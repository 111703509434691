import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { MarketingFacade } from '../../../../facades/marketing.facade';
import { IAddToCartItem, ICart, ICartItemListError } from '../../../../models/cart.models';
import { ImageUtils } from '../../../../utils/image.utils';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, take } from 'rxjs/operators';
import { ISapMessage } from '../../../../models/sap.model';
import {
  EFeatureToggles,
  EInstalledBaseTabs,
  EMessageType,
  EStoreFeatures,
  EStoreTypes,
} from '../../../../configurations/common';
import { AnalyticsService } from '../../../../analytics/analytics.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CartUtils } from '../../../../utils/cart.utils';
import { PageTypes } from '../../../../analytics/enums/pageTypes';
import { ConfigurationFacade } from '../../../../facades/configuration.facade';
import { CsvUtils } from '../../../../utils/csv.utils';
import { CustomerFacade } from '../../../../facades/customer.facade';
import { DateUtils } from '../../../../utils/date.utils';
import {
  IMaterialMasterNumbersToggles,
  IMessage,
} from '../../../../models/common.models';
import { AppUtils } from '../../../../utils/app.utils';

interface ICartOperationNotification {
  type: string;
  title: string;
  message: string;
  additionalValue?: string;
}

@Component({
  selector: 'app-current-cart',
  templateUrl: './current-cart.component.html',
  styleUrls: ['./current-cart-desktop.component.scss', './current-cart-mobile.component.scss'],
})
export class CurrentCartComponent implements OnInit, OnDestroy {
  @Input() addWishlistModalActive: boolean;
  @Input() currency: string;
  @Input() currentCart: ICart;
  @Input() currentCartItems: Array<any>;
  @Input() hasNickname: boolean;
  @Input() isCaStore: boolean;
  @Input() isExportCartActive: boolean;
  @Input() isRfqOnly: boolean;
  @Input() isUsStore: boolean;
  @Input() loadingCart: boolean;
  @Input() minimumOrderValue: number;
  @Input() rfqActive: boolean;
  @Input() wishlists: Array<any>;

  @Output() generatePdfFile = new EventEmitter();
  @Output() removeCartItem = new EventEmitter<string>();
  @Output() showAddNicknameModal = new EventEmitter<boolean>();
  @Output() showAddWishlistModal = new EventEmitter<boolean>();
  @Output() showDeleteCartModal = new EventEmitter<boolean>();
  @Output() updateCartItemQuantity = new EventEmitter<{
    oldQuantity: string, newQuantity: string, itemId: string, itemName: string
  }>();

  @ViewChildren('itemQuantity') itemQuantityInputs: QueryList<ElementRef>;

  EInstalledBaseTabs = EInstalledBaseTabs;
  activeTriggerAdlytic = false;
  addToCartListErrors: ICartItemListError[] = [];
  addToCartParsedCsvFile: any[];
  cartSapMessages: ISapMessage[] = [];
  disabledAddToCartSearchBar: boolean = false;
  featureIsEnabledForCsv: boolean = false;
  featureIsEnabledForSearchBar: boolean = false;
  fileGenerationInProgress: boolean = false;
  isAddingViaCsvInProgress: boolean = false;
  isBusinessPartnerRole: boolean = false;
  isGbStore: boolean = false;
  isHarmonizedCheckoutProcessEnabled: boolean = false;
  isMaterialMasterNumberFulfilled: boolean = false;
  isMyInstalledBaseFlowEnabled: boolean;
  isPriceDisputingActive: boolean = false;
  isProductDiscontinuedStatusEnabled$: Observable<boolean> = new Observable<boolean>();
  isSapStore = AppUtils.isSapStore();
  isSaturdayShipment: boolean = false;
  items: any[] = [];
  materialMasterNumberValidator: {} = {};
  materialMasterToggles: IMaterialMasterNumbersToggles;
  showPriceDisclaimer: boolean = false;
  shipToAddressId: string = '';

  private unsubscribe$ = new Subject<void>();

  constructor(
    private marketingFacade: MarketingFacade,
    private analyticsService: AnalyticsService,
    private configurationFacade: ConfigurationFacade,
    private customerFacade: CustomerFacade,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.isProductDiscontinuedStatusEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_DISCONTINUED_STATUS);
    this.isGbStore = AppUtils.isStoreActive(EStoreTypes.GB);
    this.showPriceDisclaimer = AppUtils.getCurrentStore().showPriceDisclaimer;
    this.loadSapMessages();
    this.getAddToCartViaCsvFeature();
    this.getMyInstalledBaseFlowToggle();
    this.selectIsHarmonizedCheckoutProcessEnabled();
    this.getAddToCartViaSearchBarFeature();
    this.selectIsBPRole();
    this.selectIsPriceDisputingActive();
    this.isSaturdayShipmentSelected();
    this.getMaterialMasterNumbersToggles();
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.shipToAddressId = params.addressId;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  showAddWishlistModalEmit(): void {
    this.showAddWishlistModal.emit(true);
  }

  showAddNicknameModalEmit(): void {
    this.showAddNicknameModal.emit(true);
  }

  showDeleteCartModalEmit(): void {
    this.showDeleteCartModal.emit(true);
  };

  removeCartItemEmit(itemId: string): void {
    this.checkAllRequiredValues({itemId: itemId, fulfilled: true});
    this.removeCartItem.emit(itemId);
  }

  updateCartItemQuantityEmit(oldQuantity: string, newQuantity: string, item: any, index: number): void {
    const itemId = item.id || item.items[0]?.id || item.attributes?.sku;
    const itemName = item.attributes?.name || item.items[0]?.attributes?.name;
    let itemQuantity = this.itemQuantityInputs.get(index)?.nativeElement;
    if (itemQuantity && itemQuantity.value != item.attributes.quantity) {
      this.updateCartItemQuantity.emit({oldQuantity, newQuantity, itemId, itemName});
      itemQuantity.value = item.attributes.quantity;
    } else if (!itemQuantity) {
      this.updateCartItemQuantity.emit({oldQuantity, newQuantity, itemId, itemName});
    }
  }

  generatePdfFileEmit(): void {
    this.fileGenerationInProgress = true;
    this.generatePdfFile.emit();
  }

  isCartEmpty(): boolean {
    return this.currentCartItems?.length === 0 || !this.currentCart.attributes?.totalItemsQuantity;
  }

  /**
   * This method check  if current product has material master number.
   * @returns {boolean}
   */
  isProductWithMaterialMasterNumber(): boolean {
    return Boolean(
      this.currentCartItems.find(
        item =>
          (this.materialMasterToggles?.notificationNumber &&
            item.attributes?.attributes?.sap_p40_notif_flag?.toUpperCase() === 'X') ||
          (this.materialMasterToggles?.peakNumber &&
            item.attributes?.attributes?.sap_p40_peak_flag?.toUpperCase() === 'X')),
    );
  }

  getImageForCartItem(item: any): string {
    return ImageUtils.getProductImageUrl(item);
  }

  getCurrentCartName(): string {
    return this.hasNickname ? this.currentCart.attributes.name : this.currentCart.id;
  }

  getLabelForAddNicknameButton(): string {
    return this.hasNickname ? 'multi-cart.edit-nickname' : 'multi-cart.add-nickname';
  }

  /**
   * Method to set label for continue button based on the store and the workflow
   * @returns {string}
   */
  getLabelForContinueToCheckoutButton(): string {
    return this.isHarmonizedCheckoutProcessEnabled || this.isSapStore
      ? 'multi-cart.continue-to-checkout'
      : (((this.rfqActive && !this.isRfqFlowInactive()) || this.isRfqOnly)
        ? this.getLabelForQuoteRequestWorkflow()
        : 'shop-cart.proceed-to-delivery-details');
  }

  /**
   * Method to set label for continue button based on the type of Rfq workflow
   * @returns {string}
   * @private
   */
  private getLabelForQuoteRequestWorkflow(): string {
    return this.isRfqOnly && this.isSetPricesForAllItems()
      ? 'shop-cart.proceed-to-generate-quote'
      : 'shop-cart.proceed-to-request-details';
  }

  public checkAllRequiredValues(value): void {
    if (this.currentCartItems.length < Object.keys(this.materialMasterNumberValidator).length) {
      this.materialMasterNumberValidator = Object.keys(this.materialMasterNumberValidator)
        .filter(key => this.currentCartItems.find((item) => item.id === key))
        .reduce((obj, key) => {
          obj[key] = this.materialMasterNumberValidator[key];
          return obj;
        }, {});
    }
    this.materialMasterNumberValidator[value.itemId] = value.fulfilled;
    let isEveryRequiredValueFilled = true;
    Object.keys(this.materialMasterNumberValidator).forEach((key) => {
      if (!this.materialMasterNumberValidator[key]) {
        isEveryRequiredValueFilled = false;
      }
    });
    this.isMaterialMasterNumberFulfilled = isEveryRequiredValueFilled;
  }

  private loadSapMessages(): void {
    this.marketingFacade.selectSapMessages().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(sapMessages => this.cartSapMessages = [...sapMessages]);
  }

  isExcludeTaxActive(): boolean {
    return this.configurationFacade.isFeatureAvailable(EStoreFeatures.EXCLUDE_TAX);
  }

  /**
   * Method to select "harmonized checkout process" feature toggle value.
   *
   * @private
   */
  private selectIsHarmonizedCheckoutProcessEnabled(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.HARMONIZED_DELIVERY_DETAILS_PAGE).pipe(take(1)).subscribe(value => {
      this.isHarmonizedCheckoutProcessEnabled = value;
    });
  }

  /**
   * Check what route use for next page redirect
   *
   * @returns {string}
   * @private
   */
  private _getRouteForNextPage(): string {
    let nextPage: string = '';

    if (this.isSapStore) {
      nextPage = '/sap-delivery-details';
    } else {
      nextPage = ((this.rfqActive && !this.isRfqFlowInactive()) || this.isRfqOnly)
        ? '/request-quote'
        : '/delivery-details';
    }

    return nextPage;
  }

  proceedToNextStep(): void {
    const nextPage = this._getRouteForNextPage();

    if (this.rfqActive && !this.isRfqFlowInactive() || this.isRfqOnly) {
      this.marketingFacade.selectCartItemsWithDetails().pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(items => {
        this.analyticsService.setProducts(items);

        if (!this.activeTriggerAdlytic) {
          this.activeTriggerAdlytic = true;
          this.analyticsService.setCartId(this.currentCart.id);
          this.analyticsService.trackPageReady('cart detail', PageTypes.CART_PAGE, 'concrete-products');
        }

        this.analyticsService.trackCart('rfq.checkout');
      });
    } else {
      this.marketingFacade.getCurrentCartItems().pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(cartItems => {
        this.analyticsService.setProducts(cartItems);
        this.analyticsService.trackCart('cart.checkout');
      });
    }
    if ((this.isUsStore || this.isCaStore) && this.shipToAddressId) {
      this.router.navigate([nextPage],
        {
          queryParams: {
            'addressId': this.shipToAddressId,
          },
        }).then();
    } else {
      this.router.navigate([nextPage]);
    }
  }

  isRfqFlowInactive(): boolean {
    return !this.configurationFacade.isFeatureAvailable(EStoreFeatures.QUOTE_REQUEST)
      || this.isSetPricesForAllItems();
  }

  isPriceVisibleInListHeader(): boolean {
    // My installed base flow is on
    if (this.isMyInstalledBaseFlowEnabled) {
      //and there are no items in the cart but fl is selected
      if (CartUtils.getCountOfItems(this.currentCartItems) === 0 && this.currentCart.attributes.systemDetails?.siemensEquipmentId) {
        return true;
      }
      // and there are items in the cart
      if (CartUtils.getCountOfItems(this.currentCartItems) > 0) {
        // and store is sap store
        if (this.isSapStore) {
          return true;
        }
        // and based on prices of items is set price in list header
        return this.isSetPricesForAllItems();
      }
    }
    // My installed base flow is of
    return this.isRfqFlowInactive();
  }

  /**
   * Method to check if every item of the current cart has a price set
   * @returns {boolean}
   * @private
   */
  private isSetPricesForAllItems(): boolean {
    return CartUtils.isSetPricesForAllItems(this.currentCartItems);
  }

  /**
   * Method to check if minimal order value has been reached
   * @returns {boolean}
   */
  isMinimumOrderValue(): boolean {
    return CartUtils.getIsMinimumOrderValue(this.minimumOrderValue, false, this.currentCart, this.currentCartItems);
  }

  addToCartViaCsv(files: any): void {
    this.readCsvFileAndParseIt(files[0]);
  }

  addToCartViaSearchBar(disable): void {
    this.disabledAddToCartSearchBar = disable;
  }

  /**
   * this method is used for getting information about the current user has a BP role
   * @private
   */
  private selectIsBPRole(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isBpRole => {
      this.isBusinessPartnerRole = isBpRole;
    });
  }

  private readCsvFileAndParseIt(file: any): void {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      const csvData: string = e.target.result;
      this.addToCartParsedCsvFile = CsvUtils.parseCSV(csvData);
      if (this.isCsvFileValid(this.addToCartParsedCsvFile)) {

        const modifiedItems: IAddToCartItem[] = this.addToCartParsedCsvFile.map((item) => ({
          sku: item.material_number,
          quantity: item.quantity,
        }));

        // upload cart item list and wait for response from Glue
        this.marketingFacade.addItemsFromCsvToCart(this.currentCart.id, modifiedItems);
        this.selectIsItemListAddInProgress();
        this.selectAddToCartViaCsvErrors();
      }
    };
    reader.readAsText(file);
  }

  private isObjectOfTypeAddToCartItem(obj: any): obj is IAddToCartItem {
    return typeof obj === 'object' && obj !== null && 'material_number' in obj && 'quantity' in obj;
  }

  private areAllOfType<T>(arr: any[], typeGuard: (item: any) => item is T): boolean {
    return arr.every(typeGuard);
  }

  private isCsvFileValid(csvFile: any): boolean {
    if (!(this.areAllOfType(this.addToCartParsedCsvFile, this.isObjectOfTypeAddToCartItem))) {
      this.showNotification(this.createCsvValidationWarning('multi-cart-notification.bad-csv-file-structure'));
      return false;
    }

    const areAllQuantitiesValid: boolean = this.addToCartParsedCsvFile.every((item) => {
      const quantityAsNumber = Number(item.quantity);
      return item.quantity !== '' && !isNaN(quantityAsNumber) && quantityAsNumber > 0;
    });

    if (!areAllQuantitiesValid) {
      this.showNotification(this.createCsvValidationWarning('multi-cart-notification.incorrect-quantity'));
      return false;
    }

    if (csvFile.length < 1) {
      this.showNotification(this.createCsvValidationWarning('multi-cart-notification.file-is-empty'));
      return false;
    }

    if (csvFile.length > 30) {
      this.showNotification(this.createCsvValidationWarning('multi-cart-notification.file-too-many-columns'));
      return false;
    }

    const sumOfQuantitiesOfItemsFromCsv: number = csvFile
      .map((item) => parseInt(item.quantity, 10))
      .reduce((quantitySum, currentValue) => quantitySum + currentValue, 0);

    if ((sumOfQuantitiesOfItemsFromCsv) > 99) {
      this.showNotification(this.createCsvValidationWarning('multi-cart-notification.incorrect-quantity-max-exceeded'));
      return false;
    }

    return true;
  }

  private createCsvValidationWarning(message: string): ICartOperationNotification {
    return {
      type: 'warning',
      title: 'multi-cart-notification.something-went-wrong-title',
      message,
    };
  }

  private showNotification(notification: ICartOperationNotification): void {
    this.configurationFacade.appendNotification({
      type: notification.type,
      title: notification.title,
      messages: [{
        key: notification.message,
        params: {
          additionalValueToMessage: notification.additionalValue,
        },
      }],
    });
  }

  private selectIsItemListAddInProgress(): void {
    this.marketingFacade.isAddingItemsViaCsvInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((isAddingInProgress: boolean) => {
      this.isAddingViaCsvInProgress = isAddingInProgress;
    });
  }

  /**
   * setTimeout is used as a "zero-delay timeout/deferred function" technique
   *
   * @private
   */
  private selectAddToCartViaCsvErrors(): void {
    this.marketingFacade.selectAddToCartViaCsvErrors().pipe(
      filter((addToCartListErrors) => addToCartListErrors !== undefined),
      takeUntil(this.unsubscribe$),
    ).subscribe((addToCartListErrors: ICartItemListError[]) => {
      setTimeout(() => {
        this.configurationFacade.clearNotifications();
      });

      this.addToCartListErrors = addToCartListErrors;
      const notificationsToDisplay: ICartOperationNotification[] = this.resolveNotifications();
      for (const notification of notificationsToDisplay) {
        this.showNotification(notification);
      }
    });
  }

  private resolveNotifications(): ICartOperationNotification[] {
    const notificationsToDisplay = [];
    if (this.addToCartListErrors.length === 0) {
      notificationsToDisplay.push({
        type: 'success',
        title: 'multi-cart-notification.item-added-title',
        message: 'multi-cart-notification.all-items-added',
      });
    } else {
      const numberOfAddedItems = this.addToCartParsedCsvFile.length - this.addToCartListErrors.length;
      let message: string;
      if (numberOfAddedItems === 1) {
        message = 'multi-cart-notification.one-item-added';
      } else {
        message = 'multi-cart-notification.some-of-items-added';
      }
      notificationsToDisplay.push({
        type: 'success',
        title: 'multi-cart-notification.item-added-title',
        additionalValue: numberOfAddedItems.toString(),
        message,
      });

      notificationsToDisplay.push(...this.getSkuWarningsGroupedBySku());
    }

    return notificationsToDisplay;
  }

  private getSkuWarningsGroupedBySku(): ICartOperationNotification[] {
    const warningsBySku: Map<string, string[]> = new Map();
    for (const error of this.addToCartListErrors) {
      if (!warningsBySku.has(error.message)) {
        warningsBySku.set(error.message, []);
      }
      warningsBySku.get(error.message).push(error.sku);
    }
    const warnings = [];
    for (const [message, skus] of warningsBySku) {
      warnings.push({
        type: 'warning',
        title: 'multi-cart-notification.something-went-wrong-title',
        message,
        additionalValue: skus.join(', '),
      });
    }

    return warnings;
  }

  private getAddToCartViaCsvFeature(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.ADD_TO_CART_VIA_CSV).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.featureIsEnabledForCsv = value;
    });
  }

  private getMyInstalledBaseFlowToggle(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.MY_INSTALLED_BASE_FLOW).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isMyInstalledBaseFlowEnabled = value;
    });
  }

  private getAddToCartViaSearchBarFeature(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.ADD_TO_CART_VIA_SEARCHBAR).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.featureIsEnabledForSearchBar = value;
    });
  }

  /**
   * Select if price disputing functionality is active (based on the company roles).
   *
   * @private
   *
   */
  private selectIsPriceDisputingActive(): void {
    this.customerFacade.isPriceDisputingActive().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isPriceDisputingActive => {
      this.isPriceDisputingActive = isPriceDisputingActive;
    });
  }

  /**
   * Determine if saturday shipment method is selected.
   *
   * @private
   */
  private isSaturdayShipmentSelected(): void {
    this.customerFacade.isSaturdayShipmentSelected()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(state => {
        this.isSaturdayShipment = state;
      });
  }

  /**
   * Check if preselected equipment is after end of support (EOS) (applicable only for Business partners in US store).
   *
   * @return boolean
   */
  isEquipmentAfterEos(): boolean {
    return this.isUsStore
      && this.isBusinessPartnerRole
      && DateUtils.isAfterEndOfSupport(this.currentCart?.attributes?.systemDetails?.endOfSupport);
  }

  /**
   * Set data (error type, error title, error description) for equipment after EOS message
   *
   * @return IMessage
   */
  setDataForEquipmentAfterEosMessage(): IMessage {
    return {
      type: EMessageType.WARNING,
      title: 'eos-equipment-modal.title',
      description: 'eos-equipment-modal.text',
    };
  }

  /**
   * Determine if special "add to cart" section (add to cart via search bar/via csv) is enabled
   *
   * At least one related ARAKH feature toggle have to be set "true"
   *
   * If it is SAP store, the system details must be included
   * For other non-SAP stores, no additional check is needed
   *
   * @return boolean
   */
  isSpecialAddToCartSectionEnabled(): boolean {
    if (this.featureIsEnabledForCsv || this.featureIsEnabledForSearchBar) {
      if (!AppUtils.isSapStore() && !this.isMyInstalledBaseFlowEnabled) {
        return true;
      }

      if ((AppUtils.isSapStore() || this.isMyInstalledBaseFlowEnabled) && this.currentCart.attributes.systemDetails?.siemensEquipmentId) {
        return true;
      }
    }

    return false;
  }

  private getMaterialMasterNumbersToggles(): void {
    this.marketingFacade.getMaterialMasterNumbersToggles()
      ?.pipe(take(1))
      .subscribe((materialMasterToggles) => {
        this.materialMasterToggles = materialMasterToggles;
        for (const [key, value] of Object.entries(this.materialMasterToggles)) {
          this.materialMasterToggles[key] = value === true || value === 'true';
        }
      });
  }

}
