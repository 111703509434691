<div class="row">
  <div class="col-12 col-lg-8">
    <div class="row">
      <div class="col-lg-1 hidden-md-down"></div>
      <div class="col-12 col-lg-10">
        <h3 class="page-title" [attr.data-test]="'title-2ndDetailsPage'">
          {{ 'checkout-delivery-details.title' | translate }}
        </h3>
        <h6 class="page-subtitle" [attr.data-test]="'subtitle-2ndDetailsPage'">
          {{ 'checkout-delivery-details.select-point-of-contact-and-shipping' | translate }}
        </h6>
        <section class="contact-details-form width-100">
          <app-dynamic-checkout-form
              [isSectionDisplayed]="isSectionDisplayed"
              [visibleInputs]="deliveryDetails2FormInputs$ | async"
              [requiredInputs]="deliveryDetailsFormRequiredInputs"
              [readOnlyInputs]="deliveryDetailsFormReadOnlyInputs$ | async"
              [cartId]="cartId"
              [cartAttributes]="cartAttributes"
              [systemDetails]="systemDetails"
              [userData]="userData"
              [userRoles]="userRoles"
              [isBusinessPartner]="isBusinessPartner"
              [isSaturdayShipment]="isSaturdayShipment"
              [isPreselectedShipToAddressCustom]="isPreselectedShipToAddressCustom"
              [isPriceDisputingSetInCart]="isPriceDisputingSetInCart"
              (formValidityChange)="isDeliveryDetailsPage2FormValid = $event">
          </app-dynamic-checkout-form>
          <app-dynamic-checkout-approver-form
              *ngIf="this.isApproverBuyerWorkflowEnabled && !this.hasApproverAccess"
              [cartId]="cartId"
              [visibleInputs]="approverFormInputs$ | async"
              [requiredInputs]="approverFormRequiredInputs"
              [readOnlyInputs]="approverFormReadOnlyInputs$ | async"
              (formValidityChange)="isApproverFormValid = $event"
              (selectedApproverEmit)="selectedApprover = $event">
          </app-dynamic-checkout-approver-form>
          <p *ngIf="deliveryDetailsFormRequiredInputs.length || approverFormRequiredInputs.length"
             class="disclaimer--mandatory-fields">
            <small>
              {{ 'checkout-delivery-details.mandatory-fields-required' | translate }}
            </small>
          </p>
        </section>
      </div>
    </div>
  </div>
  <div class="col-12 col-lg-4 order-summary">
    <div class="row">
      <div class="col-12">
        <p class="disclaimer--finalize-order">
          <span *ngIf="!isApproverBuyerWorkflowEnabled || (isApproverBuyerWorkflowEnabled && hasApproverAccess)">
            {{ 'checkout-delivery-details.approver-checkout-title' | translate }}
          </span>
          <span *ngIf="isApproverBuyerWorkflowEnabled && !hasApproverAccess">
            {{ 'checkout-delivery-details.approver-notice' | translate:{name: selectedApprover ?? ''} }}
          </span>
        </p>
        <div class="buttons-group">
          <button
              appDebounceClick
              class="button button--primary"
              type="button"
              [disabled]="isProceedToCreateOrderBtnDisabled()"
              [debounceTime]="300"
              (debounceClick)="proceedToCreateOrderEmit()"
              [attr.data-test]="'button-proceedToCreateOrder'">
            @if (!isApproverBuyerWorkflowEnabled || (isApproverBuyerWorkflowEnabled && hasApproverAccess)) {
              {{ 'checkout-delivery-details.approver-checkout-button' | translate }}
            } @else if (isApproverBuyerWorkflowEnabled && !hasApproverAccess) {
              {{ 'checkout-delivery-details.send-to-approval' | translate }}
            }
          </button>
          @if (isPdfPreviewQuoteEnabled) {
            <button
                appDebounceClick
                class="button button-create-order button-previous-page"
                type="button"
                [disabled]="isProceedToCreateOrderBtnDisabled()"
                [debounceTime]="300"
                (debounceClick)="createPdfPreviewQuote()"
                [attr.data-test]="'button-previewQuote'">
              <span>
                @if (!creatingPdfPreviewQuoteInProgress) {
                  {{ 'checkout-delivery-details.preview-quote' | translate }}
                } @else {
                  <div class="btn-spinner">
                    <div class="loading-animation"></div>
                  </div>
                }
              </span>
            </button>
          }
          <button
              class="button button-previous-page"
              type="button"
              (click)="backToDeliveryDetails1SectionEmit()"
              [attr.data-test]="'button-previousPage'">
            {{ 'request-details.previous-page' | translate }}
          </button>
        </div>
        <section class="cart-pricelist">
          <div class="cart--subtotal-price">
            <span [attr.data-test]="'label-subtotalPrice'">
              {{ 'checkout-delivery-details.subtotal' | translate }}
            </span>
            <span [attr.data-test]="'desc-subtotalPrice'">
              {{ cartAttributes.totals.subtotal | currency: cartAttributes.currency }}
            </span>
          </div>
          <div class="cart--shipping-price">
            <span [attr.data-test]="'label-shippingAndHandlingPrice'">
              {{ 'checkout-delivery-details.shipping-and-handling' | translate }}
            </span>
            <span *ngIf="!totalPricesLoading; else loadingAnimation"
                  [attr.data-test]="'desc-shippingAndHandlingPrice'">
              {{ ((totalPrices$ | async)?.expenseTotal | currency: cartAttributes.currency) ?? '-' }}
            </span>
            <ng-template #loadingAnimation>
              <span class="loading-animation"></span>
            </ng-template>
          </div>
          <div class="cart--voucher-price"
               *ngFor="let voucher of (voucherList$ | async)">
            <label>
            <span class="icon-close remove-voucher__btn"
                  title="{{ 'order-approve.remove-voucher' | translate }}"
                  [attr.data-test]="'button-removeVoucher-' + voucher.id"
                  (click)="removeVoucher(voucher)">
            </span>
              {{ getVoucherTitle(voucher, (totalPrices$ | async)) }}{{ 'order-approve.promotional-code' | translate }}
            </label>
            <label class="voucher-amount"
                   [attr.data-test]="'desc-voucherAmount-' + voucher.id">
              -{{ voucher.attributes.amount | currency: cartAttributes.currency }}
            </label>
          </div>
          <div class="cart--order-total-price">
            <strong [attr.data-test]="'label-orderTotalPrice'">
              {{ 'checkout-delivery-details.order-total' | translate }}
            </strong>
            <strong *ngIf="!totalPricesLoading; else loadingAnimation"
                    [attr.data-test]="'desc-orderTotalPrice'">
              {{ (totalPrices$ | async)?.priceToPay | currency: cartAttributes.currency }}
            </strong>
            <ng-template #loadingAnimation>
              <span class="loading-animation"></span>
            </ng-template>
          </div>
        </section>
        <app-cart-rules
            *ngIf="cartRules?.length > 0"
            [cartRules]="cartRules"
            [priceToPay]="(totalPrices$ | async)?.priceToPay"
            [currency]="cartAttributes.currency">
        </app-cart-rules>
        <div class="cart--additional-price-details">
          <span [attr.data-test]="'desc-priceInfo'">
            {{ 'checkout-delivery-details.price-info' | translate }}
          </span>
          <br>
          <span *ngIf="cartAttributes?.totals?.expenseTotal === 0 && cartAttributes?.totals?.shipmentTotal === 0"
                [attr.data-test]="'desc-shipping'">
            {{ 'checkout-delivery-details.free-shipping' | translate }}
          </span>
        </div>
        <hr class="bottom-line">
        <a class="button button--link button-back-to-cart" [routerLink]="['/shop-cart']"
           [attr.data-test]="'link-backToCart'">
          <span class="icon-arrow-left color-orange"></span>
          {{ 'checkout-delivery-details.back-to-cart' | translate }}
        </a>
        <h6 class="section-title">
          {{ 'checkout-delivery-details.your-cart' | translate }}
        </h6>
        <section class="cart-items">
          <ng-container *ngFor="let item of cartItemsWithDetails">
            <div class="cart-item" [attr.data-test]="'cart-item-' + item.attributes.name">
              <div class="cart-item--name">
                <h5 class="item-name" [attr.data-test]="'desc-itemName'">
                  {{ item.attributes.name || item.attributes.attributes?.sap_name }}
                </h5>
                <span *ngIf="(isProductDiscontinuedStatusEnabled$ | async) && item.attributes.isDiscontinued"
                      class="label discontinued-item"
                      [attr.data-test]="'label-discontinuedItem'">
                  {{ 'spare-parts.discontinued-item' | translate }}
                </span>
                <span *ngIf="item.attributes.availability?.isExchangeable"
                      class="label item-exchange-material"
                      [attr.data-test]="'label-exchangeableItem'">
                  {{ 'spare-parts.exchangeable-item' | translate }}
                </span>
                <span *ngIf="item.attributes.heavyweight"
                      class="label heavyweight-item"
                      [attr.data-test]="'label-heavyWeightItem'">
                  {{ 'spare-parts.heavyweight-item' | translate }}
                </span>
              </div>
              <div class="cart-item--body">
                <div class="cart-item--image">
                  <img [src]="ImageUtils.getProductImageUrl(item)"
                       [alt]="item.attributes.name"
                       [attr.data-test]="'img-itemImage'">
                </div>
                <div class="cart-item--info">
                  <div class="item-material-number" [attr.data-test]="'desc-materialNumber'">
                    {{ item.attributes.materialNumber }}
                  </div>
                  <div class="cart-item--additional-fields">
                    <div class="additional-field-row">
                      <div [attr.data-test]="'label-quantity'">
                        {{ 'multi-cart.quantity' | translate }}:
                      </div>
                      <div [attr.data-test]="'value-quantity'">
                        {{ item.attributes.quantity }}
                      </div>
                    </div>
                    <div class="additional-field-row">
                      <div [attr.data-test]="'label-pricePerUnit'">
                        {{ 'multi-cart.price-per-unit' | translate }}:
                      </div>
                      <div [attr.data-test]="'value-pricePerUnit'">
                        {{ item.attributes.calculations?.unitPrice | currency: cartAttributes.currency }}
                      </div>
                    </div>
                    <div class="additional-field-row">
                      <div [attr.data-test]="'label-totalPrice'">
                        {{ 'multi-cart.total-price' | translate }}:
                      </div>
                      <div [attr.data-test]="'value-totalPrice'">
                        {{ item.attributes.calculations?.sumPrice | currency: cartAttributes.currency }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <app-message
                  *ngIf="isPriceDisputingActive && CartUtils.isPriceDisputingSetForItem(priceDisputingPerItem, item.id)"
                  [message]="CartUtils.priceDisputingMessage"/>
            </div>
          </ng-container>
        </section>
      </div>
    </div>
  </div>
</div>
