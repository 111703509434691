import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { IAddressData, IConsentCheckbox } from '../../../models/checkout.models';
import { IAddress, IMessage, ITotals } from '../../../models/common.models';
import { ISapMessage } from '../../../models/sap.model';
import { ICart, IDiscount } from '../../../models/cart.models';
import { EMessageType, EAddressType } from '../../../configurations/common';
import { map, take } from 'rxjs/operators';
import { FileType, FileUtils } from '../../../utils/file.utils';
import { CheckoutFacade } from '../../../facades/checkout.facade';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { AddressUtils } from '../../../utils/address.utils';
import { SparePartsUtils } from '../../../utils/spare-parts.utils';
import { environment } from '../../../../environments/environment.defaults';
import { I18nService } from '../../../services';

@Injectable({
  providedIn: 'root',
})
export class OrderReviewService {
  fileGenerationInProgress: boolean = false;
  addressesConfig: string[]; // Addresses configuration for visibility and order from Arakh
  checkoutSectionsRequiredFields: string[]; // Required fields for sections from Arakh
  private cartTotalsSubject$: BehaviorSubject<ITotals> = new BehaviorSubject<ITotals>(null);
  private cartDiscountsSubject$: BehaviorSubject<IDiscount[]> = new BehaviorSubject<IDiscount[]>([]);

  cartTotals$: Observable<ITotals> = this.cartTotalsSubject$.asObservable();
  cartDiscounts$: Observable<IDiscount[]> = this.cartDiscountsSubject$.asObservable();

  constructor(
    private configurationFacade: ConfigurationFacade,
    private checkoutFacade: CheckoutFacade,
    private router: Router,
    private i18nService: I18nService,
  ) {
    this.selectCheckoutAddressesConfig();
    this.selectCheckoutSectionsRequiredFields();
  }

  // *** Initialization ***

  /**
   * Get addresses configuration from Arakh.
   */
  selectCheckoutAddressesConfig(): void {
    this.configurationFacade.getDynamicCheckoutAddresses()
      .pipe(take(1)).subscribe((value) => {
      this.addressesConfig = value;
    });
  }

  /**
   * Initialize addresses based on configuration in arakh. If address is not part of cart, then create empty, not valid
   * address and user will not be able to submit order.
   * @param {ICart} cart
   * @returns {IAddressData[]}
   */
  checkoutAddressesInitialization(cart: ICart): IAddressData[] {
    const addresses: IAddressData[] = [];
    this.addressesConfig.forEach(addressType => {
      let addressData: IAddressData;
      switch (addressType) {
        case EAddressType.SHIP_TO:
          addressData = this.createCheckoutAddress(addressType, cart.attributes.shippingAddress);
          break;
        case EAddressType.BILL_TO:
          addressData = this.createCheckoutAddress(addressType, cart.attributes.billingAddress);
          break;
        case EAddressType.SOLD_TO:
          addressData = this.createCheckoutAddress(addressType, cart.attributes.soldToAddress);
          break;
        case EAddressType.PAYER:
          addressData = this.createCheckoutAddress(addressType, cart.attributes.payerAddress);
          break;
        default:
          break;
      }
      addresses.push(addressData);
    });
    return addresses;
  }

  /**
   * Create address data based on address type and address object. If address is falsy (null, undefined), then create
   * empty, not valid address.
   * @param {EAddressType} addressType
   * @param {IAddress} address
   * @returns {IAddressData}
   */
  createCheckoutAddress(addressType: EAddressType, address: IAddress): IAddressData {
    const attentionTo: string = address.attentionTo ? address.attentionTo + ' - ' : '';
    const sapId: string = address.sapId ? address.sapId + ' - ' : '';
    const addressName: string = address ? `${attentionTo}
                                           ${sapId}
                                           ${SparePartsUtils.formatAddressToString(address)}` : null;
    const isAddressValid: boolean = AddressUtils.isAddressValid(address);
    return {
      value: address,
      name: addressName,
      type: addressType,
      isAddressValid: isAddressValid,
    };
  }

  /**
   * Create consent and terms checkboxes based on configuration of visibility and required fields.
   * @returns {IConsentCheckbox[]}
   */
  createTermsAndConsentsCheckboxes(taxDisclaimer: number): IConsentCheckbox[] {
    let consentsCheckboxes: IConsentCheckbox[] = [];

    forkJoin([
      this.configurationFacade.getDynamicCheckoutConsentCheckboxes(),
      this.configurationFacade.getDynamicCheckoutConsentCheckboxesRequiredFields(),
    ]).pipe(
      map(([consentsConfig, requiredFields]) => {
        return consentsConfig.map(consent => ({
          name: consent,
          label: consent + `${((consent === 'tax_disclaimer') && taxDisclaimer) ? '_' + taxDisclaimer : ''}`,
          isChecked: false,
          isRequired: requiredFields.includes(consent),
        }));
      }),
      take(1),
    ).subscribe((consentsConfig) => {
      consentsCheckboxes = consentsConfig;
    });
    return consentsCheckboxes;
  }

  /**
   * Get required fields for sections from Arakh to be checked in "isFormFieldRequired" function.
   */
  selectCheckoutSectionsRequiredFields(): void {
    this.configurationFacade.getDynamicCheckoutSectionsRequiredFields()
      .pipe(take(1)).subscribe((value) => {
      this.checkoutSectionsRequiredFields = value;
    });
  }

  /**
   * Check if form field is required for adding Validators.required while initializing form.
   * @param {string} fieldName
   * @returns {boolean}
   */
  isFormFieldRequired(fieldName: string): boolean {
    return this.checkoutSectionsRequiredFields.includes(fieldName);
  }

  // *** Cart operations ***

  /**
   * Update totals and discounts in cart (e.g. after add/remove discount).
   * @param {ITotals} totals
   * @param {IDiscount[]} discounts
   */
  updateTotalsAndDiscounts(totals: ITotals, discounts: IDiscount[] = []): void {
    this.cartTotalsSubject$.next(totals);
    this.cartDiscountsSubject$.next(discounts);
  }

  /**
   * Return % of discount for discount based on subtotal value.
   * @param {number} discountAmount
   * @param {number} cartSubtotal
   * @returns {number}
   */
  getDiscountTitle(discountAmount: number, cartSubtotal: number): number {
    return Math.round((discountAmount * 100) / cartSubtotal);
  }

  /**
   * Checks if sapMessages array contains message with type 'E'.
   * @param {ISapMessage[]} sapMessages
   */
  checkHasNoErrorMessages(sapMessages: ISapMessage[]): boolean {
    sapMessages.forEach(message => {
      if (message.type === 'E') {
        return true;
      }
    });
    return false;
  }

  /**
   * @returns {boolean}
   */
  isSaturdayShipmentSelected(selectedShipmentMethodName: string): boolean {
    return selectedShipmentMethodName === environment.sparePartSaturdayDeliveryKey;
  }

  // *** Generating of content ***

  /**
   * Generate warning message for price disputing.
   * @returns {IMessage}
   */
  generatePriceDisputingMessage(): IMessage {
    return {
      type: EMessageType.WARNING,
      title: 'cart.disputed-pricing.title',
      description: 'cart.disputed-pricing.description',
    };
  }

  /**
   * Generate warning message "Something is wrong" and redirect to home page.
   */
  showNotificationAndRedirectToHomePage() {
    this.configurationFacade.appendNotification({
      type: 'warning',
      title: 'error-404.title-something-is-wrong',
      messages: [{
        key: 'error-404.something-is-wrong',
      }],
    });
    this.router.navigate([this.i18nService.getCurrentLocale()]);
  }

  /**
   * @param {boolean} isAvailable
   * @returns {string}
   */
  getAvailabilityIcon(isAvailable: boolean): string {
    return isAvailable ? 'icon-check icon-check__circle' : 'icon-exclamationmark icon-exclamationmark__circle';
  }

  /**
   * Set loader overlay until file is generated.
   */
  generatePdfFile(cart: ICart): void {
    this.fileGenerationInProgress = true;
    this.checkoutFacade.getCartDetailPdfFile(cart.id).pipe(take(1))
      .subscribe(file => {
        FileUtils.saveAndOpenFile(file, FileType.PDF,
          `Cart_details_${cart.id}`, cart.attributes.createdAt);
        this.fileGenerationInProgress = false;
      });
  }
}
