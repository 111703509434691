import { IOrderDataAttributes, IOrderHistory, IPendingOrder } from '../models/order.models';
import { EOrderStatus } from '../configurations/order-status';
import { EGlueResource } from '../configurations/common';

export class OrdersUtils {

  static filterCanceledDeclinedCarts(cartsList: IPendingOrder[]): IPendingOrder[] {
    return cartsList?.filter(cart => cart.attributes.approverDetails?.status === EOrderStatus.DECLINED
      || cart.attributes.approverDetails?.status === EOrderStatus.CANCELED) as IPendingOrder[];
  }

  static filterPendingCarts(cartsList: IPendingOrder[]): IPendingOrder[] {
    return cartsList.filter(cart => cart.attributes.approverDetails?.status === 'waiting') as IPendingOrder[];
  }


  static convertDeclinedCanceledOrdersToOrderHistoryObject(declinedOrders: IPendingOrder[]): IOrderHistory[] {
    let canceledDeclinedCartsArray: IOrderHistory[] = [];
    for (const order of declinedOrders) {
      const status = order.attributes.approverDetails?.status || EOrderStatus.DECLINED;
      const canceledDeclinedCart = {
        type: EGlueResource.CARTS,
        id: order.id,
        attributes: {
          createdAt: order.attributes.createdAt,
          totals: order.attributes.totals,
          currencyIsoCode: order.attributes.currency,
          priceMode: order.attributes.priceMode,
          itemsTotalNumber: order.attributes.totalItemsQuantity,
          orderedAt: order.attributes.orderedAt,
          approverDetails: {
            approverId: order.attributes.approverDetails.approverId,
            firstName: order.attributes.approverDetails.firstName,
            lastName: order.attributes.approverDetails.lastName,
          },
          createdBy: {
            customerId: order.attributes.customer.idCustomer.toString(),
            firstName: order.attributes.customer.firstName,
            lastName: order.attributes.customer.lastName,
          },
          customer: {
            firstName: order.attributes.customer.firstName,
            lastName: order.attributes.customer.lastName,
          },
          orderStatus: status,
          deliveryDate: null,
          approvalStatus: status,
        },
      } as IOrderHistory;
      canceledDeclinedCartsArray.push(canceledDeclinedCart);
    }
    return canceledDeclinedCartsArray;
  }

  /**
   * Checks if the passed order is eligible for the return process in SAP-integrated stores
   *
   * @param {IOrderDataAttributes} orderAttributes
   * @returns {boolean}
   */
  static isSapOrderEligibleForReturn(orderAttributes: IOrderDataAttributes): boolean {
    let isEligible = !!orderAttributes.sapDetails?.returnAuthorizationNumber;
    let hasExchangeableItem = false;
    for (const item of orderAttributes.items) {
      if (item.sapDetails?.exchangeableItem) {
        hasExchangeableItem = true;
      }
    }
    return isEligible && hasExchangeableItem;
  }
}

