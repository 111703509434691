import { Component, Input, OnChanges, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { IShsEquipmentAttributesEquipmentAddress, IShsEquipmentData } from '../../../models/installedbase.models';
import { AdvancedFilter } from '../../../models/advanced-filter';
import { DropdownComponent } from '../../../shared/dropdown/dropdown.component';
import { combineLatest, Observable, Subject } from 'rxjs';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { ActivatedRoute, Params } from '@angular/router';
import { CpqFacade } from '../../../facades/cpq.facade';
import { map, take, takeUntil } from 'rxjs/operators';
import { SearchFieldPipe } from '../../../shared/pipes/search-field.pipe';
import { EFeatureToggles, EUserRoles } from '../../../configurations/common';
import {
  EEquipmentFilterAttributes,
  EEquipmentProductGroup,
  EquipmentFilters,
} from '../../../configurations/equipments';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { CatalogFacade } from '../../../facades/catalog.facade';
import { AppUtils } from '../../../utils/app.utils';

@Component({
  selector: 'app-my-equipment',
  templateUrl: './my-equipment.component.html',
  styleUrls: ['./my-equipment.component.scss'],
  providers: [SearchFieldPipe],
})
export class MyEquipmentComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isCartOperationInProgress: boolean;
  @Input() loadingCartDataInProgress: boolean;
  @Input() installedBaseSystemData: IShsEquipmentData[];
  @Input() installBaseLoading: boolean = true;
  @Input() productSku: string;
  @Input() soldTo: number;
  @Input() isReorderPending: boolean = false;
  @Input() companyRoles: EUserRoles[];

  active: boolean = false;
  advancedSearchDropdownIsOpened: boolean = false;
  agreements: IShsEquipmentData[];
  allOptionsAreSelected: any;
  cartItems = [];
  contractFilters: AdvancedFilter[] = [];
  currentCartId: string | null;
  displayedEquipments: IShsEquipmentData[] = [];
  equipmentProductCategoryForm: UntypedFormGroup;
  equipmentProductCategoryParam: string = '';
  filterConfig: string[];
  filterSearchValue: any;
  isConsumableEquipmentPurchaseEnabled: boolean = false;
  isInstallBaseAddressAnonymizeEnabled: boolean = false;
  isProductPricingGroupEnabled: boolean = false;
  isSapStore: boolean = AppUtils.isSapStore();
  otherEquipment: IShsEquipmentData[] = [];
  otherEquipmentEnabled$: Observable<boolean> = new Observable<boolean>();
  pricingGroupForConsumables: string;
  productPriceGroupConsumableTranslate: string;
  productPriceGroupSparePartsTranslate: string;
  searchLoading: boolean = false;
  searchValue: string;
  selectedOptions: any;
  selectedSystem: any;
  sparePartsEnabledEquipments: IShsEquipmentData[] = [];
  @ViewChildren(DropdownComponent) dropdowns: QueryList<DropdownComponent>;

  private unsubscribe$ = new Subject<void>();
  private initWidth: number = 0;

  constructor(
    private configurationFacade: ConfigurationFacade,
    private marketingFacade: MarketingFacade,
    private route: ActivatedRoute,
    private cpqFacade: CpqFacade,
    private searchFieldPipe: SearchFieldPipe,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private catalogFacade: CatalogFacade,
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
    this.otherEquipmentEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.OTHER_EQUIPMENT);
    this.filterConfig = this.translate.instant('config.equipmentFilters');
    let param: Params;
    combineLatest([
      this.route.queryParams,
      this.configurationFacade.isFeatureEnabled(EFeatureToggles.CONSUMABLE_ONLY_EQUIPMENT_PURCHASES),
      this.configurationFacade.isFeatureEnabled(EFeatureToggles.INSTALL_BASE_ADDRESS_ANONYMIZE),
      this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_PRICING_GROUP),
      this.translate.get('advanced-filter.spare-parts'),
      this.translate.get('advanced-filter.consumables'),
      this.catalogFacade.getPricingGroupForConsumables(),
    ]).pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ([params,
                 consumablePurchaseEnabled,
                 addressAnonymizeEnabled,
                 productPricingGroupEnabled,
                 spareParts,
                 consumables,
                 pricingGroup],
        ) => {
          this.productSku = params?.selectedService;
          this.pricingGroupForConsumables = pricingGroup;
          this.soldTo = +params.soldTo;
          this.equipmentProductCategoryParam = params.equipmentProductCategory?.toLowerCase();
          this.isConsumableEquipmentPurchaseEnabled = consumablePurchaseEnabled;
          this.productPriceGroupSparePartsTranslate = spareParts;
          this.productPriceGroupConsumableTranslate = consumables;
          this.setUpAdvancedFilterAttributes();
          this.isInstallBaseAddressAnonymizeEnabled = addressAnonymizeEnabled;
          this.isProductPricingGroupEnabled = productPricingGroupEnabled;
          this.preCheckSparePartsOrConsumableCheckbox(param);
          param = params;
        },
      });

    if (this.productSku) {
      this.selectSelectedSystem();
    }

    this.selectCartsData();
    this.initializeAllOptionsSelected();
    this.initializeSelectedOptions();
    this.initializeSearchValue();
    if (this.selectedOptions?.[EEquipmentFilterAttributes.PRODUCT_CATEGORY]?.length === 0) {
      this.preCheckSparePartsOrConsumableCheckbox(param);
    }
    if (this.isInstallBaseAddressAnonymizeEnabled) { //anonymize addresses based on arakh toggle for showcase purposes
      this.anonymizeAddresses();
    }
  }

  ngOnChanges(): void {
    if (!this.installBaseLoading && !this.agreements) {
      this.agreements = this.installedBaseSystemData;
      this.initializeAgreements();
      this.initializeForm();
      this.handleCheckboxParam();
      this.updateDisplayedEquipments();
      this.setUpAdvancedFilterAttributes();
      this.contractFilters.forEach(cf => {
        cf.options = (cf.name !== EEquipmentFilterAttributes.PRODUCT_CATEGORY)
          ? [...new Set(this.agreements.map(agreement => {
            if (cf.secondAdditionalAttribute) {
              return agreement[cf.attribute][cf.firstAdditionalAttribute][cf.secondAdditionalAttribute];
            } else if (cf.firstAdditionalAttribute) {
              return agreement[cf.attribute][cf.firstAdditionalAttribute];
            } else {
              return agreement[cf.attribute];
            }
          }))].filter(item => item !== undefined)
          : this.installedBaseSystemData.length > 0 ? cf.options : [];
      });
      this.route.queryParams
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          {
            next: (params) => {
              if (this.selectedOptions?.[EEquipmentFilterAttributes.PRODUCT_CATEGORY]?.length === 0) {
                this.preCheckSparePartsOrConsumableCheckbox(params);
              }
            },
          },
        );
    }
    if (this.isInstallBaseAddressAnonymizeEnabled) { //anonymize addresses based on arakh toggle for showcase purposes
      this.anonymizeAddresses();
    }
  }

  /**
   * Initialize equipment product category checkboxes (pre-check one of the values)
   * depending on equipment product group in the URL param 'equipmentProductCategory'.
   *
   * For invalid param values (or without params), leave the checkboxes in the default
   * state (all unchecked).
   *
   * @private
   */
  private handleCheckboxParam(): void {
    if (Object.values(EEquipmentProductGroup).includes(this.equipmentProductCategoryParam as EEquipmentProductGroup)) {
      const equipmentProductGroup = this.equipmentProductCategoryParam as EEquipmentProductGroup;
      this.equipmentProductCategoryForm.patchValue({
        consumableEquipmentsOnly: (equipmentProductGroup === EEquipmentProductGroup.CONSUMABLES) ? true : false,
        sparePartsEquipmentsOnly: (equipmentProductGroup === EEquipmentProductGroup.SPARE_PARTS) ? true : false,
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  selectSelectedSystem(): void {
    this.cpqFacade.selectSelectedSystem().pipe(take(1)).subscribe(selectedSystem => {
      this.selectedSystem = selectedSystem;
    });
  }

  applySelectedFiltersOptions(): void {
    this.contractFilters = this.contractFilters.map(filter => {
      filter.selected = [...this.selectedOptions[filter.name]];
      return filter;
    });
    this.openOrCloseDropdown();
  }

  clearSelectedFiltersOptions(): void {
    this.contractFilters.forEach(filter => {
      this.selectedOptions[filter.name] = [...filter.selected];
      this.allOptionsAreSelected[filter.name] = this.allOptionsAreSelectedForFilter(filter.name);
    });
    this.openOrCloseDropdown();
  }

  setSelectedOptionsForFilter(selectedOptions: string[], filterName: string): void {
    this.contractFilters = this.contractFilters.map(filter => {
      if (filter.name === filterName) {
        filter.selected = selectedOptions;
      }
      return filter;
    });
  }

  selectOption(filterName: string, option: string): void {
    const index = this.selectedOptions[filterName]?.indexOf(option);
    if (index > -1) {
      this.selectedOptions[filterName]?.splice(index, 1);
    } else {
      this.selectedOptions[filterName]?.push(option);
    }
    this.allOptionsAreSelected[filterName] = this.allOptionsAreSelectedForFilter(filterName);
  }

  selectAllFilteredOptions(filterName: string): void {
    if (!this.allOptionsAreSelected[filterName]) {
      this.allOptionsAreSelected[filterName] = true;
      const contractFilterOptions = this.contractFilters.find(filter => filter.name === filterName).options;
      let filteredOptions: string[] = this.searchFieldPipe.transform(contractFilterOptions, this.filterSearchValue[filterName]);
      filteredOptions = filteredOptions.filter(option => !this.selectedOptions[filterName].includes(option));
      this.selectedOptions[filterName] = [...this.selectedOptions[filterName], ...filteredOptions];
    } else {
      this.allOptionsAreSelected[filterName] = false;
      this.selectedOptions[filterName] = [];
    }
  }

  private allOptionsAreSelectedForFilter(filterName: string): boolean {
    const maxNumOfOptions = this.contractFilters?.find(filter => filter.name === filterName)?.options?.length;
    const actualNumOfSelectedOptions = this.selectedOptions[filterName]?.length;
    return actualNumOfSelectedOptions === maxNumOfOptions;
  }

  private openOrCloseDropdown(): void {
    if (this.advancedSearchDropdownIsOpened) {
      this.initializeSearchValue();
    }
    this.advancedSearchDropdownIsOpened = !this.advancedSearchDropdownIsOpened;
  }

  initializeSearchValue(): void {
    this.filterSearchValue = this.contractFilters.reduce((allFilters, filter) =>
      ({...allFilters, [filter.name]: ''}), ({}));
  }

  initializeAllOptionsSelected(): void {
    this.allOptionsAreSelected = this.contractFilters.reduce((allFilters, filter) =>
      ({...allFilters, [filter.name]: false}), ({}));
  }

  initializeSelectedOptions(): void {
    this.selectedOptions = this.contractFilters.reduce((allFilters, filter) =>
      ({...allFilters, [filter.name]: []}), ({}));
  }

  /**
   * Initialize form for equipment product group/category checkboxes
   * @private
   */
  private initializeForm(): void {
    this.equipmentProductCategoryForm = this.formBuilder.group({
      sparePartsEquipmentsOnly: false,
      consumableEquipmentsOnly: false,
    });
  }

  initializeAgreements(): void {
    if (this.isInstallBaseAddressAnonymizeEnabled) { //anonymize addresses based on arakh toggle for showcase purposes
      this.anonymizeAddresses();
    }

    this.replaceCityWithCityAndState();
    this.setAgreementsWithUpdatedContractNames();
  }

  /**
   * Update displayed list of installed base equipments depending on user-selected
   * product category coverage of equipments.
   *
   * IMPORTANT: DO NOT cover with unit tests until PBI #102638 is started
   * (it is possible that this method might be removed as part of that PBI).
   */
  updateDisplayedEquipments(): void {
    const sparePartsEquipments: boolean = this.equipmentProductCategoryForm.controls.sparePartsEquipmentsOnly.value;
    const consumableEquipments: boolean = this.equipmentProductCategoryForm.controls.consumableEquipmentsOnly.value;
    if (sparePartsEquipments) {
      this.equipmentProductCategoryForm.controls.consumableEquipmentsOnly.disable();
      this.equipmentProductCategoryForm.controls.sparePartsEquipmentsOnly.enable();
      this.displayedEquipments = this.sparePartsEnabledEquipments;
    } else if (consumableEquipments) {
      this.equipmentProductCategoryForm.controls.consumableEquipmentsOnly.enable();
      this.equipmentProductCategoryForm.controls.sparePartsEquipmentsOnly.disable();
      this.displayedEquipments = this.agreements;
    } else {
      // reset form
      this.equipmentProductCategoryForm.controls.consumableEquipmentsOnly.enable();
      this.equipmentProductCategoryForm.controls.sparePartsEquipmentsOnly.enable();
      this.displayedEquipments = this.agreements;
    }
  }

  private anonymizeAddresses(): void {
    this.sparePartsEnabledEquipments = this.sparePartsEnabledEquipments.map(equipment =>
      MyEquipmentComponent.anonymizeEquipmentAddress(equipment));
    this.otherEquipment = this.otherEquipment.map(equipment =>
      MyEquipmentComponent.anonymizeEquipmentAddress(equipment));
    this.displayedEquipments = this.displayedEquipments.map(equipment =>
      MyEquipmentComponent.anonymizeEquipmentAddress(equipment));
  }

  private static anonymizeEquipmentAddress(equipment: IShsEquipmentData): IShsEquipmentData {
    return {
      ...equipment,
      attributes: {
        ...equipment.attributes,
        siemensEquipmentAddress: {
          ...equipment.attributes.siemensEquipmentAddress,
          street: '100 MAIN STREET',
          city: 'GERMANTOWN, MD',
        },
      },
    };
  }

  private replaceCityWithCityAndState(): void {
    this.agreements = this.agreements.map(agreement => {
      return {
        ...agreement,
        attributes: {
          ...agreement.attributes,
          siemensEquipmentAddress: {
            ...agreement.attributes.siemensEquipmentAddress,
            city: this.getCityAndState(agreement.attributes.siemensEquipmentAddress),
          },
        },
      };
    });
  }

  /**
   * Get city and state from equipment address
   * @param {IShsEquipmentAttributesEquipmentAddress} address
   * @returns {string}
   * @private
   */
  private getCityAndState(address: IShsEquipmentAttributesEquipmentAddress): string {
    return address.city + (address.state ? (', ' + address.state) : '');
  }

  private setAgreementsWithUpdatedContractNames() {
    this.sparePartsEnabledEquipments = [];
    this.otherEquipment = [];
    this.agreements.forEach(item => {
      if (!this.isSapStore || item.attributes.isSparePartsAvailable) {
        this.sparePartsEnabledEquipments.push(item);
      } else {
        this.otherEquipment.push(item);
      }
    });
  }

  activeDropdown(): void {
    this.active = !this.active;
  }

  unselectOptionForFilter(optionToUnselect: string, filterName: string): void {
    const selectedDropdown = this.dropdowns?.find(dropdown => dropdown.dropDownName === filterName);
    selectedDropdown.selectAndApply(optionToUnselect);
    this.active = false;
  }

  onResize(event): void {
    if (this.active && this.initWidth !== event.target.innerWidth) {
      this.initWidth = event.target.innerWidth;
      this.active = false;
    }
  }

  private selectCartsData(): void {
    combineLatest([
      this.marketingFacade.selectCartId(),
      this.marketingFacade.selectCartItemsWithDetails(),
    ]).pipe(
      takeUntil(this.unsubscribe$),
      map(([currentCartId, cartItems]) => ({currentCartId, cartItems}),
      )).subscribe(data => {
        if (data) {
          this.currentCartId = data.currentCartId;
          this.cartItems = data.cartItems;
        }
      },
    );
  }

  /**
   * Resolve if the spare parts/consumable checkbox should be prechecked according to pricing_group parameter
   * @param {Params} params
   * @private
   */
  public preCheckSparePartsOrConsumableCheckbox(params: Params): void {
    if (this.isProductPricingGroupEnabled && params && ('pricing_group' in params) && this.displayedEquipments?.length > 0) {
      if (params?.pricing_group !== this.pricingGroupForConsumables) {
        this.selectOption(EEquipmentFilterAttributes.PRODUCT_CATEGORY, this.productPriceGroupSparePartsTranslate);
      } else {
        this.selectOption(EEquipmentFilterAttributes.PRODUCT_CATEGORY, this.productPriceGroupConsumableTranslate);
      }
      this.applySelectedFiltersOptions();
      this.openOrCloseDropdown();
    }
  }

  /**
   * Set filter options based on filter config
   */
  public setUpAdvancedFilterAttributes(): void {
    EquipmentFilters.forEach(filter => {
      if (this.filterConfig?.includes(filter.name) && !this.contractFilters.find(cf => cf.name === filter.name)) {
        if (filter.name === EEquipmentFilterAttributes.PRODUCT_CATEGORY) {
          filter.options = [this.productPriceGroupSparePartsTranslate, this.productPriceGroupConsumableTranslate];
        }
        this.contractFilters.push(filter);
      }
    });
  }
}
