import { Component, Input } from '@angular/core';
import { EAddressType } from '../../../../configurations/common';
import { OrderReviewService } from '../../services/order-review.service';
import { IAddressData } from '../../../../models/checkout.models';

@Component({
  selector: 'app-addresses-section',
  templateUrl: './addresses-section.component.html',
  styleUrl: './addresses-section.component.scss',
})
export class AddressesSectionComponent {
  @Input() checkoutAddresses: IAddressData[];
  @Input() selectedShipmentMethodName: string;
  @Input() cartId: string;

  protected readonly EAddressType = EAddressType;
  isReportWrongAddressModalVisible: boolean = false;
  reportedAddress: IAddressData;

  constructor(protected orderReviewService: OrderReviewService) {
  }

  /**
   * Open report wrong address modal and set reportedAddress.
   * @param {IAddressData} address
   */
  openReportWrongAddressModal(address: IAddressData): void {
    this.reportedAddress = address;
    this.isReportWrongAddressModalVisible = true;
  }

  /**
   * Close report wrong address modal and reset address.
   */
  closeReportWrongAddressModal(): void {
    this.reportedAddress = null;
    this.isReportWrongAddressModalVisible = false;
  }
}
