import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';

import { State } from '../../reducers';
import { CheckoutFacade } from '../../facades/checkout.facade';
import * as OrderApproveSelectors from '../../reducers/order-approve.reducer';
import * as OrderApprovalActions from '../../actions/order-approve.actions';
import { IAddressData, ICheckoutUpdate, ICustomAddressData } from '../../models/checkout.models';
import { IAddress } from '../../models/common.models';
import { AddressUtils } from '../../utils/address.utils';
import { CustomerFacade } from '../../facades/customer.facade';
import { take } from 'rxjs/operators';
import { EGlueResource } from '../../configurations/common';
import * as ShopCartSelectors from '../../reducers/shop-cart.reducer';

@Component({
  selector: 'app-change-address-modal',
  templateUrl: './change-address-modal.component.html',
  styleUrls: ['./change-address-modal.component.scss'],
})
export class ChangeAddressModalComponent implements OnInit, OnDestroy {
  updateAddressIsInProgress: boolean = false;
  changeAddressForm: UntypedFormGroup;
  businessAddresses: IAddress[];
  customerAddresses: IAddress[];
  addresses: Array<IAddressData> = [];
  currentClickedAddress: IAddress;
  data: ICheckoutUpdate;
  cartId: string;
  checkoutDataLoaded: boolean;
  @Input() previousCartId: string = '';
  @Input() isOrderApprovalPage: boolean = false;
  @Input() showModal: boolean;
  @Output() closeModal = new EventEmitter<any>();
  @Output() selectShippingAddress = new EventEmitter<any>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<State>,
    private checkoutFacade: CheckoutFacade,
    private customerFacade: CustomerFacade,
  ) {
  }

  ngOnInit(): void {
    this.customerFacade.beginGetBusinessUnitsAction();
    this.getCartId();
    this.getCheckoutData();
    this.getBusinessAddresses();
    this.getCustomerAddresses();
    this.initializeForm();
  }

  ngOnDestroy(): void {
    this.getCartId().unsubscribe();
    this.getCheckoutData().unsubscribe();
    this.getBusinessAddresses().unsubscribe();
    this.getCustomerAddresses().unsubscribe();
  }

  getCartId(): Subscription {
    return this.store.select(ShopCartSelectors.selectCurrentCartId).subscribe(cartId => {
      this.cartId = cartId;
    });
  }

  resetForm(): void {
    this.closeModal.emit();
    this.changeAddressForm.reset();
  }

  getBusinessAddresses(): Subscription {
    return this.customerFacade.selectBusinessAddress().subscribe(addresses => {
      if (addresses) {
        this.businessAddresses = addresses;
        AddressUtils.appendAddresses(this.addresses, this.businessAddresses, 'business');
      }
    });
  }

  getCustomerAddresses(): Subscription {
    return this.customerFacade.selectCustomerAddresses().subscribe(addresses => {
      if (addresses) {
        this.customerAddresses = addresses;
        AddressUtils.appendAddresses(this.addresses, this.customerAddresses, 'customer');
      }
    });
  }

  getCheckoutData(): Subscription {
    return this.store.select(OrderApproveSelectors.getShippingAddress).subscribe(
      data => {
        if (data) {
          const selectedAddress: ICustomAddressData = {
            attributes: data as IAddress,
          };
          this.addresses = this.checkoutFacade.convertDataToDisplayAddresses([selectedAddress], this.addresses);
          this.checkoutDataLoaded = true;
        }
      },
    );
  }

  initializeForm(): void {
    this.changeAddressForm = this.formBuilder.group({
      search: '',
    });
  }

  selectNewAddress(address: IAddress): void {
    this.currentClickedAddress = address;
  }

  saveNewAddress(): void {
    this.updateAddressIsInProgress = true;

    if (this.isOrderApprovalPage) {
      this.cartId = this.previousCartId;
    }

    this.data = {
      type: EGlueResource.CHECKOUT_UPDATE,
      attributes: {
        idCart: this.cartId,
        isAddressSavingSkipped: true,
        shippingAddress: {
          salutation: 'Mr',
          firstName: this.currentClickedAddress?.firstName || 'empty',
          lastName: this.currentClickedAddress?.lastName || 'empty',
          address1: this.currentClickedAddress?.address1 || 'empty',
          address2: this.currentClickedAddress?.address2 || 'empty',
          address3: '',
          zipCode: this.currentClickedAddress?.zipCode || 'empty',
          city: this.currentClickedAddress?.city || 'empty',
          country: this.currentClickedAddress?.country || 'empty',
          iso2Code: this.currentClickedAddress?.iso2Code || 'DE',
          phone: this.currentClickedAddress?.phone || 'empty',
          company: this.currentClickedAddress?.company || 'empty',
          state: this.currentClickedAddress?.state || 'empty',
          isDefaultBilling: false,
          isDefaultShipping: false,
        } as IAddress,
      },
    };

    this.checkoutFacade.postCheckoutData({data: this.data}).pipe(
      take(1),
    ).subscribe({
      next: () => {
        this.store.dispatch(OrderApprovalActions.setShippingAddress({address: this.currentClickedAddress}));
        this.selectShippingAddress.emit();

        this.updateAddressIsInProgress = false;
        this.resetForm();
      }
    });
  }
}
