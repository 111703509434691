import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ISystemDetails } from '../../../../../models/common.models';
import { AppUtils } from '../../../../../utils/app.utils';
import { EStoreTypes } from '../../../../../configurations/common';
import { AnalyticsService } from '../../../../../analytics/analytics.service';
import { MarketingFacade } from '../../../../../facades/marketing.facade';
import { SearchBarComponent } from '../../../../../shared/search-bar/search-bar.component';
import { ICatalogProduct } from '../../../../../models/abstract-product.models';
import { CustomerFacade } from '../../../../../facades/customer.facade';
import { CatalogFacade } from '../../../../../facades/catalog.facade';


@Component({
  selector: 'app-add-parts-to-cart',
  templateUrl: './add-parts-to-cart.component.html',
  styleUrls: ['./add-parts-to-cart.component.scss'],
})
export class AddPartsToCartComponent implements OnInit, OnDestroy {
  product: ICatalogProduct = null;
  quantity: number = 1;
  loading: boolean = false;
  loadingButton: boolean = false;
  itemsInQueueToCart = [];
  isBusinessPartner: boolean = false;
  pricingGroupForConsumables: string = '111';

  private unsubscribe$ = new Subject<void>();

  @Input() systemDetails: ISystemDetails = null;
  @Input() disabledAddButton: boolean = false;
  @Output() uploadedFilesViaSearchBar: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild(SearchBarComponent) searchBar:SearchBarComponent;

  constructor(
    private analyticsService: AnalyticsService,
    private marketingFacade: MarketingFacade,
    private customerFacade: CustomerFacade,
    private catalogFacade: CatalogFacade,
  ) {
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    this.selectItemsInQueueToCart();
    this.selectIsCustomerBusinessPartner();
    this.catalogFacade.getPricingGroupForConsumables().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(pricingGroup => {
        this.pricingGroupForConsumables = pricingGroup;
      }
    );
  }

  selectItemsInQueueToCart(): void {
    this.marketingFacade.selectItemsInQueueToCart().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(itemsInQueueToCart => {
      if (this.itemsInQueueToCart.length > 0 && itemsInQueueToCart.length == 0) {
        this.quantity = 1;
        this.product = null;
        this.searchBar.clearQuery();
      }
      this.itemsInQueueToCart = itemsInQueueToCart;
    });
  }

  updateProductQuantity(value) {
    value = parseInt(value.toString());
    if (value < 1) {
      this.quantity = 1;
    } else if (value > 99) {
      this.quantity = 99;
    } else {
      this.quantity = value;
    }
  }

  addProductToCart() {
    if (this.product != null) {
      const systemDetails = this.systemDetails;
      const concreteSku = this.product.abstractSku.replace('A_', '');

      let data = {
        sku: concreteSku,
        name: this.product.abstractName,
        systemDetails: null,
        systemDetailsPerItem: [],
      };

      if (AppUtils.isStoreActive(EStoreTypes.US)) {
        data = {
          ...data,
          systemDetails,
          systemDetailsPerItem: [{
            itemId: data.sku,
            systemDetails,
          }],
        };
      }

      this.analyticsService.setProducts(this.product);
      this.analyticsService.trackCart('cart.add');
      this.marketingFacade.addProductToCart(
        data,
        !!concreteSku,
        this.quantity,
      );
    }
  }

  isItemInProgress(): boolean {
    if (this.loadingButton) {
      return true;
    }
    if (!this.product) {
      this.uploadedFilesViaSearchBar.emit(false);
      return false;
    }
    const checkAbstractSku = this.itemsInQueueToCart.includes(this.product.abstractSku.replace('A_', '')) ||
      this.itemsInQueueToCart.includes(this.product.abstractSku);
    this.uploadedFilesViaSearchBar.emit(checkAbstractSku);
    return checkAbstractSku;
  }

  /**
   * Method to select if customer is business partner
   */
  selectIsCustomerBusinessPartner(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isBusinessPartner => {
      this.isBusinessPartner = isBusinessPartner;
    });
  }

  /**
   * Returns true if product is available for online purchase
   *  user has to be non-business partner
   *  FL has to be selected
   *  product is available for online purchase
   *    if product is consumable and system details is not isSparePartsAvailable
   *    or system details is isSparePartsAvailable
   *
   * @return {boolean}
   */
  checkIfProductIsAvailableForOnlinePurchase(): boolean {
    if (this.product && !this.isBusinessPartner && this.systemDetails?.siemensEquipmentId) {
      return (
        this.product?.attributes?.sap_p40_material_pricing_group_3 === this.pricingGroupForConsumables
        && !this.systemDetails.isSparePartsAvailable
      ) || this.systemDetails.isSparePartsAvailable
    } else {
      return true;
    }
  }
}
