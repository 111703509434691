import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ICheckoutInput,
  ICustomerCheckout,
  IPaymentMethodName,
  IPaymentProviderName,
  IConsentCheckbox,
  IAddressData,
  IShipmentMethod, ICheckoutFormData,
} from '../../models/checkout.models';
import { IAddress, IPointOfContact, IPriceDisputingPerItem } from '../../models/common.models';
import { EFeatureToggles, EGlueResource, EStoreTypes } from '../../configurations/common';
import { IconType } from '../../models/settings.model';
import { Observable, Subject } from 'rxjs';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { ActivatedRoute, Router } from '@angular/router';
import { AnalyticsService } from '../../analytics/analytics.service';
import { MarketingFacade } from '../../facades/marketing.facade';
import { CustomerFacade } from '../../facades/customer.facade';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { map, take, takeUntil } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { LocalStorageUtils } from '../../utils/localStorage.utils';
import { AppUtils } from '../../utils/app.utils';
import { OrderReviewService } from './services/order-review.service';
import { I18nService } from '../../services';
import { PayloadUtils } from '../../utils/payload.utils';
import { CartUtils } from '../../utils/cart.utils';
import { ISapMessage } from '../../models/sap.model';
import { ICart, ICartItemWithDetail, ICartRule } from '../../models/cart.models';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { TaxUtils } from '../../utils/tax.utils';

@Component({
  selector: 'app-harmonized-page-order-review',
  templateUrl: './harmonized-page-order-review.component.html',
  styleUrls: ['./harmonized-page-order-review.component.scss'],
})
export class HarmonizedPageOrderReviewComponent implements OnInit, OnDestroy {
  protected readonly AppUtils = AppUtils;
  protected readonly EStoreTypes = EStoreTypes;
  protected readonly IconType = IconType;
  private unsubscribe$ = new Subject<void>();

  // Content generation
  termsAndConsentsCheckboxes: IConsentCheckbox[] = [];
  checkoutAddresses: IAddressData[] = [];
  protected isSidebarTitleVisible$: Observable<boolean> =
              this.configurationFacade.isFeatureEnabled(EFeatureToggles.CHECKOUT_SIDEBAR_TITLE);
  protected isSidebarSubtitleVisible$: Observable<boolean> =
              this.configurationFacade.isFeatureEnabled(EFeatureToggles.CHECKOUT_SIDEBAR_SUBTITLE);

  // Cart related variables
  cartId: string;
  cart: ICart;
  cartItemsWithDetails: ICartItemWithDetail[];
  cartRules: ICartRule[];
  cartSapMessages: ISapMessage[] = [];
  isCartLoading: boolean = true;
  cartHasTotalPriceToPayZero: boolean = false;
  taxPercentage: number = 0;
  priceDisputingPerItems: IPriceDisputingPerItem[] = [];
  selectedShipmentMethod: IShipmentMethod;
  billingAddress: IAddress;

  // Operations
  isUnavailableShipmentMethodModalOpen: boolean = false;
  isSubmitButtonDisabled: boolean = false;

  // General data
  cacheSapPoNumberAndCopyEmail: boolean = true;
  checkoutFormData: ICheckoutFormData;

  constructor(
    private checkoutFacade: CheckoutFacade,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService,
    private marketingFacade: MarketingFacade,
    private customerFacade: CustomerFacade,
    private configurationFacade: ConfigurationFacade,
    protected orderReviewService: OrderReviewService,
    protected i18nService: I18nService,
  ) {}

  ngOnInit(): void {
    this._getCurrentCartId();
    this._getCurrentCartData();
    this._selectPriceDisputingPerItems();
  }

  ngOnDestroy(): void {
    if (this.cacheSapPoNumberAndCopyEmail) {
      this.checkoutFacade.setSapPoNumber(this.checkoutFormData?.purchaseOrderNumber);
      this.checkoutFacade.setOrderEmailCopy(this.checkoutFormData?.orderEmailCopy);
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Get current cart id from URL.
   * @private
   */
  private _getCurrentCartId(): void {
    this.route.params.subscribe(params => {
      if (params.orderId) {
        this.cartId = params.orderId;
      }
    });
  }

  /**
   * Fetch data from following glue resources:
   * CART_ITEMS, CONCRETE_PRODUCTS, CONCRETE_PRODUCT_IMAGE_SETS, VOUCHERS, CART_RULES, RFQ_PRODUCTS, SAP_MESSAGES,
   * SAP_ITEM_AVAILABILITIES, SHIPMENTS.
   */
  private _getCurrentCartData(): void {
    this.marketingFacade.getCartItems(this.cartId)
      .pipe(
        map(response => {
          const cartItemsWithDetails: ICartItemWithDetail[] = CartUtils.mapAvailabilitiesToCartItems(
            PayloadUtils.getItemsWithDetailsFromInclude(response.included),
            response.included);
          const cartRules: ICartRule[] = response.included
            .filter(responseInclude => responseInclude.type === EGlueResource.CART_RULES);
          const cartSapMessages: ISapMessage[] = response.included
            .filter(responseInclude => responseInclude.type === EGlueResource.SAP_MESSAGES);
          const selectedShipmentMethod: IShipmentMethod = response.included
            .filter(responseInclude => responseInclude.type === EGlueResource.SHIPMENTS)[0]
            .attributes?.selectedShipmentMethod;
          const cart: ICart = response.data;
          return [cart, cartItemsWithDetails, cartRules, cartSapMessages, selectedShipmentMethod];
        }),
        take(1),
      )
      .subscribe({
        next: ([cart, cartItemsWithDetails, cartRules, sapCartMessages, selectedShipmentMethod]:
                 [ICart, ICartItemWithDetail[], ICartRule[], ISapMessage[], IShipmentMethod]) => {
          this.isCartLoading = false;
          this.cart = cart;
          this.cartItemsWithDetails = cartItemsWithDetails;
          this.cartRules = cartRules;
          this.cartSapMessages = sapCartMessages;
          this.selectedShipmentMethod = selectedShipmentMethod;
          this.taxPercentage = TaxUtils.getTaxPercentage(cartItemsWithDetails);

          this.termsAndConsentsCheckboxes =
            this.orderReviewService.createTermsAndConsentsCheckboxes(this.cart.attributes.taxDisclaimer);
          this.checkoutAddresses = this.orderReviewService.checkoutAddressesInitialization(this.cart);

          this.orderReviewService.updateTotalsAndDiscounts(cart.attributes.totals, cart.attributes.discounts);
          this.cartHasTotalPriceToPayZero = this.cart?.attributes.totals.priceToPay === 0;
          //TODO: check also if cart has POnumber in approverDetails object,
          // if yes prefill it, in cas the cart has zero price, prefill CONTRACT

          // if (this.cartHasTotalPriceToPayZero) {
          //   this.configurationFacade.getTranslationByKey('order-review.default-purchase-order-number')
          //     .pipe(take(1))
          //     .subscribe(translation => {
          //       this.orderReviewForm.patchValue({'purchaseOrderNumber': translation});
          //     });
          // }

          if (!this.cart.attributes.billingAddress) {
            this._getBusinessAddress();
          }

          this.analyticsService.setCartId(this.cartId);
          this.analyticsService.setProducts(this.cart);
          this.analyticsService.trackPageReady('spare part - order review', PageTypes.ORDER_REVIEW, 'concrete-products');
        },
        error: () => {
          this.router.navigate([this.i18nService.getCurrentLocale()]).then();
          this.isCartLoading = false;
        },
      });
  }

  /**
   * Fetch price disputing per item as array of IPriceDisputingPerItem.
   */
  private _selectPriceDisputingPerItems(): void {
    this.marketingFacade.selectPriceDisputing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(priceDisputingPerItems => {
        this.priceDisputingPerItems = priceDisputingPerItems;
      });
  }

  // *** Form operations ***

  /**
   * Set if submit button should be disabled (for example while processing discounts in child components).
   * @param {boolean} value
   */
  setCheckoutFormData(value: ICheckoutFormData): void {
    this.checkoutFormData = value;
  }


  /**
   * Form is valid when:
   * - cart doesn't contain error messages from SAP
   * - status of form is "VALID"
   * - all required checkboxes are checked (requirement is specified in arakh)
   * - all shown addresses based on arakh configuration are valid
   * @returns {boolean}
   */
  formIsValid(): boolean {
    return !this.orderReviewService.checkHasNoErrorMessages(this.cartSapMessages)
      && this._checkAllRequiredCheckboxes()
      && this._checkAllAddressesAreValid()
      && !this.isSubmitButtonDisabled;
  }

  /**
   * Check if all shown addresses are valid.
   * @returns {boolean}
   */
  private _checkAllAddressesAreValid(): boolean {
    return this.checkoutAddresses
      .every(address => address?.isAddressValid);
  }

  /**
   * Check if all required checkboxes are checked (requirement is specified in arakh).
   * @returns {boolean}
   */
  private _checkAllRequiredCheckboxes(): boolean {
    return this.termsAndConsentsCheckboxes
      .every(checkbox => !checkbox.isRequired || (checkbox.isRequired && checkbox.isChecked));
  }

  /**
   * Return true if cart and all related data are loaded and checkout call is not in progress.
   * @returns {boolean}
   */
  areAllDataLoaded(): boolean {
    return !this.isCartLoading;
  }

  /**
   * Set if submit button should be disabled (for example while processing discounts in child components).
   * @param {boolean} value
   */
  setIsSubmitButtonDisabled(value: boolean): void {
    this.isSubmitButtonDisabled = value;
  }

  /**
   * Open shipment unavailable modal if shipment.validation.expired error is returned from postCheckout.
   */
  showUnavailableShipmentMethodModal(): void {
    this.isUnavailableShipmentMethodModalOpen = true;
  }

  /**
   * Close shipment unavailable modal and redirect to delivery details page with address ID to be preselected.
   */
  closeUnavailableShipmentMethodModal(): void {
    this.isUnavailableShipmentMethodModalOpen = false;
    this._redirectToDeliveryDetails();
  }

  /**
   * Redirect to delivery details page with address ID to be preselected.
   * @private
   */
  private _redirectToDeliveryDetails(): void {
    const addressId = this.cart.attributes.shippingAddress.id ?? this.cart.attributes.shippingAddress.sapId;
    const params = {addressId};
    this.router.navigate(['/delivery-details'],
      {
        queryParams: params,
      }).then();
  }

  // *** Checkout operations *** TODO: Check functionality and refactor code

  /**
   * Called after submitting order. After receiving price of shipment method via getShipmentMethodPrice() function of
   * CheckoutFacade, checkoutInputRequest is created and passed to postCheckout() function of CheckoutFacade.
   */
  proceedToSummaryPage(): void {
    this.isSubmitButtonDisabled = true;
    let customerData: ICustomerCheckout = {
      'attentionTo': null,
      'email': null,
      'salutation': null,
      'firstName': null,
      'lastName': null,
      'idCustomer': 0,
      'customerReference': null,
      'uuidCompanyUser': null,
    };

    this.checkoutFacade.getShipmentMethodPrice(this.cartId)
      .pipe(take(1))
      .subscribe(shipmentData => {
        const checkoutInputRequest: ICheckoutInput = {
          type: 'checkout',
          attributes: {
            idCart: this.cartId,
            isAddressSavingSkipped: true,
            payments: [
              // TODO: Replace dummy payment values with correct ones.
              {
                paymentMethodName: IPaymentMethodName.DUMMY,
                paymentProviderName: IPaymentProviderName.DUMMY,
              },
            ],
            shipment: {
              idShipmentMethod: this.selectedShipmentMethod.id,
            },
            billingAddress: this.cart.attributes.billingAddress ?? this._createBillingAddressFromBusinessUnit(),
            termsAccessTime: new Date(),
            consentedTerms: this.termsAndConsentsCheckboxes
              .filter(checkbox => checkbox.isChecked)
              .map(checkbox => checkbox.name),
            customer: shipmentData?.data?.attributes?.customer as ICustomerCheckout ? shipmentData?.data?.attributes?.customer : customerData,
            sapPoNumber: this.checkoutFormData.purchaseOrderNumber,
            taxNumber: this.checkoutFormData.taxNumber,
            orderEmailCopy: this.checkoutFormData.orderEmailCopy || undefined,
          },
        };

        this.postCheckout(checkoutInputRequest);
        this.customerFacade.clearDeliveryPagesData(this.cartId);
        this.cacheSapPoNumberAndCopyEmail = false;
        LocalStorageUtils.clearKey('opal');
      });
  }

  /**
   * Get billing address from customer business unit.
   *
   * @private
   */
  private _getBusinessAddress(): void {
    this.customerFacade.getBusinessUnits().pipe(
      map((payload) => {
        return PayloadUtils.getIncludedData(payload.data[0], payload.included, 'company-business-unit-addresses')[0];
      }),
      takeUntil(this.unsubscribe$),
    ).subscribe(address => {
      this.billingAddress = address.attributes;
    });
  }


  /**
   * Create billing address.
   *
   * @returns {IAddress}
   * @private
   */
  private _createBillingAddressFromBusinessUnit(): IAddress {
    return {
      idCustomerAddress: this.billingAddress.idCustomerAddress,
      idCompanyUnitAddress: this.billingAddress.idCompanyUnitAddress,
      salutation: 'Mr',
      firstName: 'empty',
      lastName: 'empty',
      address1: (this.billingAddress.address1) ? this.billingAddress.address1 : 'empty',
      address2: (this.billingAddress.address2) ? this.billingAddress.address2 : 'empty',
      zipCode: (this.billingAddress.zipCode) ? this.billingAddress.zipCode : 'empty',
      city: (this.billingAddress.city) ? this.billingAddress.city : 'empty',
      iso2Code: (this.billingAddress.iso2Code) ? this.billingAddress.iso2Code : 'empty',
      phone: (this.billingAddress.phone) ? this.billingAddress.phone : 'empty',
      company: 'empty',
      country: (this.billingAddress.country) ? this.billingAddress.country : 'empty',
      isDefaultBilling: false,
      isDefaultShipping: false,
    };
  }

  /**
   *
   * @param {ICheckoutInput} checkoutInputRequest
   * @private
   */
  private postCheckout(checkoutInputRequest: ICheckoutInput): void {
    this.checkoutFacade.postCheckout({data: checkoutInputRequest})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: checkout => {
          if (checkout.data.id !== this.cartId) {
            this.analyticsService.setProducts(this.cartItemsWithDetails);
            this.analyticsService.trackCart('cart.order');
            this.marketingFacade.createEmptyCart();
            this.customerFacade.setPointOfContact({} as IPointOfContact);
            this.router.navigate(['/order-thank-you-page/', checkout.data.id]);
          } else {
            this.marketingFacade.createEmptyCart();
            this.customerFacade.setPointOfContact({} as IPointOfContact);
            this.orderReviewService.showNotificationAndRedirectToHomePage();
          }
        },
        error: error => {
          this.isSubmitButtonDisabled = false;
          if (error instanceof HttpErrorResponse && error.status === 504) {
            this.marketingFacade.deleteCart(this.cartId);
            this.orderReviewService.showNotificationAndRedirectToHomePage();
          }
          if ((error?.error?.errors as any[]).find(error => error.detail === 'shipment.validation.expired')) {
            this.showUnavailableShipmentMethodModal();
          } else {
            this.orderReviewService.showNotificationAndRedirectToHomePage();
          }
        },
      });
  }
}
