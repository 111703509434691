<div class="order-review container">
  <ng-container *ngIf="areAllDataLoaded()">
    <div class="order-review-container row">
      <!--
      Left column checkout
      -->
      <div class="col-12 col-lg-8">
        <div class="order-review-form-container">
          <h3 class="page-title" [attr.data-test]="'title-orderReview'">
            {{ 'order-review.page-title' | translate }}
          </h3>
          <app-sap-message-controller [messages]="cartSapMessages"/>
          <app-checkout-form
              [checkoutAddresses]="checkoutAddresses"
              [cartHasTotalPriceToPayZero]="cartHasTotalPriceToPayZero"
              [cartItemsWithDetails]="cartItemsWithDetails"
              [currency]="cart.attributes.currency"
              [pointOfContact]="cart.attributes.pointOfContact"
              [cartId]="cartId"
              [selectedShipmentMethod]="selectedShipmentMethod"
              (setIsSubmitButtonDisabled)="setIsSubmitButtonDisabled($event)"
              (checkoutFormData)="setCheckoutFormData($event)"/>
        </div>
      </div>

      <!--
      Right column order summary
      -->
      <div class="col-12 col-lg-4">
        <div class="order-summary-container">
          @if ((isSidebarTitleVisible$ | async) || (isSidebarSubtitleVisible$ | async)) {
            <p class="disclaimer--finish-order">
              @if (isSidebarTitleVisible$ | async) {
                <div>{{ 'order-review.submit-order-title' | translate }}</div>
              }
              @if (isSidebarSubtitleVisible$ | async) {
                <div>{{ 'order-review.submit-order-subtitle' | translate }}</div>
              }
            </p>
          }
          <div class="buttons-group">
            <button
                appDebounceClick
                type="submit"
                class="button button--primary"
                [disabled]="!formIsValid()"
                [debounceTime]="300"
                (debounceClick)="proceedToSummaryPage()"
                [attr.data-test]="'button-proceedToSummaryPage'">
              {{ 'order-review.submit-order-button' | translate }}
            </button>
            @if (!orderReviewService.fileGenerationInProgress) {
              <button type="button" class="button button--secondary print-as-pdf"
                      (click)="orderReviewService.generatePdfFile(cart)">
                {{ 'order-review.print-pdf-button' | translate }}
              </button>
            } @else {
              <div class="btn-spinner">
                <div class="loading-animation"></div>
              </div>
            }
          </div>

          <div class="order-review-inner-container">
            <app-terms-and-consents [termsAndConsentsCheckboxes]="termsAndConsentsCheckboxes"/>
            <app-totals-and-discounts
                [currency]="cart?.attributes?.currency"
                [taxPercentage]="taxPercentage"
                [cartId]="cartId"
                (setIsSubmitButtonDisabled)="setIsSubmitButtonDisabled($event)"/>
            @if (cartRules?.length > 0 && cart.attributes.discounts?.length === 0) {
              <app-cart-rules
                  [cartRules]="cartRules"
                  [priceToPay]="cart?.attributes?.totals.priceToPay"
                  [currency]="cart?.attributes?.currency">
              </app-cart-rules>
            }
            @if (selectedShipmentMethod) {
              <section class="shipment-and-payment">
                <strong class="shipment-method" [attr.data-test]="'desc-shippingMethod'">
                  {{ selectedShipmentMethod?.name }}
                </strong>
              </section>
            }
          </div>
          <hr class="bottom-line">
          <a class="button button--link button-back-to-cart" [routerLink]="['/shop-cart']"
             [attr.data-test]="'link-backToCart'">
            <span class="icon-arrow-left color-orange"></span>
            {{ 'checkout-delivery-details.back-to-cart' | translate }}
          </a>
        </div>
      </div>
    </div>
  </ng-container>

  <!--
  Loading animation
  -->
  <div class="loader__overlay" *ngIf="!areAllDataLoaded()">
    <div class="loading-animation"></div>
  </div>
</div>

<app-modal
    class="unavailable-shipment-method-modal"
    [id]="'unavailable-shipment-method-modal'"
    [type]="IconType.WARNING"
    [open]="isUnavailableShipmentMethodModalOpen"
    title="{{ 'order-review.unavailable-shipment-method-modal.title' | translate }}"
    (crossClick)="closeUnavailableShipmentMethodModal()"
    (outsideClick)="closeUnavailableShipmentMethodModal()">
  <ng-container ngProjectAs="[slot][body]">
    <p>{{ 'order-review.unavailable-shipment-method-modal.text' | translate }}</p>
  </ng-container>
  <ng-container ngProjectAs="[slot][footer]">
    <div class="row">
      <div class="col-12">
        <button type="button"
                class="button button--primary btn-submit"
                data-dismiss="modal-dialog"
                (click)="closeUnavailableShipmentMethodModal()">
          {{ 'order-review.unavailable-shipment-method-modal.button-ok' | translate }}
        </button>
      </div>
    </div>
  </ng-container>
</app-modal>
