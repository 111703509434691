import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { skipWhile, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as OrderApproveActions from '../../actions/order-approve.actions';
import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { CustomerFacade } from '../../facades/customer.facade';
import { MarketingFacade } from '../../facades/marketing.facade';
import { OrdersFacade } from '../../facades/orders.facade';
import { ICart, ICartRule } from '../../models/cart.models';
import {
  IApproverData,
  ICheckout,
  IPaymentMethodName,
  IPaymentProviderName,
  IShipmentMethod,
} from '../../models/checkout.models';
import { IAddress, IBaseModel, ISelectBoxOption } from '../../models/common.models';
import { ICustomer } from '../../models/customer.models';
import { State } from '../../reducers';
import { I18nService } from '../../services';
import { TermConstants } from '../../shared/terms/TermConstants';
import { AddressUtils } from '../../utils/address.utils';
import { ContactPointModalComponent } from '../../shared/contact-point-modal/contact-point-modal.component';
import { AppUtils } from '../../utils/app.utils';
import { EGlueResource, EStoreTypes, EUserRoles } from '../../configurations/common';
import { environment } from '../../../environments/environment';
import { TaxUtils } from '../../utils/tax.utils';
import { CustomerActions, ShopCartActions } from '../../actions';
import { GlueUtils } from '../../utils/glue.utils';

@Component({
  selector: 'app-order-approve',
  templateUrl: './order-approve.component.html',
  styleUrls: ['./order-approve.component.scss'],
})
export class OrderApproveComponent implements OnInit, OnDestroy {
  @Input() modelValue: any;
  @ViewChild(ContactPointModalComponent) pocModal: ContactPointModalComponent;

  isAuStore: boolean = false;
  isJpStore: boolean = false;

  showModalContactPoint: boolean;
  showModalChangeAddress: boolean;
  showModalConfirmRemove: boolean;
  showModalDeclineConfirm: boolean;
  showSuccessModal: boolean = false;

  addresses: Array<ISelectBoxOption> = [];
  businessAddressesLoaded: boolean;
  businessAddresses: any;

  selectedDeliveryAddress: any;
  selectedDeliveryAddressLoaded: boolean = false;

  billingAddress: IAddress;
  shippingAddress: IAddress;
  data: {data: ICheckout};

  currentCart: any;
  cartLoaded: boolean;
  itemsInCart: any[];
  cartTotals: any;
  cartId: string;
  clickedItemDataName: string;
  clickedItemDataId: string;
  removingItemInProgress: boolean = false;

  termsAccessTime: Date;
  privacyPolicyUrl = {
    value: '', type: 'external',
  };
  termsAndConditionsUrl = {
    value: '', type: 'internal',
  };
  approveOrderForm: any;

  voucherButtonIsClicked: boolean;
  voucherHasError: boolean = false;
  voucherList = [];
  showAdditionalInfoForVoucher: boolean;
  voucherListLoaded: boolean = false;
  totalPrice: number;
  cartSubtotal: number;
  cartApprovalData: any;
  checkoutData: ICheckout;
  loggedUserRoles: EUserRoles[];
  approverInfo: {
    approverId: string,
    comment: string,
    dueDate: string,
    firstName: string,
    lastName: string
  };
  cartCurrency: string;
  currentUserCartId: string;
  cartApprovalDataLoaded: boolean = false;
  actionInProgress: boolean = false;
  deleteLastItemAndDeclineOrderFlag: boolean;
  cartItemsCount: number;
  isGuest: boolean;
  loggedUserId: string;
  userData: ICustomer;
  cartRules: Array<ICartRule>;
  voucherInProgress: boolean = false;
  selectedShipmentMethod: IShipmentMethod;
  shipmentMethodsLoaded: boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private checkoutFacade: CheckoutFacade,
    private store: Store<State>,
    private formBuilder: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private analyticsService: AnalyticsService,
    private marketingFacade: MarketingFacade,
    private ordersFacade: OrdersFacade,
    private customerFacade: CustomerFacade,
    private configurationFacade: ConfigurationFacade,
    private i18nService: I18nService,
  ) {
  }

  ngOnInit(): void {
    this.isAuStore = AppUtils.isStoreActive(EStoreTypes.AU);
    this.isJpStore = AppUtils.isStoreActive(EStoreTypes.JP);

    this.route.params.subscribe((params) => {
      if (params.orderId) {
        this.cartId = params.orderId;
      }
    });
    this.getCartApprovalData();
    this.customerFacade.beginGetBusinessUnitsAction();
    this.selectBusinessAddresses();
    this.initializeForm();

    this.currentUserCartId = '';
    this.getMiniCartData();

    this.getCompanyUserData();
    this.getUserData();

    this.privacyPolicyUrl = this.configurationFacade.getTermsUrl(
      TermConstants.termKeys.PRIVACY_POLICY.termsKey,
    );
    this.termsAndConditionsUrl = this.configurationFacade.getTermsUrl(
      TermConstants.termKeys.TERMS_AND_CONDITIONS.termsKey,
    );

    this.selectCartOperationInProgress();
  }

  ngOnDestroy(): void {
    this.clearCheckoutData();

    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getMiniCartData(): void {
    this.marketingFacade.selectCartId().pipe(
      takeUntil(this.unsubscribe$),
      skipWhile(cartId => !cartId),
    ).subscribe(cartId => {
      this.currentUserCartId = cartId;
      if (cartId !== this.cartId) {
        this.checkoutFacade.actionLoadCartItemsOrderApprove(this.cartId);
      }

      this.selectCartItemsSubscription();
      this.selectCartItemsLoadFailSubscription();
    });
  }

  selectCartItemsLoadFailSubscription(): void {
    this.checkoutFacade.selectCartItemsLoadFail().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cartItemsLoadFail => {
      if (cartItemsLoadFail) {
        this.router.navigate([this.i18nService.getCurrentLocale()]).then();
      }
    });
  }

  selectCartItemsSubscription(): void {
    const cartItems$ = this.marketingFacade.getCartItems(this.cartId);

    cartItems$.pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cartItems => {
      if (!cartItems) {
        return;
      }

      if (cartItems) {
        let cart: ICart;
        cart = cartItems.data as ICart;
        cart.included = cartItems.included;
        this.currentCart = cart;
        this.cartRules = cartItems.included.filter(include => include.type === EGlueResource.CART_RULES);
        this.analyticsService.setProducts(this.currentCart);
        this.analyticsService.setCartId(this.cartId);
        this.itemsInCart = GlueUtils.filterResourcesByType(this.currentCart.included, EGlueResource.CART_ITEMS);
        this._updateVoucherList(this.currentCart.included);
        this.shipmentMethodsLoaded = true;
        this.resolveSelectedShipmentMethod();
        this.cartLoaded = true;
        this.cartTotals = this.getTotals(cartItems);
        this.cartCurrency = this.getCurrency(cartItems);
        this.cartItemsCount = this.getCartItemsCount(this.currentCart);
        this.analyticsService.trackOrderApproved('Order Placed', PageTypes.ORDER_CONFIRMATION_PAGE);
      }
    });
  }

  setCartIdForCheckoutUpdate(): string {
    if (this.cartId !== this.currentUserCartId) {
      return this.cartId;
    } else return this.currentUserCartId;
  }

  initializeForm(): void {
    this.approveOrderForm = this.formBuilder.group({
      address: ['', [Validators.required]],
      invoiceEmailCopy: ['', [Validators.email]],
      paymentMethod: 'Invoice to Hospital',
      promotionCode: '',
      deliveryMethod: 'Normal delivery',
      termsAndConditionsConsent: false,
      privacyPolicyConsent: false,
      taxNumber: ['', Validators.maxLength(30)],
    });
  }

  selectBusinessAddresses(): void {
    this.customerFacade.selectBusinessAddress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(addresses => {
      if (addresses) {
        this.businessAddresses = addresses;
        AddressUtils.appendAddresses(this.addresses, this.businessAddresses, 'business');
        this.businessAddressesLoaded = true;
      }
    });
  }

  showModalToChangeAddress(): void {
    this.showModalChangeAddress = !this.showModalChangeAddress;
  }

  showModalToContactPoint(): void {
    this.showModalContactPoint = !this.showModalContactPoint;
  }

  clickPromotionalButton(): void {
    this.voucherButtonIsClicked = true;
  }

  formIsValid(): boolean {
    return this.approveOrderForm.status === 'VALID';
  }

  placeOrder(): void {
    if (this.formIsValid()) {
      const {value} = this.approveOrderForm.value.address;
      this.billingAddress = {
        idCustomerAddress: value.idCustomerAddress,
        idCompanyUnitAddress: value.idCompanyUnitAddress,
        salutation: 'Mr',
        firstName: 'empty',
        lastName: 'empty',
        address1: (value.address1) ? value.address1 : 'empty',
        address2: (value.address2) ? value.address2 : 'empty',
        zipCode: (value.zipCode) ? value.zipCode : 'empty',
        city: (value.city) ? value.city : 'empty',
        iso2Code: (value.iso2Code) ? value.iso2Code : 'empty',
        phone: (value.phone) ? value.phone : 'empty',
        company: 'empty',
        country: (value.country) ? value.country : 'empty',
        isDefaultBilling: false,
        isDefaultShipping: false,
      };

      const invoiceEmailCopyFormatted = this.approveOrderForm.value.invoiceEmailCopy.length > 0 ?
        this.approveOrderForm.value.invoiceEmailCopy : undefined;

      this.checkoutData = {
        type: EGlueResource.CHECKOUT,
        attributes: {
          idCart: this.cartId,
          isAddressSavingSkipped: true,
          taxNumber: this.approveOrderForm.value.taxNumber,
          pointOfContact: this.cartApprovalData.attributes.pointOfContact,
          approverDetails: this.prepareApproverData(this.cartApprovalData.attributes.approverDetails.comment,
            this.cartApprovalData.attributes.approverDetails.dueDate),
          customer: this.cartApprovalData.attributes.customer,
          billingAddress: this.billingAddress,
          shippingAddress: this.cartApprovalData.attributes.shippingAddress,
          payments: [{
            paymentMethodName: IPaymentMethodName.DUMMY,
            paymentProviderName: IPaymentProviderName.DUMMY,
          }],
          shipment: {
            idShipmentMethod: this.selectedShipmentMethod?.id ?? 1,
          },
          // both checkboxes need to be checked for the form to be valid, which should be the case here
          termsAccessTime: this.termsAccessTime,
          consentedTerms: [
            ...(this.approveOrderForm.controls.termsAndConditionsConsent.value ?
              [TermConstants.termKeys.TERMS_AND_CONDITIONS.termsKey] : []),
            ...(this.approveOrderForm.controls.privacyPolicyConsent.value ?
              [TermConstants.termKeys.PRIVACY_POLICY.termsKey] : []),
          ],
          invoiceEmailCopy: invoiceEmailCopyFormatted,
        },
      };
      this.data = {
        data: this.checkoutData,
      };

      this.actionInProgress = true;

      this.checkoutFacade.postCheckout(this.data).pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe({
          next: response => {
            this.showSuccessModal = true;
            this.analyticsService.trackCart('order.approved');

            this.store.dispatch(OrderApproveActions.postCheckoutDataSuccess({postCheckoutDataResponse: response}));
            this.store.dispatch(OrderApproveActions.setPostCheckoutItems({items: this.currentCart}));
            this.store.dispatch(OrderApproveActions.setUserCartIdDuringCheckout({cartId: this.currentUserCartId}));
            this.store.dispatch(CustomerActions.clearCustomShipToAddress());

            this.marketingFacade.resetCachedCheckoutData();
            this.marketingFacade.createEmptyCart();

            this.proceedToSuccessPage();
            this.actionInProgress = false;
          },
          error: (error) => {
            this.handleCheckoutError(error);
            this.actionInProgress = false;
          },
        },
      );
    }
  }

  prepareApproverData(comment: string, dueDate: string): IApproverData {
    return {
      approverId: this.loggedUserId,
      comment,
      dueDate,
      firstName: this.userData.firstName,
      lastName: this.userData.lastName,
    };
  }

  getItemPicture(id: string): string {
    const idForInfo = id[EGlueResource.CONCRETE_PRODUCTS].data[0].id;
    return this.currentCart.included?.filter(
      (tmp) => tmp.type === EGlueResource.CONCRETE_PRODUCT_IMAGE_SETS && tmp.id === idForInfo)[0]
      .attributes.imageSets.find((img) => img.name === 'default')
      .images.find((smallImage) => smallImage.externalUrlSmall)
      .externalUrlSmall;
  }

  getTotals(cart: any): any {
    return cart?.data?.attributes?.totals;
  }

  getCurrency(cart: any): any {
    return cart?.data?.attributes?.currency;
  }

  getItemPrice(id: string): number {
    return this.currentCart.included?.filter(
      (tmp) => tmp.type === EGlueResource.CART_ITEMS && tmp.id === id
    )[0].attributes.calculations.unitPrice;
  }

  getItemName(id: string): string {
    const idForInfo = id[EGlueResource.CONCRETE_PRODUCTS].data[0].id;
    return this.currentCart.included?.filter(
      (tmp) => tmp.type === EGlueResource.CONCRETE_PRODUCTS && tmp.id === idForInfo,
    )[0].attributes.name;
  }

  getItemSku(id: string): string {
    const idForInfo = id[EGlueResource.CONCRETE_PRODUCTS].data[0].id;
    return this.currentCart.included?.filter(
      (tmp) => tmp.type === EGlueResource.CONCRETE_PRODUCTS && tmp.id === idForInfo
    )[0].id;
  }

  getVoucherTitle(id: string): number {
    const voucherAmount = this.getVoucherAmount(id);
    return Math.round((voucherAmount * 100) / this.cartSubtotal);
  }

  getVoucherAmount(id: string): number {
    return this.voucherList.filter(
      tmp => tmp.type === EGlueResource.VOUCHERS && tmp.id === id
    )[0].attributes.amount;
  }

  getTotalPrice(cartTotals: any): number {
    this.cartSubtotal = cartTotals.subtotal;
    if (this.isJpStore) {
      this.totalPrice = cartTotals.subtotal;
    } else {
      this.totalPrice = cartTotals.taxTotal + cartTotals.subtotal;
    }
    return this.totalPrice;
  }

  getPriceAfterVoucher(): number {
    let totalAmount = 0;
    if (this.voucherList.length > 0) {
      this.voucherList.forEach(
        tmp => {
          totalAmount += tmp.attributes.amount;
        });
    }
    return this.totalPrice - totalAmount;
  }

  getSubTotal(): number {
    return this.cartTotals?.subtotal ?? 0;
  }

  getGrandTotal(): number {
    return this.cartTotals?.grandTotal ?? 0;
  }

  getShipmentTotal(): number {
    return this.cartTotals?.expenseTotal ?? 0;
  }

  getTaxTotal(): number {
    return this.cartTotals?.taxTotal ?? 0;
  }

  getTaxPercent(): number {
    return TaxUtils.getTaxPercentage(this.itemsInCart);
  }

  removeItemFromCart(): void {
    this.store.dispatch(
      ShopCartActions.deleteItemFromCart({cartId: this.cartId, itemId: this.clickedItemDataId}),
    );
    this.showModalConfirmRemove = false;
  }

  private selectCartOperationInProgress(): void {
    this.marketingFacade.selectCartOperationInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inProgress => {
      this.removingItemInProgress = inProgress;
    });
  }

  checkAllLoaded(): boolean {
    return this.cartApprovalDataLoaded && this.cartLoaded && this.shipmentMethodsLoaded;
  }

  getCartApprovalData(): void {
    this.ordersFacade.getCartForApprovalData(this.cartId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        this.cartApprovalData = response.data;
        this.cartApprovalDataLoaded = true;
        this.selectedDeliveryAddress = this.cartApprovalData.attributes.shippingAddress;
        this.store.dispatch(OrderApproveActions.setShippingAddress({address: this.selectedDeliveryAddress}));
        this.approverInfo = this.cartApprovalData.attributes.approverDetails;
        this.selectedDeliveryAddressLoaded = true;

        this.pocModal.pocData = this.cartApprovalData.attributes.pointOfContact;
        this.pocModal.initializeForm();
      });
  }

  setOrderFormValue(event: any): void {
    this.approveOrderForm.patchValue({
      [event.key]: event.value,
    });
  }

  setAddress(event: any): void {
    this.approveOrderForm.patchValue({
      [event.key]: {name: event.name, value: event.value},
    });
  }

  restartShowModal(): void {
    this.showModalConfirmRemove = false;
    this.deleteLastItemAndDeclineOrderFlag = false;
  }

  removeItemFromCartModal(itemName: any, id: any): void {
    this.clickedItemDataName = itemName;
    this.clickedItemDataId = id;
    this.showModalConfirmRemove = true;
    this.deleteLastItemAndDeclineOrderFlag = this.cartItemsCount <= 1;
  }

  proceedToSuccessPage(): void {
    this.checkoutFacade.getResponseCheckoutData().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(
      response => {
        if (response?.data) {
          this.router.navigateByUrl('/summary-success/' + response.data.id);
        }
      },
    );
  }

  backToOrders(): void {
    this.router.navigate(['/my-orders']);
  }

  addVoucher(): void {
    this.voucherInProgress = true;
    this.marketingFacade.postVoucher(this.cartId, this.approveOrderForm.value.promotionCode).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: response => {
        if (response) {
          const currentCartCopy = {...this.currentCart};
          currentCartCopy.data = response.data;
          currentCartCopy.included = response.included;
          this.currentCart = currentCartCopy;
          this._updateVoucherList(response.included);
          this.setOrderFormValue({key: 'promotionCode', value: ''});
        }
        this.voucherInProgress = false;
      },
      error: () => {
        this.voucherListLoaded = false;
        this.voucherHasError = true;
        this.voucherInProgress = false;
      },
    });
  }

  removeVoucher(voucherId: string): void {
    this.actionInProgress = true;
    this.marketingFacade.removeVoucher(this.cartId, voucherId).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe({
      next: response => {
        const currentCartCopy = {...this.currentCart};
        currentCartCopy.data = response.data;
        currentCartCopy.included = response.included;
        this.actionInProgress = false;
        this.setOrderFormValue({key: 'promotionCode', value: ''});
        this.voucherList = this.voucherList.filter(voucher => voucher.attributes.code !== voucherId);
        this._updateVoucherList(this.voucherList);
      },
      error: _ => {
        this.actionInProgress = false;
        this.voucherHasError = true;
      },
    });
  }

  selectNewAddress(): void {
    this.getCartApprovalData();
  }

  getCartItemsCount(cart: any): number {
    let itemsCount = 0;
    if (cart.included.length > 0) {
      cart.included.forEach(
        item => {
          if (item.type === EGlueResource.CART_ITEMS) {
            itemsCount++;
          }
        },
      );
    }
    if (itemsCount === 1) {
      this.deleteLastItemAndDeclineOrderFlag = true;
    }
    return itemsCount;
  }

  lastItemDeclineOrder(): void {
    this.router.navigate([this.i18nService.getCurrentLocale()]).then(
      r => {
        this.marketingFacade.deleteCart(this.cartId);
      }
    );
  }

  getCompanyUserData(): void {
    this.customerFacade.getMyOrderPageData().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.isGuest = state.isGuest;
      this.loggedUserRoles = state.companyRoles;
      this.loggedUserId = state.id;
    });
  }

  getUserData(): void {
    this.customerFacade.selectMyProfileInformation().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(state => {
      this.userData = state;
      this.termsAccessTime = new Date();
    });
  }

  clearCheckoutData(): void {
    this.store.dispatch(OrderApproveActions.getCheckoutUpdateDataSuccess({checkoutUpdateData: null}));
    this.store.dispatch(OrderApproveActions.setShippingAddress({address: null}));
  }

  private handleCheckoutError(httpError: HttpErrorResponse): void {
    if (httpError.status === 422) {
      // unprocessable entity/validation error
      // 1. reset previous alert to type warning
      this.configurationFacade.getAlert().pipe(
        take(1),
      ).subscribe((alert) => {
        if (alert && alert.type === 'error' && alert.message) {
          // change the alert type for the user to 'warning'
          this.configurationFacade.setAlert({
            ...alert,
            type: 'warning',
          });
        }
      });
      // 2. reset terms and conditions consent
      // NOTE: Currently not possible to distinguish terms and conditions change from other validation errors
      // without checking a localization-specific attribute but... at present this is the only type of validation
      // error that the BE can throw on checkout, so we can assume it's that
      this.handleTermsAndConditionsChanged();
    }
  }

  private handleTermsAndConditionsChanged(): void {
    // reset terms access time as the user is now aware of term changes (and uncheck prior consent)
    this.termsAccessTime = new Date();
    this.approveOrderForm.controls.termsAndConditionsConsent.setValue(false);
    // that's it: the shared error handling will display the appropriate message from the BE
  }

  isVoucherBtnDisabled(): boolean {
    return this.voucherInProgress || this.approveOrderForm.value.promotionCode === '';
  }

  resetVoucherField(): void {
    this.voucherListLoaded = false;
    this.voucherHasError = false;
  }

  isExcludeTaxActive(): boolean {
    return environment.features.excludeTax.includes(this.getCurrentStore());
  }

  getCurrentStore(): string {
    return AppUtils.getCurrentStore().storeId;
  }

  private resolveSelectedShipmentMethod(): void {
    const shipments = this.currentCart.included?.filter(
      include => include.type === EGlueResource.SHIPMENTS
    );
    if (shipments?.length) {
      this.selectedShipmentMethod = shipments[0].attributes.selectedShipmentMethod;
    }
  }

  private _updateVoucherList(included: IBaseModel[]): void {
    this.voucherListLoaded = true;
    this.voucherHasError = false;
    this.voucherList = GlueUtils.filterResourcesByType(included, EGlueResource.VOUCHERS);
    this.showAdditionalInfoForVoucher = this.voucherList && this.voucherList.length > 0;
  }
}
