import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, skipWhile, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EFeatureToggles, ESparePartsOrderHistoryTabs } from '../../../configurations/common';
import { OrdersFacade } from '../../../facades/orders.facade';
import { IHybrisOrder, IOrder } from '../../../models/order.models';
import { TranslateService } from '@ngx-translate/core';
import { IPaginationCurrent } from '../../../models/settings.model';
import { IOrdersWithSapDetails } from '../../../reducers/orders.reducer';
import { ConfigurationFacade } from '../../../facades/configuration.facade';

@Component({
  selector: 'app-spare-part-purchase-activity',
  templateUrl: './spare-part-purchase-activity.component.html',
  styleUrls: ['./spare-part-purchase-activity.component.scss'],
})
export class SparePartPurchaseActivityComponent implements OnInit {
  ESparePartsOrderHistoryTabs = ESparePartsOrderHistoryTabs;
  currentTab: string = ESparePartsOrderHistoryTabs.ORDERS;
  tabs: string[] = [
    ESparePartsOrderHistoryTabs.ORDERS,
  ];

  allLoadedOrders: any[] = [];
  orders: any[] = [];
  ordersLoading: boolean = true;
  ordersCurrentPage: number = 1;
  ordersNumPages: number = 1;
  ordersLimitPerPage: number = 1;

  hybrisOrders: IHybrisOrder[];
  hybrisOrdersLoading: boolean = true;

  private unsubscribe$: Subject<void> = new Subject<void>();

  @Output() sparePartOrdersLoadedEmit = new EventEmitter<boolean>();

  //TEMPORARY SOLUTION for order filtering (permanent solution will be done on BE (dept task))
  //because GET ORDERS endpoint is returning all orders (also contracts/parts and not just orders for current stores)
  sparePartOrderStates: string[] = [
    'created',
    'in process',
    'shipped',
    'partially shipped',
    'canceled',
    'issue',
    'see details',
    'sap call successful',
    'n/a',
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ordersFacade: OrdersFacade,
    private translate: TranslateService,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.selectCurrentTab();
    this.goToOrdersPage(1);
    this.retrieveHybrisOrders();
    this.getArchivedFeature();
  }

  goToOrdersPage(pageNum: number): void {
    this.ordersLoading = true;
    this.ordersCurrentPage = pageNum;
    combineLatest([
      this.ordersFacade.selectOrdersWithSapDetailsAtPage(pageNum),
      this.ordersFacade.selectOrdersWithSapDetailsPagination(),
      this.ordersFacade.selectLoadedOrdersWithSapDetails(),
    ]).pipe(
      skipWhile(([, pagination]) => !pagination.currentPage), // first page still loading in the background
      takeUntil(this.unsubscribe$),
    ).subscribe(([ordersWithSapDetails, pagination, allOrders]) => {
      if (ordersWithSapDetails.orders.length) {
        this.updateOrders(ordersWithSapDetails, pagination, allOrders);
      } else if (this.ordersCurrentPage === 1) {
        // the user does not have access to any orders
        this.updateOrders(ordersWithSapDetails, pagination, allOrders);
      } else {
        const limit: number = pagination.limitPerPage;
        const offset: number = limit ? (pageNum - 1) * limit : undefined;
        this.ordersFacade.loadOrdersWithSapDetails(offset, limit);
      }
    });
  }

  retrieveHybrisOrders(): void {
    this.hybrisOrdersLoading = true;
    this.ordersFacade.selectHybrisOrders().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((orders: IHybrisOrder[]) => {
      if (orders) {
        this.hybrisOrders = orders;
        this.hybrisOrdersLoading = false;
        this.checkIfAllOrdersLoaded();
      }
    });
  }

  private updateOrders(
    ordersWithSapDetails: IOrdersWithSapDetails,
    pagination: IPaginationCurrent,
    allOrders: IOrdersWithSapDetails,
  ): void {
    this.orders = this.filterByDate(
      this.mapOrders(ordersWithSapDetails.orders, ordersWithSapDetails.sapOrdersDetails),
    );
    this.allLoadedOrders = this.filterByDate(
      this.mapOrders(allOrders.orders, allOrders.sapOrdersDetails),
    );
    this.ordersNumPages = pagination.lastPage;
    this.ordersLimitPerPage = pagination.limitPerPage;

    //TEMPORARY SOLUTION FOR FILTERING
    this.orders = this.orders.filter(order => {
      return this.sparePartOrderStates.includes(order.attributes.stateDisplayName.toLocaleLowerCase());
    });
    this.allLoadedOrders = this.allLoadedOrders.filter(order => {
      return this.sparePartOrderStates.includes(order.attributes.stateDisplayName.toLocaleLowerCase());
    });

    this.ordersLoading = false;
    this.checkIfAllOrdersLoaded();
  }

  private mapOrders(orders: IOrder[], sapOrderDetailsList: IOrder[]): any[] {
    return orders.map((order: IOrder) => {
      let sapOrderDetails: IOrder = sapOrderDetailsList.find(orderDetails => orderDetails.id === order.id);

      return {
        ...order,
        attributes: {
          ...order?.attributes,
          ...sapOrderDetails?.attributes,
        },
        mappedStateDisplayName: this.translate.instant(
          `spare-part-list.order-status-${this.getKey(order.attributes?.stateDisplayName)}`,
        ),
      };
    });
  }

  private filterByDate(orderArray: any[]): any[] {
    const yearAgo: Date = new Date();
    yearAgo.setFullYear(yearAgo.getFullYear() - 1);
    yearAgo.setUTCHours(0, 0, 0, 0);

    const filteredOrders = orderArray.filter(
      order =>
        new Date(order.attributes.createdAt).getTime() >= yearAgo.getTime(),
    );
    return filteredOrders ?? [];
  }

  /**
   * Replace all spaces in string status with '-'.
   *
   * @param {string} status
   * @returns {string}
   * @private
   */
  getKey(status: string): string {
    if (!status) {
      return '';
    }
    return status.replace(/ /g, '-')
  }

  selectCurrentTab(): void {
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.currentTab = params.subtab
          ? params.subtab
          : ESparePartsOrderHistoryTabs.ORDERS;
      });
  }

  changeTab(tab: string): void {
    this.currentTab = tab;
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: {subtab: tab},
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  checkIfAllOrdersLoaded(): void {
    if (!this.ordersLoading && !this.hybrisOrdersLoading) {
      this.sparePartOrdersLoadedEmit.emit(true);
    }
  }

  getArchivedFeature(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.PURCHASE_ACTIVITY_ARCHIVE).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(archiveEnabled => {
      if (archiveEnabled) {
        if (!this.tabs.includes(ESparePartsOrderHistoryTabs.ARCHIVE)) {
          this.tabs.push(ESparePartsOrderHistoryTabs.ARCHIVE);
        }
      }
    });
  }
}
