import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';
import { combineLatest, Subject } from 'rxjs';
import { debounceTime, map, take, takeUntil } from 'rxjs/operators';

import { MarketingFacade } from '../../../facades/marketing.facade';
import { CpqFacade } from '../../../facades/cpq.facade';
import { I18nService } from '../../../services';
import {
  addressValidation,
  contractDateValidation,
  contractDurationLessThanEndOfSupportDate,
} from '../../../configurations/validations';
import { CpqUtils } from '../../../utils/cpq.utils';
import { IContractParams } from '../../../models/catalog.models';
import { AppUtils } from '../../../utils/app.utils';
import { IconType } from '../../../models/settings.model';
import { CustomerFacade } from '../../../facades/customer.facade';
import { IAddress, ITotals} from '../../../models/common.models';
import { AddressUtils } from '../../../utils/address.utils';
import { ArrayUtils } from '../../../utils/array.utils';
import { EAddressType } from '../../../configurations/cpq';
import { EInstalledBaseTabs } from '../../../configurations/common';

@Component({
  selector: 'app-quote-summary',
  templateUrl: './quote-summary.component.html',
  styleUrls: ['./quote-summary.component.scss'],
})
export class QuoteSummaryComponent implements OnInit, OnDestroy {
  cpqForm: UntypedFormGroup;
  showLoaderOverlay = false;
  loadingPageData = true;
  removeQuoteModalFlag = false;
  isSoldToAddressValid: boolean;
  generateQuoteDocumentModalFlag = false;
  reportWrongSoldToAddressModalFlag = false;
  payerAddresses: IAddress[] = [];
  billToAddresses: IAddress[] = [];
  cartId: string;
  prices: any;
  cartItemsWithDetails: any;
  tabsOpened = [] as Array<string>;
  serviceParams: IContractParams = {
    'fl-number': '',
    'rel-product-sysivk': '',
    selectedService: '',
  };
  currency: string;
  showRemoveConfigurationModal = false;
  quoteItemToRemove: {sku: string, configId: string};
  deleteQuoteItemInProgress = false;
  iconType = IconType;
  cart: any;
  customerBusinessUnitName: string;
  ecommerceTeamEmailAddress: string;
  promoCodeForm: UntypedFormGroup;
  activePromoCode: {amount: number, displayName: string, code: string};
  promoCodeSuccess = false;
  promoCodeInvalid = false;
  promoCodesCombined = false;
  soldToAddress: IAddress[] = [];
  addressType = EAddressType.SoldTo;
  isCartOperationInProgress = false;

  @Output() showAlmostDone = new EventEmitter<any>();

  private unsubscribe$ = new Subject<void>();
  private soldToId: string;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private marketingFacade: MarketingFacade,
    private cpqFacade: CpqFacade,
    private activatedRoute: ActivatedRoute,
    private i18nService: I18nService,
    private customerFacade: CustomerFacade,
  ) {
  }

  ngOnInit(): void {
    this.selectCartId();
    this.activatedRoute.queryParams.pipe(debounceTime(0), take(1)).subscribe(params => {
      this.serviceParams['fl-number'] = params['fl-number'] ?? '';
      this.serviceParams['rel-product-sysivk'] = params['rel-product-sysivk'] ?? '';
      this.serviceParams.selectedService = params.selectedService ?? '';
    });
    this.getUserData();
    this.getStoreEmailAddress();
    this.selectCartOperationInProgress()
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isFormValid(form: UntypedFormGroup): boolean {
    return form?.status === 'VALID';
  }

  toggleTab(id: string): void {
    const exists = this.tabsOpened.findIndex((tab) => tab === id);
    if (exists !== -1) {
      this.tabsOpened.splice(exists, 1);
    } else {
      this.tabsOpened.push(id);
    }
  }

  getMultipleFunctionalLocations(cartItemsWithDetails: any): string[] {
    const functionalLocationsInArray = [];

    if (cartItemsWithDetails) {
      for (const item of cartItemsWithDetails) {
        const functionalLocation = item.attributes?.systemDetails?.siemensEquipmentId;
        if (functionalLocation) {
          functionalLocationsInArray.push(functionalLocation);
        }
      }
    }

    return functionalLocationsInArray;
  }

  generateQuoteDocument(): void {
    this.generateQuoteDocumentModalFlag = true;
  }

  backToQuoteSummary(): void {
    this.generateQuoteDocumentModalFlag = false;
    this.removeQuoteModalFlag = false;
  }

  showReportWrongSoldToAddressModal(): void {
    this.reportWrongSoldToAddressModalFlag = true;
  }

  showRemoveQuoteModal(): void {
    this.removeQuoteModalFlag = true;
  }

  navigateToAlmostDone(): void {
    this.router.navigate(
      ['almost-done'],
      {relativeTo: this.activatedRoute, queryParamsHandling: 'merge'}
    ).then(() => this.showAlmostDone.emit(true));
  }

  configureAnotherFunctionalLocation(): void {
    const extras = this.soldToId ? {queryParams: {soldTo: this.soldToId, tab: EInstalledBaseTabs.CONTRACTS}} :
      {queryParams: {selectedService: this.serviceParams.selectedService, tab: EInstalledBaseTabs.CONTRACTS}};
    this.router.navigate(
      ['/my-installed-base'],
      extras,
    ).then();
  }

  applyPromoCode(code: string): void {
    this.showLoaderOverlay = true;
    this.marketingFacade.postVoucher(this.cartId, code).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: response => {
        if (response) {
          this.recalculateAfterPromoCodeOperation(true);
        }
      },
      error: () => {
        this.promoCodeInvalid = true;
        this.showLoaderOverlay = false;
      }
    });
  }

  removePromoCode(voucherId: string): void {
    this.showLoaderOverlay = true;
    this.marketingFacade.removeVoucher(this.cartId, voucherId).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      this.recalculateAfterPromoCodeOperation(false);
    });
  }

  private recalculateAfterPromoCodeOperation(isPromoCodeAdding: boolean): void {
    this.cpqFacade.postCpqRecalculate(this.cartId).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.marketingFacade.getCartById(this.cartId).pipe(
          take(1)
        ).subscribe({
          next: cartResponse => {
            const {totals} = cartResponse.data.attributes;
            this.prices = totals;
            if (isPromoCodeAdding) {
              this.setPromoCode(totals, cartResponse.data.attributes.discounts[0].code);
              this.promoCodeSuccess = true;
            } else {
              this.activePromoCode = null;
              this.promoCodeSuccess = false;
            }
            this.showLoaderOverlay = false;
          },
          error: () => {
            this.showLoaderOverlay = false;
          }
        });
      },
      error: () => {
        this.showLoaderOverlay = false;
      }
    });
  }

  private setPromoCode(totals: ITotals, code: string): void {
    this.activePromoCode = {
      code,
      displayName: Math.round((totals.cpqPromocodeReductionTotal * 100) /
        (totals.cpqListPriceTotal - totals.cpqContractReductionTotal)).toString(),
      amount: totals.cpqPromocodeReductionTotal,
    };
    this.promoCodeInvalid = false;
    this.promoCodeForm.value.promoCode = '';
  }

  itemDeleted($event: any): void {
    this.quoteItemToRemove = $event;

    if (this.cartItemsWithDetails.length > 1) {
      this.showRemoveConfigurationModal = true;
    } else {
      this.removeQuoteModalFlag = true;
    }
  }

  removeQuote(): void {
    const redirectUrl = `/${this.i18nService.getCurrentLocale()}`;
    this.removeQuoteModalFlag = false;
    this.marketingFacade.quoteSummaryPageDeleteQuote(this.cartId, redirectUrl);
  }

  removeQuoteItem(): void {
    this.showRemoveConfigurationModal = false;
    this.deleteQuoteItemInProgress = true;
    this.cpqFacade.deleteCpqQuoteItem(this.cartId, this.quoteItemToRemove.sku, this.quoteItemToRemove.configId).pipe(
      take(1)
    ).subscribe({
      next: () => {
        this.marketingFacade.loadCurrentCart();
        this.cpqFacade.postCpqRecalculate(this.cartId).pipe(take(1)).subscribe(() => {
          this.deleteQuoteItemInProgress = false;
        });
      },
      error: () => {
        this.deleteQuoteItemInProgress = false;
      }
    });
  }

  private selectCartId(): void {
    this.marketingFacade.selectHasCartContract().pipe(takeUntil(this.unsubscribe$)).subscribe(cartHasContract => {
      if (cartHasContract) {
        this.marketingFacade.selectCartId().pipe(takeUntil(this.unsubscribe$)).subscribe(cartId => {
          if (cartId) {
            this.cartId = cartId;
            this.marketingFacade.refreshCart(cartId);
            this.getCpqQuote(this.cartId);
          }
        });
      }
    });
  }

  private getCpqQuote(cartId: string): void {
    this.cpqFacade.getCpqQuote(cartId).pipe(take(1)).subscribe(cpqQuote => {
      this.soldToId = cpqQuote.data.attributes.cpqResponse.soldTo_id;
      this.cpqFacade.getCpqQuoteItems(cartId).pipe(take(1)).subscribe(() => {
        this.selectCart();
      });
    });
  }

  private selectCart(): void {
    combineLatest([
      this.marketingFacade.selectCart(),
      this.marketingFacade.selectCartItemsWithDetails(),
    ]).pipe(takeUntil(this.unsubscribe$), map(([cart, cartItemsWithDetails]) => (
      {cart, cartItemsWithDetails}),
    )).pipe(debounceTime(0)).subscribe(data => {
      this.cart = data.cart;
      const {totals, currency, discounts} = data.cart.attributes;
      this.prices = totals;
      this.currency = currency;
      this.cartItemsWithDetails = data.cartItemsWithDetails;
      const unFilteredBillToAddresses = [...new Set(this.cartItemsWithDetails.map(item => {
        return AddressUtils.changeAddressName(item.attributes.billToAddress);
      }))] as IAddress[];
      this.billToAddresses = ArrayUtils.uniqueObjects(unFilteredBillToAddresses, 'sapId');
      const unFilteredPayerAddresses = [...new Set(this.cartItemsWithDetails.map(item => {
        return AddressUtils.changeAddressName(item.attributes.payerAddress);
      }))] as IAddress[];
      this.payerAddresses = ArrayUtils.uniqueObjects(unFilteredPayerAddresses, 'sapId');
      this.deleteQuoteItemInProgress = false;
      this.initializeForms();

      this.promoCodeForm = this.formBuilder.group({
        promoCode: '',
      });
      if (discounts.length > 0) {
        this.setPromoCode(this.prices, discounts[0].code);
      }
    });
  }

  private initializeForms(): void {
    const itemsFormControls = this.cartItemsWithDetails.reduce((acc, next) => {
      const systemDetails = next.attributes.systemDetails;
      const control = {
        ['contractStartDate' + next.id]: [
          systemDetails.contractStartDate ?
            formatDate(systemDetails.contractStartDate, 'dd-MMM-yyyy', 'en') : '',
          [
            Validators.required,
            contractDateValidation(systemDetails.contractStartDate),
          ],
        ],
        ['contractTerm' + next.id]: [
          systemDetails.contractDurationYears ?? '',
          [
            Validators.required,
            contractDurationLessThanEndOfSupportDate(systemDetails.contractStartDate, systemDetails.endOfSupport),
          ],
        ],
        ['shipToAddress' + next.id]:
          [CpqUtils.cpqAddressToString(next.attributes.customerShipToAddress), Validators.required],
      };
      return Object.assign(acc, control);
    }, {});
    const customerSoldToAddress = this.cartItemsWithDetails[0].attributes.customerSoldToAddress;
    const controls = Object.assign(itemsFormControls, {
      ['soldToAddress']: [CpqUtils.cpqAddressToString(customerSoldToAddress),
        [
          Validators.required,
          addressValidation(customerSoldToAddress),
        ],
      ],
    });
    this.cpqForm = this.formBuilder.group(controls);
    this.isSoldToAddressValid = this.cpqForm.controls.soldToAddress.status === 'VALID';
    this.soldToAddress = [customerSoldToAddress] as IAddress[];
    this.loadingPageData = false;
  }

  private getStoreEmailAddress(): void {
    this.ecommerceTeamEmailAddress = AppUtils.getCurrentStore().ecommerceTeamEmailAddress;
  }

  private getUserData(): void {
    this.customerFacade.selectCompanyBusinessUnit()
      .pipe(take(1))
      .subscribe(businessUnit => {
        this.customerBusinessUnitName = businessUnit?.name;
      });
  }

  private selectCartOperationInProgress(): void {
    this.marketingFacade.selectCartOperationInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(inProgress => {
      this.isCartOperationInProgress = inProgress;
    });
  }
}
