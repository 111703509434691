import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ObjectUtils } from '../../utils/object.utils';
import { IEquipmentFilter } from '../../models/equipment-filter.model';
import { ISystemDetails } from '../../models/common.models';

@Component({
  selector: 'app-marked-filters',
  templateUrl: './marked-filters.component.html',
  styleUrls: ['./marked-filters.component.scss'],
})
export class MarkedFiltersComponent implements OnInit, OnChanges {
  activeFilters = [];
  showBPRewoke: boolean = false;

  @Input() filters: Array<any>;
  @Input() equipmentFilter: IEquipmentFilter;
  @Input() isBusinessPartner: boolean;
  @Input() systemDetails: ISystemDetails;
  @Output() deselectFilterOption = new EventEmitter<any>();

  constructor(
    private router: Router,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filters?.currentValue || changes.equipmentFilter?.currentValue) {
      this.initFilters();
    }
  }

  ngOnInit(): void {
    this.initFilters();
  }

  initFilters(): void {
    const mergedActiveFilters = [];

    if (this.isBusinessPartner) {
      const urlTree = this.router.parseUrl(this.router.url);
      this.showBPRewoke = !!urlTree.queryParams['rel-product-sysivk'];
    }

    if (this.equipmentFilter.isApplicable && this.equipmentFilter.activeValue?.length > 0) {
      mergedActiveFilters.push({
        name: this.equipmentFilter.name,
        entities: this.mapRelProductSysivkToValueArray()
      });
    }

    this.filters.forEach(filter => {
      if (filter.activeValue) {
        mergedActiveFilters.push({
          name: filter.name,
          entities: Array.isArray(filter.activeValue) ? filter.activeValue : [filter.activeValue]
        });
      }
    });

    this.activeFilters = mergedActiveFilters;
  }

  mapRelProductSysivkToValueArray(): Array<string> {
    return this.equipmentFilter.activeValue?.map(
      activeValue => this.equipmentFilter?.values?.find(value => value['rel-product-sysivk'] === activeValue).value
    );
  }

  mapValueToRelProductSysivk(option: string): string {
    return this.equipmentFilter?.values?.find(value => value.value === option)['rel-product-sysivk'];
  }

  filterResults(filter, option, routeUrl: string): void {
    const pageUrl = decodeURIComponent(routeUrl.split('?')[0]);
    const urlTree = this.router.parseUrl(routeUrl);

    if (this.isBusinessPartner) {
      delete urlTree.queryParams['rel-product-sysivk'];
      delete urlTree.queryParams['fl-number'];

      urlTree.queryParams = ObjectUtils.shallowFilterObject(urlTree.queryParams, filter);
    }

    if (Array.isArray(urlTree.queryParams[filter])) {
      if (filter === 'rel-product-sysivk') {
        urlTree.queryParams[filter] = urlTree.queryParams[filter].filter(queryParam => queryParam !== this.mapValueToRelProductSysivk(option));
      } else {
        urlTree.queryParams[filter] = urlTree.queryParams[filter].filter(queryParam => queryParam !== option);
      }
    } else {
      urlTree.queryParams = ObjectUtils.shallowFilterObject(urlTree.queryParams, filter);
    }

    const url = this.router.createUrlTree(
      [pageUrl],
      {queryParams: {...urlTree.queryParams, page: '1'}},
    );

    this.router.navigateByUrl(url.toString()).then();
  }
}
