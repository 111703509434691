import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SearchFieldPipe } from '../pipes/search-field.pipe';
import { IEquipmentComponentFilter } from '../../models/equipment-component-filter.model';

@Component({
  selector: 'app-equipment-component-filter',
  templateUrl: './equipment-component-filter.component.html',
  styleUrls: ['./equipment-component-filter.component.scss'],
  providers: [SearchFieldPipe],
})
export class EquipmentComponentFilterComponent {
  isFilterOpened: boolean = false;
  filterSearchValue: any;

  @Input() filter: IEquipmentComponentFilter;

  constructor(
    private router: Router,
    private searchFieldPipe: SearchFieldPipe,
  ) {
  }

  ngOnInit(): void {
   if (!this.checkEquipmentsClickedParam()) {
      this.selectAllOptions(true);
   }
  }

  private checkEquipmentsClickedParam(): boolean {
    const urlTree = this.router.parseUrl(this.router.url);
    return urlTree.queryParams['equipmentsClicked'];
  }

  toggleFilterOptions(): void {
    this.isFilterOpened = true;

    const filterContainer = document.querySelector('.equipment-component-filter');
    filterContainer.classList.toggle('is-open');
  }

  /**
   * Selects all filter options and updates the URL parameters accordingly.
   * If the `shouldAddEquipmentClickedParam` argument is provided and is true,
   * the method also adds the 'equipmentsClicked' parameter to the URL with a value of 'true'.
   * which is later used for logic of selecting of all options on firt page load
   *
   * @param {boolean} [shouldAddEquipmentClickedParam] - Optional parameter indicating whether to add the 'equipmentsClicked' parameter to the URL.
   * @returns {void}
   */
  selectAllOptions(shouldAddEquipmentClickedParam?: boolean): void {
    const pageUrl = decodeURIComponent(this.router.url.split('?')[0]);
    const urlTree = this.router.parseUrl(this.router.url);

    let filteredOptions = this.searchFieldPipe.transform(this.filter.values, this.filterSearchValue);
    let values = [];
    filteredOptions.forEach(option => {
      values.push(option['rel-sparepart-ivk']);
      this.filter.activeValue.push(option['rel-sparepart-ivk']);
    });

    let mergedQueryParams;
    if(shouldAddEquipmentClickedParam) {
      mergedQueryParams = {
        ...urlTree.queryParams,
        'rel-sparepart-ivk': values.join(','),equipmentsClicked: 'true',
        page: 1,
      };
    }
    else {
      mergedQueryParams = {
        ...urlTree.queryParams,
        'rel-sparepart-ivk': values.join(','),
        page: 1,
      };
    }
    const url = this.router.createUrlTree(
      [pageUrl],
      {queryParams: mergedQueryParams},
    ).toString();
    this.router.navigateByUrl(url).then();
  }

  selectOptionsOrClear(): void {
    const pageUrl = decodeURIComponent(this.router.url.split('?')[0]);
    const urlTree = this.router.parseUrl(this.router.url);

    if (this.areOptionsSelected()) {
      this.filter.activeValue = [];
      if (urlTree.queryParams['rel-sparepart-ivk']) {
        delete urlTree.queryParams['rel-sparepart-ivk'];
      }

      const url = this.router.createUrlTree([pageUrl], {queryParams: urlTree.queryParams}).toString();
      this.router.navigateByUrl(url).then();
    } else {
      this.selectAllOptions();
    }
  }

  selectOption(option: any): void {
    const pageUrl = decodeURIComponent(this.router.url.split('?')[0]);
    const queryParams = this.createQueryParams('rel-sparepart-ivk', option['rel-sparepart-ivk']);
    // Update the `activeValue` array based on the URL query parameters
    if (typeof queryParams['rel-sparepart-ivk'] === 'string') {
      this.filter.activeValue = queryParams['rel-sparepart-ivk'].split(',');
    } else if (Array.isArray(queryParams['rel-sparepart-ivk'])) {
      this.filter.activeValue = queryParams['rel-sparepart-ivk'];
    } else {
      this.filter.activeValue = [queryParams['rel-sparepart-ivk']];
    }

    const url = this.router.createUrlTree(
      [pageUrl],
      {queryParams},
    ).toString();

    this.router.navigateByUrl(url).then();
  }

  areOptionsSelected(): boolean {
    const filteredOptions = this.searchFieldPipe.transform(this.filter.values, this.filterSearchValue);
    let areOptionsSelected = true;
    filteredOptions.forEach(option => {
      if (!this.filter.activeValue?.includes(option['rel-sparepart-ivk'])) {
        areOptionsSelected = false;
      }
    });
    return areOptionsSelected;
  }

  isOptionSelected(option: any): boolean {
    // Parse the URL
    let urlTree = this.router.parseUrl(this.router.url);
    // Check if the option's `rel-sparepart-ivk` value is in the URL query parameters
    if (typeof urlTree.queryParams['rel-sparepart-ivk'] === 'string') {
      let values = urlTree.queryParams['rel-sparepart-ivk'].split(',');
      return values.includes(option['rel-sparepart-ivk']);
    } else {
      return urlTree.queryParams['rel-sparepart-ivk'] === option['rel-sparepart-ivk'];
    }
  }

  createQueryParams(name: string, value: string | number): any {
    let urlTree = this.router.parseUrl(this.router.url);
    if (!urlTree.queryParams.hasOwnProperty(name)) {
      urlTree.queryParams[name] = value.toString();
      urlTree.queryParams.page = 1;
      return urlTree.queryParams;
    }

    let values = urlTree.queryParams[name].split(',');
    if (values.includes(value.toString())) {
      values = values.filter(param => param !== value.toString());
    } else {
      values.push(value.toString());
    }
    if (values.length === 0) {
      delete urlTree.queryParams[name];
    } else {
      urlTree.queryParams[name] = values.join(',');
    }
    urlTree.queryParams.page = 1;
    this.filter.activeValue = values;
    return urlTree.queryParams;
  }
}
