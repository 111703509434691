<section class="checkboxes-container">
  @for (termsAndConsentsCheckbox of termsAndConsentsCheckboxes; track termsAndConsentsCheckbox.name) {
    <div class="checkbox-row">
      <input type="checkbox"
             [id]="'consent-checkbox-' + termsAndConsentsCheckbox.name"
             [name]="'consent-checkbox-' + termsAndConsentsCheckbox.name"
             [attr.data-test]="'checkbox-termsAndConsentsCheckbox-' + termsAndConsentsCheckbox.label"
             class="checkbox"
             [(ngModel)]="termsAndConsentsCheckbox.isChecked">
      <label for="consent-checkbox-{{ termsAndConsentsCheckbox.name }}"
             [attr.data-test]="'label-termsAndConsentsCheckbox' + termsAndConsentsCheckbox.label">
        {{ 'order-review.consent-checkbox-' + termsAndConsentsCheckbox.label | translate }}
      </label>
    </div>
  }
</section>
