// What is a Glue resource? Essentially it's the basic building block of the Spryker Glue API
// - can have its own endpoint/set of endpoints, e.g. GET /carts, POST /carts -> resource: carts
// --- also as a sub-resource/child of another resource, e.g. POST /carts/:id/items -> resource: items
// - can be used as the 'type' to describe the attribute structure in a Glue request
// --- e.g. POST /carts {data: {type: carts, attributes: ...}}
// - can be used as the 'type' to describe the attribute structure in a Glue response
// --- e.g. GET /stores -> {data: [{type: stores, attributes: ...}]}
// - can be added in the 'include' parameter of another resource, e.g. GET /carts?include=items -> resource: items
// --- also means it can appear in the 'relationships' section of a Glue response
export enum EGlueResource {
  // products-related
  ABSTRACT_PRODUCTS               = 'abstract-products',
  CONCRETE_PRODUCTS               = 'concrete-products',
  ABSTRACT_PRODUCT_IMAGE_SETS     = 'abstract-product-image-sets',
  CONCRETE_PRODUCT_IMAGE_SETS     = 'concrete-product-image-sets',
  ABSTRACT_PRODUCT_PRICES         = 'abstract-product-prices',
  CONCRETE_PRODUCT_PRICES         = 'concrete-product-prices',
  ABSTRACT_PRODUCT_AVAILABILITIES = 'abstract-product-availabilities',
  CONCRETE_PRODUCT_AVAILABILITIES = 'concrete-product-availabilities',
  PRODUCT_LABELS                  = 'product-labels',

  // customer management-related
  CUSTOMERS                       = 'customers',
  CUSTOMER_PREFERENCES            = 'customer-preferences',
  COMPANIES                       = 'companies',
  COMPANY_BUSINESS_UNITS          = 'company-business-units',
  COMPANY_BUSINESS_UNIT_ADDRESSES = 'company-business-unit-addresses',
  COMPANY_ROLES                   = 'company-roles',
  COMPANY_USERS                   = 'company-users',
  COMPANY_APPROVERS               = 'company-approvers',
  ADDRESSES                       = 'addresses',
  ADDRESSES_REPORT_WRONG          = 'addresses-report-wrong',
  CPQ_ADDRESS_REPORT_WRONG        = 'cpq-address-report-wrong',

  // catalog and common shopping flow features-related
  CATALOG_SEARCH                  = 'catalog-search',
  CATALOG_SEARCH_SUGGESTIONS      = 'catalog-search-suggestions',
  CATEGORY_TREES                  = 'category-trees',
  CATEGORY_NODES                  = 'category-nodes',
  CARTS                           = 'carts',
  ITEM_LISTS                      = 'item-lists',
  CART_ITEMS                      = 'items',
  CART_RULES                      = 'cart-rules',
  VOUCHERS                        = 'vouchers',
  CARTS_EXPORT_PDF                = 'export-to-pdf',

  // classic flow with or without approval and with checkout-related
  CARTS_APPROVAL                  = 'carts-approval',
  QUOTE_DECLINE                   = 'quote-decline',

  CHECKOUT                        = 'checkout',
  CHECKOUT_UPDATE                 = 'checkout-update',
  CHECKOUT_DATA                   = 'checkout-data',
  SHIPMENTS                       = 'shipments',
  SHIPMENT_METHODS                = 'shipment-methods',
  ORDERS                          = 'orders',
  ORDERS_CANCEL                   = 'orders-cancel',
  ORDERS_EXPORT_EXCEL             = 'orders-export-to-excel',

  // RfQ flow-related
  RFQ_PRODUCTS                    = 'rfq-products',
  QUOTE_REQUESTS                  = 'quote-requests',
  QUOTE_REQUESTS_CANCEL           = 'quote-request-cancel',
  QUOTE_REQUESTS_EXPORT_PDF       = 'generate-pdf',

  // CPQ flow-related
  CPQ_INITIALIZE                  = 'cpq-initialize',
  CPQ_GUIDED_INITIALIZE           = 'cpq-guided-initialize',
  CPQ_SET_STEP                    = 'cpq-set-step',
  CPQ_ACCEPT                      = 'cpq-accept',
  CPQ_COMMIT                      = 'cpq-commit',
  CPQ_UNCOMMIT                    = 'cpq-uncommit',
  CPQ_RECONFIGURE                 = 'cpq-reconfigure',
  CPQ_RECALCULATE                 = 'cpq-recalculate',
  CPQ_QUOTE                       = 'cpq-quote',
  CPQ_QUOTE_ITEMS                 = 'cpq-quote-items',
  CPQ_QUOTE_ITEMS_COMMIT          = 'cpq-cart-items-commit',
  CPQ_QUOTE_DOWNLOAD              = 'cpq-quote-download',
  CPQ_CONFIGURATION_DOWNLOAD      = 'cpq-configuration-download',
  CPQ_CONTRACT_DETAILS_DEFAULTS   = 'cpq-contract-details-defaults',
  CPQ_SUMMARY                     = 'cpq-summary',


  // TpF/OPAL/SAP P40/other systems integration
  SHS_EQUIPMENT_MATERIALS         = 'shs-equipment-materials',
  SHS_EQUIPMENTS                  = 'shs-equipments',
  SHS_EQUIPMENTS_INSTALLED_BASE   = 'shs-equipments-installed-base',
  OPAL_SYSTEMS_SEARCH             = 'opal-systems-search',
  CUSTOMER_ORDERS_HISTORY         = 'customer-orders-history',
  SAP_MESSAGES                    = 'sap-messages',
  SAP_ITEM_AVAILABILITIES         = 'sap-item-availabilities',
  SOLD_TO_ADDRESSES               = 'sold-to-addresses',
  SHIP_TO_ADDRESSES               = 'ship-to-addresses',
  BILL_TO_ADDRESSES               = 'bill-to-addresses',
  PAYER_ADDRESSES                 = 'payer-addresses',
  CUSTOMER_SOLD_TO_ID             = 'customer-sold-to-id',

  // misc.
  STORES                          = 'stores',
  NAVIGATIONS                     = 'navigations',
  CMS_PAGES                       = 'cms-pages',
  CONTENT_BANNERS                 = 'content-banners',
  CONTENT_BANNERS_BY_KEY          = 'content-banners-by-key',
  CONTENT_PRODUCT_ABSTRACT        = 'content-product-abstract',
  CONTACT_US                      = 'contact-us',
  CONTACT_US_GENERAL              = 'contact-us-general',
}

// HTTP query params usable for all/most Glue endpoints
export enum EQueryParams {
  // common
  INCLUDE = 'include',

  // pagination-related for specific API endpoints (GET /orders, GET /catalog-search, ...)
  OFFSET  = 'page[offset]',
  LIMIT   = 'page[limit]',

  // sort-related for specific API endpoints (GET /orders, GET /catalog-search, ...)
  SORT    = 'sort',
}

export enum ETranslationTypes {
  LABEL,
  DESCRIPTION,
  TITLE,
  SUBTITLE
}

export enum ERouteDataTypes {
  ROUTE_DATA_BREADCRUMB   = 'breadcrumb',
  DYNAMIC_DATA_BREADCRUMB = 'dynamicBreadCrumb',
  SHOW_DATA_BREADCRUMB    = 'showBreadcrumb',
  IS_BREADCRUMB_DISABLED  = 'breadcrumbDisabled'
}

export enum EUserRoles {
  Guest                   = 'GUEST',
  Viewer                  = 'RIGHT_WEBSHOPS_BROWSER_USER', // 'VIEWER',
  SPCViewer               = 'RIGHT_WEBSHOPS_SPAREPARTS_VIEW', // 'SPCVIEWER',
  Buyer                   = 'RIGHT_WEBSHOPS_ORDER_USER', // 'BUYER',
  Approver                = 'RIGHT_WEBSHOPS_APPROVER_USER', // 'APPROVER', // 'RIGHT_WEBSHOPS_APPROVER_USER',
  Admin                   = 'RIGHT_WEBSHOPS_CUSTOMER_ADMIN', // 'ADMIN'
  BusinessPartnerBuyer    = 'RIGHT_WEBSHOPS_BUSINESSPARTNER_BUYER',
  BusinessPartnerApprover = 'RIGHT_WEBSHOPS_BUSINESSPARTNER_APPROVER',
}

export enum EAppBarAlertType {
  NOT_LOGGED_USER,
  RESTRICTED_ACCESS,
  AUTHENTICATED_GUEST,
}

export enum ECompanyBUIncludes {
  COMPANY_BUSINESS_UNITS = 'company-business-units',
  CUSTOMERS              = 'customers',
  COMPANY_ROLES          = 'company-roles',
  CUSTOMER_PREFERENCES   = 'customer-preferences'
}

export enum EStoreTypes {
  AU = 'AU',
  BR = 'BR',
  CA = 'CA',
  GB = 'GB',
  IN = 'IN',
  JP = 'JP',
  US = 'US'
}

export enum ELanguageTypes {
  EN = 'en',
  FR = 'fr',
  JA = 'ja',
  PT = 'pt',
}

export enum EStoreFeatures {
  QUOTE_REQUEST    = 'quoteRequest',
  EXPORT_CART      = 'exportCart',
  CPQ              = 'cpq',
  SPARE_PARTS      = 'spareParts',
  MY_PROFILE       = 'myProfile',
  MULTI_CART       = 'multiCart',
  EXCLUDE_TAX      = 'excludeTax',
  SHOP_BY_CATEGORY = 'shopByCategory'
}

export const CARTS_API_ERROR_CODES_TO_SHOW_ALERT = ['102', '113', '115', '192', '193', '901'];

export enum EFullPageLoaderListOptions {
  NAVIGATION      = 'NAVIGATION',
  HOMEPAGE_BANNER = 'HOMEPAGE_BANNER'
}

export const HOMEPAGE_FULL_PAGE_LOADER_LIST = [
  EFullPageLoaderListOptions.NAVIGATION,
  EFullPageLoaderListOptions.HOMEPAGE_BANNER,
];

export enum EProfileTabs {
  PERSONAL = 'personal-data',
  ACCOUNT  = 'account-settings',
  DELIVERY = 'delivery-addresses'
}

export enum EInstalledBaseTabs {
  EQUIPMENT = 'my-equipment',
  CONTRACTS = 'my-contracts'
}

export enum EMultiCartTabs {
  PARTS    = 'parts',
  SERVICES = 'services'
}

export enum ESparePartsOrderHistoryTabs {
  ORDERS  = 'orders',
  ARCHIVE = 'archive'
}

export enum HospitalOrdersTabs {
  PREVIOUS_ORDERS   = 'approver-previous-orders',
  PENDING_ORDERS    = 'approver-pending-orders',
  PREVIOUS_QUOTES   = 'previous-quotes',
  PREVIOUS_REQUESTS = 'previous-request',
}

export enum EOrderWorkflows {
  WT_ORDER  = 'wt_order',
  CPQ_ORDER = 'cpq_order',
  SAP_ORDER = 'sap_order',
}

export const FEATURE_TOGGLE = 'feature_toggle.';
export const FEATURE_CONFIG = 'config.';

export enum EFeatureToggles {
  // IMPORTANT: Only for boolean-valued feature toggle keys
  //TODO: Introduce naming convention for feature toggles as there is currently no convention defined,
  // so different names with different format are being used.
  SAP_P40                                                    = 'sap_p40',
  CPQ                                                        = 'cpq',
  SPARE_PARTS                                                = 'spare_parts',
  WALK_ME                                                    = 'walk_me',
  CONSUMABLE_ONLY_EQUIPMENT_PURCHASES                        = 'enable_consumable_only_equipment_purchases',
  INSTALL_BASE_ADDRESS_ANONYMIZE                             = 'install_base_address_anonymize',
  PURCHASE_ACTIVITY_ARCHIVE                                  = 'purchase_activity_archive',
  RETURNABLE_DOCUMENT_EMAIL_LINK_FROM_PURCHASE_ACTIVITY_PAGE = 'returnable_document_email_link_from_purchase_activity_page',
  ADD_TO_CART_VIA_CSV                                        = 'add-to-cart-via-csv',
  ADD_TO_CART_VIA_SEARCHBAR                                  = 'add-to-cart-via-searchbar',
  ADOBE_ANALYTICS                                            = 'adobe-analytics',
  MAINTENANCE                                                = 'maintenance',
  PRODUCT_DISCONTINUED_STATUS                                = 'product-discontinued-status',
  PREFERRED_PAYMENT                                          = 'preferred_payment_method',
  PRODUCT_PRICING_GROUP                                      = 'product-pricing-group',
  APPROVER_HYPERLINK                                         = 'approver_hyperlink',
  ATTENTION_TO                                               = 'attention-to',
  MY_INSTALLED_BASE_FLOW                                     = 'enable_my_installed_base_flow',
  OTHER_EQUIPMENT                                            = 'other_equipment',
  HARMONIZED_CHECKOUT_PROCESS                                = 'harmonizedCheckoutProcess',
  APPROVER_BUYER_FLOW                                        = 'approver_buyer_flow',
  PDF_GENERATE_QUOTE                                         = 'pdf_generate_quote',
  PDF_PREVIEW_QUOTE                                          = 'pdf_preview_quote',
}

export enum EFeatureSettings {
  // Intended for non-boolean values related to features
  WALK_ME_SCRIPT                    = 'walk_me_inner_script',
  EQUIPMENT_SELECTION_SESSION_LIMIT = 'equipment-selection-session-limit',
  PRICING_GROUP_FOR_CONSUMABLES     = 'pricingGroupForConsumables',
}

export enum EMessageType {
  INFO    = 'info',
  WARNING = 'warning',
  ERROR   = 'error',
}

// TODO: Consider changing the common prefix/translation key namespace
//  to distinguish settings from toggles (e.g. something like 'feature_setting.')
export const FEATURE_SETTING_WALK_ME_SCRIPT                    = FEATURE_TOGGLE + EFeatureSettings['WALK_ME_SCRIPT'];
export const FEATURE_SETTING_EQUIPMENT_SELECTION_SESSION_LIMIT = FEATURE_TOGGLE + EFeatureSettings['EQUIPMENT_SELECTION_SESSION_LIMIT'];
export const CONFIG_PRICING_GROUP_FOR_CONSUMABLES              = FEATURE_CONFIG + EFeatureSettings['PRICING_GROUP_FOR_CONSUMABLES'];
export const FEATURE_HARMONIZED_CHECKOUT_PROCESS               = FEATURE_TOGGLE + EFeatureToggles['HARMONIZED_CHECKOUT_PROCESS'];
export const REGISTER_URL_KEY                                  = 'app-bar.register-url';

export enum EDeliveryInputNames {
  SELECTED_EQUIPMENT               = 'selectedEquipment',
  SOLD_TO_ACCOUNT                  = 'soldToAccount',
  CUSTOMER                         = 'customer',
  SHIP_TO_ADDRESS                  = 'shipToAddress',
  BUSINESS_PARTNER_BILL_TO_ADDRESS = 'businessPartnerBillToAddress',
  ATTENTION_TO                     = 'attentionTo',
  FIRST_NAME                       = 'firstName',
  LAST_NAME                        = 'lastName',
  NAME_ROW                         = 'nameRow',
  EMAIL                            = 'email',
  PHONE                            = 'phone',
  SHIPMENT_METHOD                  = 'shipmentMethod',
  DEPARTMENT                       = 'department',
  FLOOR_OR_ROOM                    = 'floorOrRoom',
  DELIVERY_DATE                    = 'deliveryDate',
  DELIVERY_TIME                    = 'deliveryTime',
  COMMENT                          = 'comment',
}

export enum EApproverInputNames {
  APPROVER   = 'approver',
  COMMENT    = 'comment',
  PO_NUMBER  = 'poNumber',
  PROMO_CODE = 'promoCode',
}
