import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationStart, Params, Router, RouterEvent, UrlTree } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { ArrayUtils } from '../../utils/array.utils';
import { environment } from '../../../environments/environment';
import { redirectToUrlExecuted } from '../../actions/app.actions';
import { AnalyticsService } from '../../analytics/analytics.service';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { CatalogFacade } from '../../facades/catalog.facade';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { MarketingFacade } from '../../facades/marketing.facade';
import {
  IAbstractProductIncluded,
  ICatalogProduct,
} from '../../models/abstract-product.models';
import {
  ICatalogSearchIncluded,
  ICategoryNode,
  IContractParams,
  ICurrentParams, IEquipmentValues, IPartsCategory, IProductIncluded,
  ISort,
} from '../../models/catalog.models';
import { IPagination, ISortOption } from '../../models/settings.model';
import { IEquipmentFilter } from '../../models/equipment-filter.model';
import { CategoryUtils } from '../../utils/category.utils';
import { ObjectUtils } from '../../utils/object.utils';
import { ProductUtils } from '../../utils/product.utils';
import { StringUtils } from '../../utils/string.utils';
import { ISystemDetails } from '../../models/common.models';
import { ICart } from '../../models/cart.models';
import { EFeatureToggles, EStoreTypes } from '../../configurations/common';
import { AppUtils } from '../../utils/app.utils';
import { InstallBaseFacade } from '../../facades/install-base.facade';
import { ISimpleInstallBaseProduct } from '../../models/installedbase.models';
import { CustomerFacade } from '../../facades/customer.facade';
import { SparePartsUtils } from '../../utils/spare-parts.utils';
import {
  httpContractParams,
  httpParams,
  notFilterableAttributesData,
  warningMessageData,
} from './catalog-data';

@Component({
  selector: 'app-catalog',
  templateUrl: './catalog.component.html',
  styleUrls: ['./catalog.component.scss'],
})
export class CatalogComponent implements OnInit, OnDestroy, AfterViewChecked {

  abstractProductIncluded: IAbstractProductIncluded[];
  abstractProducts: ICatalogProduct[] = [];
  activatedRouteSubscription: Subscription;
  activeEquipmentType: string[] = [];
  activeRoute: string;
  baseRouteUrl: string;
  blackFridaySticker: string = environment.blackFridaySticker;
  blockedSortByOptions: string[];
  catalogTitle: string = '';
  categoriesLoaded: boolean = false;
  categoryTree: ICategoryNode[];
  contractParams: IContractParams = httpContractParams;
  currentCart: ICart;
  currentCartId: string;
  currentLayoutType: string = 'grid';
  fetchedCategories: any;
  filters: any = [];
  filtersDropdownOpened: boolean = false;
  firstPageLoad: boolean = true;
  hasContractParams: boolean = false;
  installBaseProduct: ISimpleInstallBaseProduct;
  isBusinessPartner: boolean = false;
  isCaStore: boolean = false;
  isCpqEnabled: boolean = false;
  isMyInstalledBaseFlowEnabled$: Observable<boolean> = new Observable<boolean>();
  isSparePartsEnabled: boolean = false;
  isUsStore: boolean = false;
  labels: IAbstractProductIncluded[] | null = [];
  loading: boolean = false;
  loadingCartDataInProgress: boolean = true;
  noProductFound: boolean = false;
  notFilterableAttributes: string[] = notFilterableAttributesData;
  pagination: IPagination;
  params: ICurrentParams = httpParams;
  sort: ISortOption[];
  systemDetails: ISystemDetails;
  warningWasShown: boolean = false;

  equipmentTypeFilter: IEquipmentFilter = {
    name: 'rel-product-sysivk',
    localizedName: this.translateService.instant('filters.equipment-filter.rel-product-sysivk'),
    isApplicable: false,
    activeValue: [],
    values: [],
  };

  private readonly unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private analyticsService: AnalyticsService,
    private translate: TranslateService,
    private catalogFacade: CatalogFacade,
    private configurationFacade: ConfigurationFacade,
    private marketingFacade: MarketingFacade,
    private installBaseFacade: InstallBaseFacade,
    private changeDetector: ChangeDetectorRef,
    private translateService: TranslateService,
    private customerFacade: CustomerFacade,
  ) {
  }

  ngOnInit(): void {
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.isCaStore = AppUtils.isStoreActive(EStoreTypes.CA);
    this.activeRoute = this.activatedRoute.snapshot.url[1].path;
    this.baseRouteUrl = '/' + this.activatedRoute.snapshot.url.map(url => url.path).join('/');
    this.isMyInstalledBaseFlowEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.MY_INSTALLED_BASE_FLOW);

    this.selectIsCustomerBusinessPartner();
    this.setEquipmentTypeFilter();
    this.selectUsFeatures();
    this.selectLoadingCartDataInProgress();
    this.selectCartAndSetParamsForFL();
    this.getBlockedSortByOptions();
    this.selectIsCategoriesLoaded();
    this.selectCategoriesSubscription();
    this.navigationBarEventSubscription();
    this.shouldShowWarning();
  }

  /**
   * Method to set feature toggles for CPQ and SpareParts
   */
  selectUsFeatures(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.CPQ).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isCpqEnabled = value;
    });

    this.configurationFacade.isFeatureEnabled(EFeatureToggles.SPARE_PARTS).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isSparePartsEnabled = value;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewChecked(): void {
    this.changeDetector.detectChanges();
  }

  /**
   * Method to set loading of categories
   */
  selectIsCategoriesLoaded(): void {
    this.catalogFacade.selectIsCategoriesLoaded().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(loaded => {
      this.categoriesLoaded = loaded;
    });
  }

  /**
   *  Method to select category tree and call filterCatalogBasedOnTogglesAndStore() and initializePage()
   */
  selectCategoriesSubscription(): void {
    this.catalogFacade.selectCategories().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(categories => {
      this.categoryTree = categories;
      if (!this.categoriesLoaded) {
        return;
      }
      this.filterCatalogBasedOnTogglesAndStore();
      this.initializePage();
    });
  }

  /**
   * Method to initialize page
   */
  navigationBarEventSubscription(): void {
    this.router.events.pipe(
      takeUntil(this.unsubscribe$),
      filter(event => event instanceof NavigationStart),
    ).subscribe(currentRouteEvent => {
      if (currentRouteEvent instanceof RouterEvent) {
        if (this.baseRouteUrl === currentRouteEvent.url) {
          this.initializePage();
        }
      }
    });
  }

  /**
   * Method to initialize page
   */
  initializePage(): void {
    this.activatedRouteSubscription = this.activatedRoute.url.pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        if (this.isRouteActive('catalog') && !this.activatedRoute.firstChild) {
          this.updateCategoryInUrl(true);
        } else if (this.activatedRoute.firstChild) {
          this.getQueryParamsFromChild();
        } else {
          this.getQueryParams();
        }
      });
  }

  /**
   * Method to get query params
   */
  getQueryParams(): void {
    this.activatedRoute.queryParams
      .pipe(debounceTime(0))
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(routeParams => {
        this.resolveUrlParams(routeParams);

        if (this.isRouteActive('installed-base')) {
          if (!routeParams.hasOwnProperty('rel-product-sysivk')) {
            this.router.navigate(['page-not-found']).then();
            return;
          }

          this.selectInstalledBase(routeParams['rel-product-sysivk']);
        }
      });
  }

  /**
   * Method to removes categories from catalogTree based on feature toggles when CPQ/SpareParts disabled will remove relevant category.
   * for now only for CA and US shop
   */
  filterCatalogBasedOnTogglesAndStore(): void {
    if ((this.isUsStore || this.isCaStore) && (!this.isSparePartsEnabled || !this.isCpqEnabled)) {
      this.categoryTree = this.filterCategoriesByFeatures(this.categoryTree);
    }
  }

  /**
   * Method to remove Categories from category tree based on features toggles
   *
   * @param {ICategoryNode[]} categories
   * @returns {ICategoryNode[]}
   */
  filterCategoriesByFeatures(categories: ICategoryNode[]): ICategoryNode[] {
    const hiddenCategories: string[] = [];

    if (!this.isCpqEnabled) {
      hiddenCategories.push('service contracts');
    }

    if (!this.isSparePartsEnabled) {
      hiddenCategories.push('parts');
    }
    return categories?.filter(category => !hiddenCategories.includes(category.name?.toLocaleLowerCase()));
  }

  /**
   * Method to get query params from first child
   */
  getQueryParamsFromChild(): void {
    combineLatest([
      this.activatedRoute.firstChild.params,
      this.activatedRoute.firstChild.queryParams,
    ]).pipe(takeUntil(this.unsubscribe$))
      .pipe(map(([params, queryParams]) => (
        {...params, ...queryParams}),
      )).pipe(debounceTime(0)).subscribe(routeParams => {
      this.activatedRouteSubscription.unsubscribe();
      this.resolveUrlParams(routeParams);
    });
  }

  /**
   * Method to select install base
   * @param {string} materialNumber
   */
  selectInstalledBase(materialNumber?: string): void {
    this.catalogFacade.selectInstalledBase().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(installedBase => {
      if (!!materialNumber && (!installedBase || this.installBaseProduct?.attributes.materialNumber !== materialNumber)) {
        this.setInstalledBase(materialNumber);
      } else {
        this.installBaseProduct = installedBase;
      }
    });
  }

  /**
   * Method to set install base
   * @param {string} materialNumber
   */
  setInstalledBase(materialNumber: string): void {
    this.installBaseFacade.selectInstalledBaseProductData()
      .pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(installBaseProducts => {
      this.installBaseProduct = installBaseProducts.data.filter(
        installBaseProduct => installBaseProduct.attributes.materialNumber === materialNumber,
      )[0];

      this.catalogFacade.setInstalledBase(this.installBaseProduct);
    });
  }

  /**
   * Method to resolve URL params
   * @param orgParams
   */
  resolveUrlParams(orgParams: any): void {
    let params: any = {};

    Object.keys(orgParams).forEach(key => {
      params[key] = orgParams[key];
    });

    if (!this.isRouteActive('installed-base') && params['fl-number']) {
      delete params['fl-number'];
    }

    if (params.category) {
      const categoryNode: ICategoryNode = CategoryUtils.getCategoryByParamFromTree(this.categoryTree, decodeURIComponent(params.category));

      if (!categoryNode) {
        this.updateCategoryInUrl();
        return;
      }

      params = {...params, ...{category: categoryNode.nodeId}};
    }

    if (params.page) {
      params = {
        ...params, ...{
          'page[limit]': this.params['page[limit]'],
          'page[offset]': (params.page * this.params['page[limit]']) - this.params['page[limit]'],
        },
      };
    } else {
      params = {
        ...params, ...{
          'page[limit]': this.params['page[limit]'],
          'page[offset]': 0,
          page: 1,
        },
      };
    }

    if (this.isRouteActive('catalog')) {
      const catalogChildSegment: string = this.activatedRoute.firstChild?.snapshot.url[0].path ?? '';
      this.equipmentTypeFilter.isApplicable = false;

      if (catalogChildSegment === 'service-contracts') {
        if (this.contractParams['rel-product-sysivk']) {
          params = {...params, 'rel-product-sysivk': this.contractParams['rel-product-sysivk']};
        }
      }
    }

    this.params = params;
    this.catalogFacade.setQueryParams({...params});
    this.getSearchResults();
  }

  /**
   * Method to update category in url
   * @param {boolean} reInitialize
   */
  updateCategoryInUrl(reInitialize = false): void {
    this.catalogFacade.selectQueryParams().pipe(take(1)).subscribe(queryParams => {
      const categoryName: string = this.getCategoryToRedirectTo(this.categoryTree, reInitialize ? null : queryParams);
      const urlTree: UrlTree = this.router.parseUrl(this.router.url);
      const filteredQueryParams = ObjectUtils.shallowFilterObject(urlTree.queryParams, 'category');

      if (!categoryName) {
        this.configurationFacade.setAlert({
            type: 'error',
            message: this.translate.instant('search-results.no-categories'),
            action: redirectToUrlExecuted.type,
            props: {url: '/' + this.activatedRoute.snapshot.url[0].path},
          },
        );
        return;
      }

      this.router.navigate(
        [`/catalog/${StringUtils.toLowerCaseWithoutWhiteSpaceOrSlash(categoryName, '-')}`],
        {queryParams: filteredQueryParams},
      ).then();
    });
  }

  /**
   * Method to get category to create redirect to category
   * @param {ICategoryNode[]} categoryTree
   * @param {ICurrentParams} params
   * @returns {string}
   */
  getCategoryToRedirectTo(categoryTree: ICategoryNode[], params: ICurrentParams): string {
    let categoryNode: ICategoryNode;

    if (params?.category) {
      categoryNode = CategoryUtils.getCategoryByIdFromTree(categoryTree, params.category);
    } else {
      categoryNode = categoryTree.find(parentCategory => parentCategory.hasProducts);
    }

    return categoryNode ? categoryNode.name : this.categoryTree[0].name;
  }

  /**
   * Method to get blocked sort by options
   */
  getBlockedSortByOptions(): void {
    this.catalogFacade.selectBlockedSortByOptions().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(blockedSortByOptions => this.blockedSortByOptions = blockedSortByOptions);
  }

  /**
   *  Method to get no products for BP and show filter
   *
   * @return void
   */
  getSearchResultsNoProductsBP(): void {
    this.loading = true;

    this.catalogFacade.getSearchResults(this.params).pipe(takeUntil(this.unsubscribe$)).subscribe(response => {
      this.loading = false;
      if (response) {
        const {pagination, valueFacets} = response.data[0].attributes;
        this.analyticsService.trackPageReady('Product list', PageTypes.PRODUCT_LIST_PAGE, null, this.params.q);
        this.pagination = pagination as IPagination;
        this.fetchedCategories = valueFacets.find(facet => facet.name === 'category').values;

        this.filters = valueFacets.filter(valueFacet =>
          !this.notFilterableAttributes.includes(valueFacet.name) && (valueFacet.values.length > 0 || valueFacet.activeValue)
          && AppUtils.getCurrentStore().catalogFilters.includes(valueFacet.name),
        );
      }

      this.firstPageLoad = false;
    });
  }

  /**
   * Method to get search results
   */
  getSearchResults(): void {
    this.loading = true;

    this.catalogFacade.getSearchResults(this.params).pipe(takeUntil(this.unsubscribe$)).subscribe(response => {
      this.loading = false;
      this.setProducts(response);
      if (response) {
        const {pagination, sort, valueFacets} = response.data[0].attributes;
        this.analyticsService.setProducts(response);
        this.analyticsService.trackPageReady('Product list', PageTypes.PRODUCT_LIST_PAGE, null, this.params.q);
        this.pagination = pagination as IPagination;
        this.setSort(sort);
        this.fetchedCategories = valueFacets.find(facet => facet.name === 'category').values;

        //select "Parts" category by its unique id (nodeId === 2)
        let partsCategory: IPartsCategory[] = CategoryUtils.filterCategoryChildrens(
          [ObjectUtils.deepClone(this.categoryTree).find(category => category.nodeId === 2)],
          this.fetchedCategories,
          this.params.category,
        );

        this.equipmentTypeFilter.isApplicable = CategoryUtils.isParentCategoryActive(partsCategory) || this.noProductFound;
        if (this.equipmentTypeFilter.isApplicable) {
          this.resolveUrlParamsForParts(this.params);
        }

        this.filters = valueFacets.filter(valueFacet =>
          !this.notFilterableAttributes.includes(valueFacet.name) && (valueFacet.values.length > 0 || valueFacet.activeValue)
          && AppUtils.getCurrentStore().catalogFilters.includes(valueFacet.name),
        );
        this.addActiveFilters();
      }

      this.firstPageLoad = false;

      if (this.noProductFound && this.isBusinessPartner) {
        delete this.params['fl-number'];
        delete this.params['rel-product-sysivk'];

        this.catalogFacade.setQueryParams({...this.params});
        this.getSearchResultsNoProductsBP();
      }

      if (!this.noProductFound && this.isBusinessPartner) {
        this.getSearchResultsNoProductsBP();
      }
    });
  }

  /**
   * Method to set equipment type filter
   */
  setEquipmentTypeFilter(): void {
    let equipmentValues: IEquipmentValues[] = [];

    this.installBaseFacade.selectInstalledBaseProductData()
      .pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe((installBaseSystems) => {
      if (installBaseSystems) {
        installBaseSystems.data.forEach(installBaseSystem => {
          equipmentValues.push(
            {
              value: installBaseSystem.attributes.materialName,
              'rel-product-sysivk': installBaseSystem.attributes.materialNumber,
            },
          );
          equipmentValues = ArrayUtils.removeDuplicates(equipmentValues);
          this.equipmentTypeFilter.values = ArrayUtils.sortByAttribute(equipmentValues, 'value');
        });
      }
    });
  }

  /**
   * Method to set sorting
   * @param {ISort} sort
   */
  setSort(sort: ISort): void {
    this.sort = Object.keys(sort.sortParamLocalizedNames).map(key => {
      return {
        name: sort.sortParamLocalizedNames[key],
        value: key,
      };
    });

    if (this.blockedSortByOptions.length > 0) {
      this.sort = this.sort.filter(option => !this.blockedSortByOptions.includes(option.value));
    }
  }

  /**
   * Method to add active filters
   */
  addActiveFilters(): void {
    const clonedFilters = ObjectUtils.deepClone(this.filters);

    this.filters = clonedFilters.map(filterEntity => {
      if (Array.isArray(filterEntity.activeValue)) {
        const missingFilters = filterEntity.activeValue.filter(value =>
          !filterEntity.values.find(entity => entity.value === value));

        missingFilters.forEach(entity => filterEntity.values.push({value: entity, doc_count: 0}));
        return filterEntity;
      }
      if (filterEntity.activeValue) {
        const exists = filterEntity.values.find(entity => entity.value === filterEntity.activeValue);
        if (!exists) {
          filterEntity.values.push({value: filterEntity.activeValue, doc_count: 0});
        }
      }
      return filterEntity;
    });
  }

  /**
   * Method to set products
   * @param products
   */
  setProducts(products: ICatalogSearchIncluded): void {
    const {abstractProducts} = products.data[0].attributes;
    const includedProducts: IProductIncluded[] = products?.included ?? [];
    this.abstractProducts = abstractProducts.reduce((acc, product) => {
      const includes: IProductIncluded[] = includedProducts.filter(include => {
        const includeSku: string = include.type === 'concrete-products' ? include.attributes.productAbstractSku : include.id;
        if (includeSku === product.abstractSku) {
          return include;
        }
      });
      const hasProductLabels: boolean = !!includes.find(include => include.type === 'abstract-products')?.relationships['product-labels'];
      if (hasProductLabels) {
        acc.push({...product, ...includes.reduce((ac, a) => ({...ac, ...a.attributes, ...a.relationships}), {})});
      } else {
        acc.push({...product, ...includes.reduce((ac, a) => ({...ac, ...a.attributes}), {})});
      }
      return acc;
    }, []);

    this.abstractProductIncluded = includedProducts;
    this.noProductFound = this.abstractProducts.length === 0;
    this.getLabels();
    this.setCpqSpecificCatalogTitle();
  }

  /**
   * Method to get labels
   * @returns {IAbstractProductIncluded[]}
   */
  getLabels(): IAbstractProductIncluded[] {
    if (!this.abstractProductIncluded) {
      return;
    }
    this.labels = this.abstractProductIncluded?.filter(item => item.type === 'product-labels');
  }

  /**
   * Method to show black friday label
   * @returns {IAbstractProductIncluded}
   */
  getBlackFridayLabel(): IAbstractProductIncluded {
    if (this.labels?.length === 0) {
      return null;
    }

    return this.labels.find(item => item?.attributes?.frontEndReference?.toLocaleUpperCase() === 'BF') || null;
  }

  /**
   * Method to select layout option
   * @param option
   */
  selectLayoutOption(option): void {
    this.currentLayoutType = option;
  }

  /**
   * Method to check if route is active
   * @param {string} catalog
   * @returns {boolean}
   */
  isRouteActive(catalog: string): boolean {
    return this.activeRoute.includes(catalog);
  }

  /**
   * Method to show results message
   * @returns {boolean}
   */
  showResultsMessage(): boolean {
    return this.pagination && this.pagination.numFound >= 0 && !this.firstPageLoad && !!this.params.q;
  }

  /**
   * Method to proceed to homepage
   */
  proceedToHomepage(): void {
    this.router.navigate([this.activatedRoute.snapshot.url[0].path]).then();
  }

  /**
   * Method to proceed to catalog
   */
  proceedToCatalog(): void {
    this.router.navigate(['/catalog']).then();
  }

  /**
   * Method to show no configuration available info
   * @returns {boolean}
   */
  showNoConfigurationAvailableInfo(): boolean {
    if (!this.containsConfigurableProducts() || this.abstractProducts.length === 0) {
      return false;
    }
    return !!this.abstractProducts.find(product => !ProductUtils.isConfigurableAvailable(product));
  }

  /**
   * Method to check if it's spare parts catalog and if abstract products contains configurable products
   * @returns {boolean}
   */
  isSparePartsCatalogAndHasProducts(): boolean {
    return (this.isUsStore || this.isCaStore) && this.abstractProducts?.length > 0
      && !this.abstractProducts.find(product => product?.productConfigurationInstance);
  }

  /**
   * Method to check if products have been found
   * @returns {boolean}
   */
  showNoProductFound(): boolean {
    return (this.isRouteActive('catalog') || this.isRouteActive('installed-base')) && this.noProductFound;
  }

  /**
   * Method to check if abstract products contains configurable products
   * @returns {boolean}
   */
  containsConfigurableProducts(): boolean {
    return !!this.abstractProducts.find(product => product?.productConfigurationInstance);
  }

  /**
   * Method to set CPQ specific catalog title
   */
  setCpqSpecificCatalogTitle(): void {
    if (this.isUsStore || this.isCaStore) {
      this.catalogTitle = this.containsConfigurableProducts() ? 'catalog.select-contract-type' : 'catalog.select-part-type';
    }
  }

  /**
   *  Method to set contract params
   * @param {Params} params
   */
  setContractParams(params: Params): void {
    this.contractParams['fl-number'] = params['fl-number'] ?? '';
    this.contractParams['rel-product-sysivk'] = params['rel-product-sysivk'] ?? '';
    this.hasContractParams = !!this.contractParams['rel-product-sysivk'].length && !!this.contractParams['fl-number'].length;
    this.catalogTitle = 'product-list.catalog';
  }

  /**
   * Method to resolve URL params for Parts and set equipment filter active value
   * @param {ICurrentParams} params
   * @returns {ICurrentParams}
   * @private
   */
  private resolveUrlParamsForParts(params: ICurrentParams): ICurrentParams {
    this.equipmentTypeFilter.activeValue = [];

    if (params['rel-product-sysivk']) {
      this.activeEquipmentType = Array.isArray(params['rel-product-sysivk'])
        ? params['rel-product-sysivk']
        : [params['rel-product-sysivk'].toString()];

      this.equipmentTypeFilter.activeValue = this.activeEquipmentType;
    }

    return params;
  }

  /**
   * Method to select cart, cartId, systemDetails, queryParams and show warning message
   * @private
   */
  private selectCartAndSetParamsForFL(): void {
    combineLatest([
      this.marketingFacade.selectCart(),
      this.activatedRoute.queryParams,
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(([cart, params]) => {
      if (cart) {
        this.currentCart = cart;
        this.currentCartId = cart.id;
        this.systemDetails = cart.attributes?.systemDetails;
        this.shouldShowWarning();
      }
      this.setContractParams(params);
    });
  }

  /**
   * Method to set loading of cart data
   * @private
   */
  private selectLoadingCartDataInProgress(): void {
    this.marketingFacade.selectLoadingCartDataInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(loadingCartDataInProgress => {
      this.loadingCartDataInProgress = loadingCartDataInProgress;
    });
  }

  /**
   * Method for showing warning message
   * @private
   */
  private shouldShowWarning(): void {
    if (SparePartsUtils.canCartItemsBeChanged(this.currentCart, this.isBusinessPartner) && !this.warningWasShown) {
      this.showWarning();
      this.warningWasShown = true;
    }
  }

  /**
   * Method for creating warning notification
   * @private
   */
  private showWarning(): void {
    this.configurationFacade.appendNotification(warningMessageData);
  }

  /**
   * Method to set isBusinessPartner variable
   */
  selectIsCustomerBusinessPartner(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isBusinessPartner => {
      this.isBusinessPartner = isBusinessPartner;
    });
  }
}
