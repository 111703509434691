import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import * as ValidationPatterns from '../../configurations/validations';
import { addressValidation } from '../../configurations/validations';
import { take, takeUntil, tap } from 'rxjs/operators';
import { CustomerFacade } from '../../facades/customer.facade';
import { combineLatest, Observable, skipWhile, Subject } from 'rxjs';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { IAddressSelectBox, ICustomerCheckoutData, ICustomerInfo } from '../../models/customer.models';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { ShopCartActions } from '../../actions';
import { AddressUtils } from '../../utils/address.utils';
import { IAddress, IPointOfContact, ISelectEvent, ISystemDetails, ITotals } from '../../models/common.models';
import { SparePartsUtils } from '../../utils/spare-parts.utils';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../environments/environment.defaults';
import { AppUtils } from '../../utils/app.utils';
import { EAddressType, EDeliveryInputNames, EGlueResource, EStoreTypes, EUserRoles } from '../../configurations/common';
import { MarketingFacade } from '../../facades/marketing.facade';
import { IBaseConfig } from '../../models/enviroment-delivery-details.model';
import { IShipmentMethod } from '../../models/checkout.models';
import { ICartAttributes, ICartItemWithDetail } from '../../models/cart.models';
import { ConfigurationFacade } from '../../facades/configuration.facade';

@Component({
  selector: 'app-dynamic-checkout-form',
  templateUrl: './dynamic-checkout-form.component.html',
  styleUrl: './dynamic-checkout-form.component.scss',
})
export class DynamicCheckoutFormComponent implements OnInit, OnDestroy {
  orderDetailsForm: UntypedFormGroup;
  addressesLoading: boolean = true;
  availableShipToAddresses: IAddressSelectBox[] = [];
  availableBillToAddresses: IAddressSelectBox[] = [];
  showModalAddNewAddress: boolean = false;
  showModalReportWrongAddress: boolean = false;
  isUsStore: boolean = false;
  addressChanged: boolean = false;
  shipmentMethodsLoading: boolean = false;
  shipmentMethods: IShipmentMethod[];
  defaultShipmentMethodOptions: IBaseConfig[];
  editedShipmentMethodOptions: IBaseConfig[];
  isShipmentMethodSelectedManually: boolean = false;
  idShipmentMethod: number;
  departments$: Observable<IBaseConfig[]> = new Observable<IBaseConfig[]>();
  deliveryTimes$: Observable<IBaseConfig[]> = new Observable<IBaseConfig[]>();
  loadingCheckoutData: boolean = true;
  currentShipToAddress: IAddress;
  loadingAddressesDataAlreadyStarted: boolean = false;

  maxLengthAttentionTo: number;
  maxLengthComment: number;
  maxLengthName: number;
  maxLengthPhone: number;
  minDaysFromToday: number = 0;

  @Input() isSectionDisplayed: boolean = false;
  @Input() isSaturdayShipment: boolean = false;
  @Input() cartId: string;
  @Input() cartItemsWithDetails: Array<ICartItemWithDetail>;
  @Input() requiredInputs: string[];
  @Input() visibleInputs: string[];
  @Input() readOnlyInputs: string[];
  @Input() cartAttributes: ICartAttributes;
  @Input() systemDetails: ISystemDetails;
  @Input() userData: ICustomerCheckoutData;
  @Input() userRoles: EUserRoles[];
  @Input() isBusinessPartner: boolean = false;
  @Input() isPreselectedShipToAddressCustom: boolean = false;
  @Input() isPriceDisputingSetInCart: boolean = false;

  @Output() formValidityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  reportWrongAddressType: EAddressType = EAddressType.SHIP_TO;

  protected readonly EAddressType = EAddressType;
  protected readonly EDeliveryInputNames = EDeliveryInputNames;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customerFacade: CustomerFacade,
    private checkoutFacade: CheckoutFacade,
    private store: Store<State>,
    private translate: TranslateService,
    private marketingFacade: MarketingFacade,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  /**
   * Initializes the component.
   */
  ngOnInit(): void {
    this._getMaxLength();
    this.initializeForm();
    this.loadNeededDataForForm();
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
  }

  /**
   * Cleans up the component.
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  /**
   * Initialize form with default values and validators.
   */
  initializeForm(): void {
    const formGroupConfig = {};

    const inputConfigMap = {
      [EDeliveryInputNames.SELECTED_EQUIPMENT]: ['', [...this.getRequiredValidator(EDeliveryInputNames.SELECTED_EQUIPMENT)]],
      [EDeliveryInputNames.SOLD_TO_ACCOUNT]: ['', [...this.getRequiredValidator(EDeliveryInputNames.SOLD_TO_ACCOUNT)]],
      [EDeliveryInputNames.CUSTOMER]: this.isBusinessPartner ? ['', [...this.getRequiredValidator(EDeliveryInputNames.CUSTOMER)]] : '',
      [EDeliveryInputNames.SHIP_TO_ADDRESS]: ['', [...this.getRequiredValidator(EDeliveryInputNames.SHIP_TO_ADDRESS), addressValidation]],
      [EDeliveryInputNames.BUSINESS_PARTNER_BILL_TO_ADDRESS]: this.isBusinessPartner ? ['', [...this.getRequiredValidator(EDeliveryInputNames.BUSINESS_PARTNER_BILL_TO_ADDRESS)]] : '',
      [EDeliveryInputNames.ATTENTION_TO]: ['', [...this.getRequiredValidator(EDeliveryInputNames.ATTENTION_TO), Validators.maxLength(this.maxLengthAttentionTo)]],
      [EDeliveryInputNames.FIRST_NAME]: ['', [...this.getRequiredValidator(EDeliveryInputNames.FIRST_NAME), Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      [EDeliveryInputNames.LAST_NAME]: ['', [...this.getRequiredValidator(EDeliveryInputNames.LAST_NAME), Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      [EDeliveryInputNames.EMAIL]: ['', [...this.getRequiredValidator(EDeliveryInputNames.EMAIL), Validators.email]],
      [EDeliveryInputNames.PHONE]: ['', [...this.getRequiredValidator(EDeliveryInputNames.PHONE), Validators.maxLength(this.maxLengthPhone), ValidationPatterns.phonePattern]],
      [EDeliveryInputNames.SHIPMENT_METHOD]: ['', [...this.getRequiredValidator(EDeliveryInputNames.SHIPMENT_METHOD)]],
      [EDeliveryInputNames.DEPARTMENT]: ['', [...this.getRequiredValidator(EDeliveryInputNames.DEPARTMENT)]],
      [EDeliveryInputNames.FLOOR_OR_ROOM]: ['', [...this.getRequiredValidator(EDeliveryInputNames.FLOOR_OR_ROOM)]],
      [EDeliveryInputNames.DELIVERY_TIME]: ['', [...this.getRequiredValidator(EDeliveryInputNames.DELIVERY_TIME)]],
      [EDeliveryInputNames.DELIVERY_DATE]: ['', [...this.getRequiredValidator(EDeliveryInputNames.DELIVERY_DATE)]],
      [EDeliveryInputNames.COMMENT]: ['', [...this.getRequiredValidator(EDeliveryInputNames.COMMENT), Validators.maxLength(this.maxLengthComment), ValidationPatterns.noEmptySpaceOnTheBeginning]],
    };

    this.visibleInputs.forEach(input => {
      if (inputConfigMap[input]) {
        formGroupConfig[input] = inputConfigMap[input];
      }
    });

    this.orderDetailsForm = this.formBuilder.group(formGroupConfig);

    //emitting because of init state of the form
    this.formValidityChange.emit(this.orderDetailsForm.valid);
    this._checkFormValidity();

    this.selectCustomerInfo();
  }

  /**
   * Loads the necessary data for the form based on the visible inputs configuration.
   */
  loadNeededDataForForm(): void {
    this.visibleInputs.forEach(visibleInputs => {
      switch (visibleInputs) {
        case EDeliveryInputNames.SELECTED_EQUIPMENT:
        case EDeliveryInputNames.SOLD_TO_ACCOUNT:
        case EDeliveryInputNames.SHIP_TO_ADDRESS:
        case EDeliveryInputNames.CUSTOMER:
        case EDeliveryInputNames.BUSINESS_PARTNER_BILL_TO_ADDRESS:
          this._loadDeliveryDetailsAddressesData();
          break;
        case EDeliveryInputNames.DEPARTMENT:
          this.departments$ = this.configurationFacade.getDynamicDeliveryDetailsFormDepartments();
          break;
        case EDeliveryInputNames.DELIVERY_DATE:
          this.getDatepickerMinDaysFromToday();
          break;
        case EDeliveryInputNames.DELIVERY_TIME:
          this.deliveryTimes$ = this.configurationFacade.getDynamicDeliveryDetailsFormDeliveryTimes();
          break;
        default:
          break;
      }
    });
  }

  /**
   * Checks the form validity and emits the validity status.
   * @private
   */
  private _checkFormValidity(): void {
    this.orderDetailsForm.statusChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.formValidityChange.emit(this.orderDetailsForm.valid);
    });
  }

  /**
   * Gets the required validators for a given input name.
   * @param inputName - The name of the input.
   * @returns An array of validators.
   */
  getRequiredValidator(inputName: string): ValidatorFn[] {
    return this.requiredInputs?.includes(inputName) ? [Validators.required] : [];
  }

  /**
   * Selects customer profile information and pre-fills the form.
   */
  selectMyProfileInformation(): void {
    combineLatest([
      this.customerFacade.selectMyProfileInformation(),
      this.checkoutFacade.selectDeliveryDetailsFormData(),
    ]).pipe(
      take(1),
    ).subscribe(([myProfileInformation, deliveryDetailFormData]) => {
      if (this.visibleInputs.includes(EDeliveryInputNames.SHIPMENT_METHOD)) {
        const selectedShipmentMethod = this.resolveShipmentMethod(
          deliveryDetailFormData?.shipmentMethod ?? myProfileInformation?.pointOfContact?.shipmentMethod,
        );
        this.recalculateShipmentMethodPrice(selectedShipmentMethod.id);
        this.preFillFormDefaults(myProfileInformation, deliveryDetailFormData, selectedShipmentMethod);
      } else {
        this.preFillFormDefaults(myProfileInformation, deliveryDetailFormData);
      }
    });
  }

  private _getMaxLength(): void {
    combineLatest([
      this.configurationFacade.getTranslationByKey('config.delivery_details_page_max_length_attention_to'),
      this.configurationFacade.getTranslationByKey('config.delivery_details_page_max_length_comment'),
      this.configurationFacade.getTranslationByKey('config.delivery_details_page_max_length_name'),
      this.configurationFacade.getTranslationByKey('config.delivery_details_page_max_length_phone'),
    ]).pipe(
      take(1),
    ).subscribe(([attentionTo, comment, name, phone]) => {
      this.maxLengthAttentionTo = attentionTo;
      this.maxLengthComment = comment;
      this.maxLengthName = name;
      this.maxLengthPhone = phone;
    });
  }

  /**
   * Gets the minimum days from today for the date picker.
   */
  getDatepickerMinDaysFromToday(): void {
    this.configurationFacade.getTranslationByKey('checkout-delivery-details.min-days-from-today').pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.minDaysFromToday = +value && +value >= 0 ? +value : 0;
    });
  }

  /**
   * Caches the current form data.
   */
  cacheCheckoutFormData(): void {
    this.checkoutFacade.setCheckoutFormData(this.orderDetailsForm.value);
  }

  /**
   * Sets the value of a contact form field.
   * @param event - The event containing the key and value to set.
   */
  setContactFormValue(event: ISelectEvent): void {
    this.orderDetailsForm.patchValue({
      [event.key]: event.value,
    });
  }

  /**
   * Checks if the shipment method has changed and updates the options accordingly.
   */
  checkIsShipmentMethodChanged(): void {
    if ((!this.isUsStore && this.orderDetailsForm.value.attentionTo != '')
      || this.orderDetailsForm.value.comment != ''
      || this.isPreselectedShipToAddressCustom
      || this.isPriceDisputingSetInCart
    ) {
      this.editedShipmentMethodOptions = this.defaultShipmentMethodOptions.filter(option => option.name != environment.sparePartSaturdayDeliveryKey);
    } else {
      this.editedShipmentMethodOptions = this.defaultShipmentMethodOptions;
    }
  }

  /**
   * Selects customer information and pre-fills the form.
   * @private
   */
  private selectCustomerInfo(): void {
    this.checkoutFacade.setCheckoutTotalPricesLoading(true);
    if (this.visibleInputs.includes(EDeliveryInputNames.SHIPMENT_METHOD)) {
      this.shipmentMethodsLoading = true;
      this.checkoutFacade.getShipmentMethodsOrRecalculatePrice({
        data: {
          type: EGlueResource.CHECKOUT_DATA,
          attributes: {
            idCart: this.cartId,
          },
        },
      }).pipe(takeUntil(this.unsubscribe$)).subscribe(response => {
        if (response?.data) {
          this.shipmentMethods = response.data.attributes?.shipmentMethods;
          this.defaultShipmentMethodOptions = this.shipmentMethods.map(shipmentMethod => {
            return {
              id: shipmentMethod.id,
              name: shipmentMethod.name,
              value: `${shipmentMethod.id}`,
            } as IBaseConfig;
          });
          this.editedShipmentMethodOptions = this.defaultShipmentMethodOptions;

          this.selectMyProfileInformation();
        }
        this.shipmentMethodsLoading = false;
      });
    } else {
      this.selectMyProfileInformation();
      this._setTotalPrices(this.cartAttributes.totals);
    }
  }

  /**
   * Recalculates the shipment method price based on the selected shipment method.
   * @param idShipmentMethod - The ID of the selected shipment method.
   * @private
   */
  private recalculateShipmentMethodPrice(idShipmentMethod: number): void {
    if (!idShipmentMethod || idShipmentMethod === 0) {
      this._setTotalPrices(this.cartAttributes.totals);
      return;
    }

    this.checkoutFacade.setCheckoutTotalPricesLoading(true);
    this.checkoutFacade.getShipmentMethodsOrRecalculatePrice({
      data: {
        type: EGlueResource.CHECKOUT_DATA,
        attributes: {
          idCart: this.cartId,
          shipment: {
            idShipmentMethod: idShipmentMethod,
          },
        },
      },
    }).pipe(takeUntil(this.unsubscribe$)).subscribe(response => {
      if (response?.data) {
        this.checkoutFacade.getShipmentMethodPrice(this.cartId)
          .pipe(take(1))
          .subscribe({
            next: cartItems => {
              if (cartItems?.data.attributes.totals) {
                this._setTotalPrices(cartItems.data.attributes.totals);
              }
            },
            error: () => this.checkoutFacade.setCheckoutTotalPricesLoading(false),
          });
      }
    });
  }

  /**
   * Sets the total prices in the checkout facade.
   * @param totals - The totals to set.
   * @private
   */
  private _setTotalPrices(totals: ITotals): void {
    this.checkoutFacade.setCheckoutTotalPrices({...totals});
    this.checkoutFacade.setCheckoutTotalPricesLoading(false);
  }

  /**
   * Sets the selected shipment method and updates the form.
   * @param event - The event containing the selected shipment method.
   */
  setShipmentMethod(event: ISelectEvent): void {
    this.idShipmentMethod = event.id;
    this.isShipmentMethodSelectedManually = true;
    this.isSaturdayShipment = event.name === environment.sparePartSaturdayDeliveryKey;

    this.orderDetailsForm.patchValue({
      [event.key]: event.value,
    });
    this.checkoutFacade.setCheckoutFormData(this.orderDetailsForm.value);
    this.recalculateShipmentMethodPrice(this.idShipmentMethod);
  }

  /**
   * Pre-fills the form with default values based on customer information and cached data.
   * @param customerInfo - The customer information.
   * @param cachedDeliveryDetailsFormData - The cached delivery details form data.
   * @param selectedShipmentMethod - The selected shipment method.
   * @private
   */
  private preFillFormDefaults(
    customerInfo: ICustomerInfo,
    cachedDeliveryDetailsFormData: IPointOfContact,
    selectedShipmentMethod?: IBaseConfig,
  ): void {
    this.visibleInputs.forEach(visibleInput => {
      switch (visibleInput) {
        case EDeliveryInputNames.FIRST_NAME:
          this.orderDetailsForm.patchValue({firstName: cachedDeliveryDetailsFormData?.firstName || customerInfo.firstName || ''});
          break;
        case EDeliveryInputNames.LAST_NAME:
          this.orderDetailsForm.patchValue({lastName: cachedDeliveryDetailsFormData?.lastName || customerInfo.lastName || ''});
          break;
        case EDeliveryInputNames.EMAIL:
          this.orderDetailsForm.patchValue({email: cachedDeliveryDetailsFormData?.email || customerInfo.email || ''});
          break;
        case EDeliveryInputNames.PHONE:
          this.orderDetailsForm.patchValue({phone: cachedDeliveryDetailsFormData?.phone || customerInfo.phone || ''});
          break;
        case EDeliveryInputNames.ATTENTION_TO:
          this.orderDetailsForm.patchValue({attentionTo: cachedDeliveryDetailsFormData?.attentionTo || ''});
          break;
        case EDeliveryInputNames.SHIPMENT_METHOD:
          this.orderDetailsForm.patchValue({shipmentMethod: selectedShipmentMethod.value});
          break;
        case EDeliveryInputNames.COMMENT:
          this.orderDetailsForm.patchValue({comment: cachedDeliveryDetailsFormData?.comment || ''});
          break;
        case EDeliveryInputNames.FLOOR_OR_ROOM:
          this.orderDetailsForm.patchValue({floorOrRoom: cachedDeliveryDetailsFormData?.floorOrRoom || ''});
          break;
        case EDeliveryInputNames.DEPARTMENT:
          this.orderDetailsForm.patchValue({department: cachedDeliveryDetailsFormData?.department || ''});
          break;
        case EDeliveryInputNames.DELIVERY_DATE:
          this.orderDetailsForm.patchValue({deliveryDate: cachedDeliveryDetailsFormData?.deliveryDate || ''});
          break;
        case EDeliveryInputNames.DELIVERY_TIME:
          this.orderDetailsForm.patchValue({deliveryTime: cachedDeliveryDetailsFormData?.deliveryTime || ''});
          break;
        default:
          break;
      }
    });
  }

  /**
   * Resolves the shipment method based on customer information.
   * @param shipmentMethod - The customer selected shipment method.
   * @returns The resolved shipment method.
   * @private
   */
  private resolveShipmentMethod(shipmentMethod: string): IBaseConfig {
    let selectedShipmentMethod: IBaseConfig;

    // first check if another shipment method has already been selected by the user
    if (shipmentMethod) {
      selectedShipmentMethod = this.defaultShipmentMethodOptions.find(
        option => option.value === shipmentMethod,
      );
      if (selectedShipmentMethod) {
        return selectedShipmentMethod;
      }
    }
    // otherwise check if total weight of ordering items is over weight limit
    // for automatic preselection of "FedEx Heavy Weight Shipment" method
    if (this.isHeavyWeightShipmentApplicable()) {
      selectedShipmentMethod = this.defaultShipmentMethodOptions.find(
        option => option.name === 'FedEx Heavy Weight Shipment',
      );
      if (selectedShipmentMethod) {
        return selectedShipmentMethod;
      }
    }

    // otherwise check store for default shipment method by name in current store config
    const defaultShipmentName = AppUtils.getCurrentStore().defaultShipmentMethod.name;
    selectedShipmentMethod = this.defaultShipmentMethodOptions.find(
      option => option.name === defaultShipmentName,
    );

    if (!selectedShipmentMethod) {
      // NOTE: This should never happen unless e.g.
      // - new shipment method setup is still in progress as part of new store setup
      // - name of the default shipment method has changed on the Angular or Spryker side
      selectedShipmentMethod = {id: 0, name: '', value: ''};
    }
    return selectedShipmentMethod;
  }

  /**
   * Determine if "FedEx HeavyWeight Shipment" method should be applied (based on the weight limit)
   * @returns True if the heavyWeight shipment method is applicable, false otherwise.
   */
  isHeavyWeightShipmentApplicable(): boolean {
    return this._selectCartItemsTotalWeight() > this.translate.instant('config.heavyWeightShipmentLimit');
  }

  /**
   * Select shipTo address in orderDetailsForm.
   *
   * @param {ISelectEvent} event - The event containing the selected shipTo address.
   */
  selectShipToAddressInForm(event: ISelectEvent): void {
    this.orderDetailsForm.patchValue({
      shipToAddress: {
        name: `${event?.value?.isCustom ? '' : event?.value?.sapId + ' -'} ${this.getFormattedAddress(event?.value)}`,
        value: event?.value,
      },
    });
    this.addressChanged = true;
    this.currentShipToAddress = event?.value;

    this.checkoutFacade.setPreselectedShipToAddress(this.currentShipToAddress);
    this.store.dispatch(ShopCartActions.setShipToAddressIdAction({shipToAddressSapId: event?.value?.sapId}));
    this.customerFacade.clearAttentionTo();
  }

  /**
   * Select business partner's billTo address in orderDetailsForm.
   *
   * @param {ISelectEvent} billToAddress - The event containing the selected billTo address.
   */
  selectBillToAddressInForm(billToAddress: ISelectEvent): void {
    this.orderDetailsForm.patchValue({
      businessPartnerBillToAddress: billToAddress,
    });

    this.checkoutFacade.setPreselectedBillToAddress(billToAddress?.value);
    this.store.dispatch(ShopCartActions.setBillToAddressIdAction({billToAddressSapId: billToAddress?.value?.sapId}));
  }


  /**
   * Get formatted address.
   * If address is not valid, error msg (configurable in Arakh) is returned.
   *
   * @private
   *
   * @param {IAddress} address
   * @param {EAddressType} addressType
   * @return {string}
   */
  private getFormattedAddress(address: IAddress, addressType: EAddressType = EAddressType.SHIP_TO): string {
    if (!AddressUtils.isAddressValid(address)) {
      if (addressType === EAddressType.SHIP_TO) {
        return this.translate.instant('spare-parts-order-details-page.missing-default-fl-ship-to-address');
      } else {
        return this.translate.instant('spare-parts-order-details-page.missing-default-fl-bill-to-address');
      }
    }
    return `${SparePartsUtils.formatAddressToString(address)}`;
  }

  /**
   * Show change address modal if saturday delivery shipment method is not selected.
   */
  showAddNewAddressModal(): void {
    if (!this.isSaturdayShipment) {
      this.showModalAddNewAddress = true;
    }
  }

  /**
   * Load delivery details addresses data from application state store.
   * @private
   */
  private _loadDeliveryDetailsAddressesData(): void {
    if (this.loadingAddressesDataAlreadyStarted) {
      return;
    }

    this.loadingAddressesDataAlreadyStarted = true;

    combineLatest([
      this.checkoutFacade.selectAvailableShipToAddresses(),
      this.checkoutFacade.selectPreselectedShipToAddress(),
      this.checkoutFacade.selectAvailableBillToAddresses(),
      this.checkoutFacade.selectPreselectedBillToAddress(),
      this.checkoutFacade.selectLoadingDeliveryDetailsData(),
    ]).pipe(
      tap(([, , , , loadingCheckoutData]) => this.loadingCheckoutData = loadingCheckoutData),
      skipWhile(([availableShipTos, , , , loadingCheckoutData]) => (!availableShipTos || loadingCheckoutData)),
      takeUntil(this.unsubscribe$),
    ).subscribe(([availableShipTos, preselectedShipTo, availableBillTos, preselectedBillTo, loadingCheckoutData]) => {
      this._patchDeliveryDetailsAddressesDataToForm(availableShipTos, preselectedShipTo, availableBillTos, preselectedBillTo);
      this.addressesLoading = loadingCheckoutData;
    });
  }

  /**
   * Patches the delivery details addresses data to the form.
   * @param availableShipToAddresses - The available ship-to addresses.
   * @param preselectedShipToAddress - The preselected ship-to address.
   * @param availableBillToAddresses - The available bill-to addresses.
   * @param preselectedBillToAddress - The preselected bill-to address.
   * @private
   */
  private _patchDeliveryDetailsAddressesDataToForm(
    availableShipToAddresses: IAddress[],
    preselectedShipToAddress: IAddress,
    availableBillToAddresses: IAddress[],
    preselectedBillToAddress: IAddress,
  ): void {
    this.visibleInputs.forEach(visibleInput => {
      switch (visibleInput) {
        case EDeliveryInputNames.SELECTED_EQUIPMENT:
          this.orderDetailsForm?.patchValue({selectedEquipment: this._getSelectedEquipment()});
          break;
        case EDeliveryInputNames.SOLD_TO_ACCOUNT:
          this.orderDetailsForm?.patchValue({soldToAccount: this._getSoldToAccount()});
          break;
        case EDeliveryInputNames.SHIP_TO_ADDRESS:
          this.currentShipToAddress = preselectedShipToAddress;
          this.availableShipToAddresses = AddressUtils.mapAddressesToAddressSelectBox(
            availableShipToAddresses, EAddressType.SHIP_TO, this.cartAttributes?.systemDetails?.shipToAddress?.sapId,
          );

          this.orderDetailsForm?.patchValue({
            shipToAddress: AddressUtils.mapAddressToAddressSelectBox(
              preselectedShipToAddress, EAddressType.SHIP_TO, this.cartAttributes?.systemDetails?.shipToAddress?.sapId,
            ),
          });
          break;
        case EDeliveryInputNames.CUSTOMER:
          if (this.isBusinessPartner) {
            this.orderDetailsForm?.patchValue({customer: this._getCustomer()});
          }
          break;
        case EDeliveryInputNames.BUSINESS_PARTNER_BILL_TO_ADDRESS:
          if (this.isBusinessPartner) {
            this.availableBillToAddresses = AddressUtils.mapAddressesToAddressSelectBox(
              availableBillToAddresses,
              EAddressType.BILL_TO,
            );

            this.orderDetailsForm?.patchValue({
              businessPartnerBillToAddress: AddressUtils.mapAddressToAddressSelectBox(
                preselectedBillToAddress, EAddressType.BILL_TO,
              ),
            });
          }
          break;
        default:
          break;
      }
    });
  }

  /**
   * Customer can set custom shipping address.
   *
   * @param {ISelectEvent} event - The event containing the custom ship-to address.
   */
  setCustomShipToAddress(event: ISelectEvent): void {
    const customShipToAddress: IAddress = {
      ...event.value,
      company: event.value.firstName,
      state: event.value.address2,
      sapId: this.currentShipToAddress?.sapId,
      isCustom: true,
    };

    this.addressChanged = true;
    this.customerFacade.setCustomCustomerShipToAddress(customShipToAddress);
    this.customerFacade.clearAttentionTo();
    this.checkoutFacade.setPreselectedShipToAddress(customShipToAddress);
    this.checkoutFacade.addShipToAddressToAvailableShipToAddresses(customShipToAddress);
  }

  /**
   * Gets the title for the report wrong address modal based on the address type.
   * @returns The title for the report wrong address modal.
   */
  titleForReport(): string {
    switch (this.reportWrongAddressType) {
      case EAddressType.BILL_TO:
        return 'checkout-delivery-details.report-wrong-bill-to-address';
      case EAddressType.SHIP_TO:
        return 'checkout-delivery-details.report-wrong-ship-to-address';
      case EAddressType.SOLD_TO:
        return 'checkout-delivery-details.report-wrong-sold-to-address';
      default:
        return 'checkout-delivery-details.report-wrong-ship-to-address';
    }
  }

  /**
   * Returns current form values for triggered report wrong address - BP only.
   *
   * @returns {string} The current form values for the report wrong address modal.
   */
  addressValueForReport(): string {
    switch (this.reportWrongAddressType) {
      case EAddressType.BILL_TO:
        return this.orderDetailsForm.value.businessPartnerBillToAddress?.name ?? ' ';
      case EAddressType.SHIP_TO:
        return this.orderDetailsForm.value.shipToAddress?.name;
      default:
        return this.orderDetailsForm.value.soldToAccount;
    }
  }

  /**
   * Selects the total weight of the cart items.
   * @returns The total weight of the cart items.
   * @private
   */
  private _selectCartItemsTotalWeight(): number {
    let totalWeight = 0;
    this.marketingFacade.selectCartItemsTotalWeight$()
      .pipe(take(1))
      .subscribe(weight => totalWeight = weight);
    return totalWeight;
  }

  /**
   * Gets the customer information.
   * @returns The customer information.
   * @private
   */
  private _getCustomer(): string {
    return this.systemDetails?.shipToAddress?.name ?? this.userData?.businessUnitName;
  }

  /**
   * Gets the selected equipment information.
   * @returns The selected equipment information.
   * @private
   */
  private _getSelectedEquipment(): string {
    return this.systemDetails
      ? (`${this.systemDetails?.nameEnUs} (${this.systemDetails?.siemensEquipmentId})`)
      : '';
  }

  /**
   * Gets the sold-to account information.
   * @returns The sold-to account information.
   * @private
   */
  private _getSoldToAccount(): string {
    return `${this.isBusinessPartner ? this.cartAttributes?.soldToAddress?.sapId + ' - '
      : ''}${this.cartAttributes?.soldToAddress?.company ?? this.userData?.businessUnitName}`;
  }
}
