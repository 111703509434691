import {
  IAddress,
  IBaseModel,
  IPriceDisputingPerItem,
  ISystemDetailsPerItem,
  ISystemDetails,
  IMaterialMasterNumbersPerItem,
  IPointOfContact,
} from './common.models';

export interface ICartsInfo {
  type: string;
  attributes: {
    customer: {
      email: string;
      salutation: string;
      firstName: string;
      lastName: string;
    };
    idCart: string;
    shippingAddress: IAddress;
    pointOfContact: {
      department: string;
      firstName: string;
      lastName: string;
      phone: string;
      deliveryTime: string;
      comment: string
    },
    approverDetails: {
      approverId: string;
      firstName: string;
      lastName: string;
      comment: string
    }
  };
}

export interface ICheckoutValidResponse extends IBaseModel {
  attributes: {
    orderReference: string;
    redirectUrl: string;
    isExternalRedirect: boolean;
  };
  relationships: {
    orders: IBaseModel[];
  };
}

export interface ICheckoutDataValidResponse extends IBaseModel {
  attributes: {
    addresses: IAddress[];
    paymentProviders: {
      paymentProviderName: string;
      paymentMethods: {
        paymentMethodName: string;
        requiredRequestData: any[];
      }[];
    }[];
    shipmentMethods: IShipmentMethod[];
  };
}

export interface ICheckoutInput {
  type: string;
  id?: string;
  attributes: {
    orderReference?: string;
    redirectUrl?: string | null;
    isExternalRedirect?: string;
    idCart?: string;
    isAddressSavingSkipped?: boolean;
    taxNumber?: string;
    stateDisplayName?: string;
    pointOfContact?: IPointOfContact;
    approverDetails?: IapproverDetails;
    customer: ICustomerCheckout,
    billingAddress?: IAddress;
    shippingAddress?: IAddress;
    soldToAddress?: IAddress;
    payerAddress?: IAddress;
    payments: IPaymentsData[];
    shipment: {
      idShipmentMethod: number;
    },
    termsAccessTime: Date,
    consentedTerms: string[];
    invoiceEmailCopy?: string;
    sapPoNumber?: string;
    orderEmailCopy?: string;
  };
}

export interface ICustomerCheckout {
  attentionTo?: string;
  email: string;
  salutation: string;
  firstName: string;
  idCustomer?: number;
  customerReference: string;
  idCompanyBusinessUnit?: string;
  uuidCompanyUser: string;
  lastName: string;
}

export interface ICheckout {
  type: string;
  id?: string;
  attributes: {
    orderReference?: string;
    redirectUrl?: string | null;
    isExternalRedirect?: string;
    idCart?: string;
    isAddressSavingSkipped?: boolean;
    taxNumber?: string;
    stateDisplayName?: string;
    pointOfContact?: IPointOfContact;
    approverDetails?: IapproverDetails;
    customer: ICustomerCheckout;
    billingAddress: IAddress;
    shippingAddress: IAddress;
    soldToAddress?: IAddress;
    payerAddress?: IAddress;
    payments: IPaymentsData[];
    shipment: {
      idShipmentMethod: number;
    }
    termsAccessTime: Date;
    consentedTerms: string[];
    invoiceEmailCopy?: string;
  };
}

export interface IWrongAddress {
  type: string;
  attributes: {
    addressUuid: string;
    comment: string;
  };
}

export interface IAddressData {
  id: string;
  name: string;
  value: IAddress;
}

export interface ICheckoutUpdate {
  type: string;
  attributes: {
    idCart: string;
    isAddressSavingSkipped: boolean;
    systemDetails?: ISystemDetails;
    systemDetailsPerItem?: ISystemDetailsPerItem[];
    priceDisputingPerItem?: IPriceDisputingPerItem[];
    pointOfContact?: IPointOfContact;
    approverDetails?: IapproverDetails;
    materialMasterNumbers?: IMaterialMasterNumbersPerItem[];
    customer?: {
      email: string;
      salutation: string;
      firstName: string;
      idCustomer: number;
      customerReference: string;
      uuidCompanyUser: string;
      lastName: string;
    },
    billingAddress?: {
      salutation: string;
      firstName: string;
      lastName: string;
      address1: string;
      address2: string;
      address3?: string;
      zipCode: string;
      city: string;
      iso2Code: string;
      company: string;
      phone: string;
      state?: string;
      isDefaultBilling: boolean;
      isDefaultShipping: boolean;
    },
    shippingAddress?: {
      salutation: string;
      firstName: string;
      lastName: string;
      address1: string;
      address2: string;
      address3?: string;
      zipCode: string;
      city: string;
      country: string;
      iso2Code: string;
      company: string | null;
      phone: string | null;
      state?: string;
      isDefaultBilling: boolean;
      isDefaultShipping: boolean;
    },
    payments?: IPaymentsData[];
    shipment?: {
      idShipmentMethod: number;
    }
  };
}

export interface IapproverDetails {
  approverId: string;
  firstName?: string;
  lastName?: string;
  comment?: string;
  dueDate?: string;
}

export interface IPaymentsData {
  paymentMethodName: IPaymentMethodName;
  paymentProviderName: IPaymentProviderName;
  paymentSelection?: IPaymentSelection;
}

export enum IPaymentMethodName {
  INVOICE  = 'Pay by invoice',
  CONTRACT = 'contract',
  DUMMY    = 'invoice'
}

export enum IPaymentProviderName {
  INVOICE  = 'InvoicePayment',
  CONTRACT = 'ContractPayment',
  DUMMY    = 'DummyPayment'
}

export enum IPaymentSelection {
  INVOICE = 'payByInvoicePurchaseOrder'
}

export interface IApproverData {
  approverId: string;
  firstName: string;
  lastName: string;
  comment: string;
  dueDate: string;
  poNumber?: string;
}

export interface IAddressesDataIncluded {
  attributes: IAddress;
  id: string;
  links: {
    self: string;
  };
  type: string;
}

export interface ICustomAddressData {
  attributes: IAddress;
}

export interface IShipmentMethod {
  id: number;
  name: string;
  carrierName: string;
  price: number;
  taxRate: number;
  deliveryTime: number;
  currencyIsoCode: string;
  value?: number;
}
