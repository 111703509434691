export class StringUtils {
  static toLowerCaseWithoutWhiteSpaceOrSlash(text: string, replaceString: string): string {
    return text.toLowerCase().replace(/\s|\/|&/g, replaceString);
  }

  static capitalize(text: string): string {
    if (typeof text !== 'string') {
      return '';
    }
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

  static removeTags(str: string): string {
    if (str) {
      return str.replace(/<[^>]*>/ig, ''); //NOSONAR
    }
    return '';
  }

  static replaceUnderscores(text: string, replaceString: string): string {
    if (text) {
      return text.replace(/_/g, replaceString);
    }
    return '';
  }

  static substringFromChar(str: string, char: string): string {
    if (!str) {
      return '';
    }
    const indexOfChar = str.indexOf(char);
    return str.substr(0, indexOfChar > -1 ? indexOfChar : str.length);
  }

  static updatePathname(str: string): string {
    if (!str) {
      return '';
    }
    const indexOfChar = str.lastIndexOf('/');
    return indexOfChar > 0 ? str : `${str}/`;
  }
}
