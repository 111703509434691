import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { IOrderById, IOrderDataAttributes } from '../../models/order.models';
import { OrdersFacade } from '../../facades/orders.facade';
import { ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ShopCartActions } from '../../actions';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { AnalyticsService } from '../../analytics/analytics.service';
import { EFeatureToggles } from '../../configurations/common';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { OrdersUtils } from '../../utils/orders.utils';

@Component({
  selector: 'app-order-thank-you-page',
  templateUrl: './order-thank-you-page.component.html',
  styleUrls: ['./order-thank-you-page.component.scss'],
})
export class OrderThankYouPageComponent implements OnInit {
  order: any = {
    orderNumber: '',
  };
  orderedItemsAccordion: boolean = false;
  questionEmail: string = '';
  isEligibleForReturn: boolean = false;
  isReturnableDocumentEmailLinkEnable: boolean = false;
  orderData: IOrderDataAttributes = {
    sapDetails: undefined,
    approverDetails: undefined,
    calculatedDiscounts: [],
    contractNumber: '',
    createdAt: '',
    currencyIsoCode: '',
    customer: undefined,
    expenses: [],
    items: [],
    itemsTotalNumber: 0,
    sapMessages: [],
    payments: [],
    sapPoNumber: '',
    pointOfContact: undefined,
    priceMode: '',
    sapId: 0,
    shipments: [],
    stateComment: '',
    stateDisplayName: '',
    taxNumber: '',
    totals: undefined,
    trackingNumber: 0,
  };
  returnDocumentUrl: string = '';
  sparePartsReturnDocumentUrlKey: string = environment.sparePartsReturnDocumentUrlKey;
  isProductDiscontinuedStatusEnabled$: Observable<boolean> = new Observable<boolean>();

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private translate: TranslateService,
    private ordersFacade: OrdersFacade,
    private store: Store<State>,
    private translateService: TranslateService,
    private analyticsService: AnalyticsService,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.isProductDiscontinuedStatusEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_DISCONTINUED_STATUS);

    this.subscribeToRouteParams();
    this.getQuestionEmail();


    this.ordersFacade.getOrderById(this.order.orderNumber)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((orderResponseWithMessages: IOrderById) => {
        this.orderData = orderResponseWithMessages.data?.attributes;
        this.isEligibleForReturn = OrdersUtils.isSapOrderEligibleForReturn(this.orderData);
        this.analyticsService.setProducts(this.orderData.items);
        this.analyticsService.trackPageReady('Order Thank You', PageTypes.ORDER_THANK_YOU_PAGE, 'thank-you-page');
      });
    this.store.dispatch(ShopCartActions.updateCartDataSuccess(this.order.orderNumber));
    this.configurationFacade.isFeatureEnabled(
      EFeatureToggles.RETURNABLE_DOCUMENT_EMAIL_LINK_FROM_PURCHASE_ACTIVITY_PAGE,
    ).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.isReturnableDocumentEmailLinkEnable = value;
    });
    this._getReturnDocumentUrl();
  }

  toggleOrderedItemsAccordion(): void {
    this.orderedItemsAccordion = !this.orderedItemsAccordion;
  }

  downloadDocumentation(): void {
    window.open(this.returnDocumentUrl, '_blank');
  }

  getTotalItemTranslationValue(itemsCount: number): string {
    const value = itemsCount <= 1 ? 'singular' : 'plural';
    return `order-thank-you-page.total-item-number-${value}`;
  }

  private _getReturnDocumentUrl(): void {
    this.translateService.get(this.sparePartsReturnDocumentUrlKey).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.returnDocumentUrl = value;
    });
  }

  private subscribeToRouteParams(): void {
    this.route.params.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(params => {
      if (params.orderId) {
        this.order.orderNumber = params.orderId;
        this.ordersFacade.recallAndClearOrdersWithDetailsDataList();
      }
    });
  }

  private getQuestionEmail(): void {
    this.translate.get('order-thank-you-page.question.email').pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.questionEmail = value;
    });
  }
}
