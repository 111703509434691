import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, take } from 'rxjs/operators';

import { CustomerActions } from '../actions';
import { CustomerFacade } from '../facades/customer.facade';
import { EGlueResource, EStoreFeatures } from '../configurations/common';
import { EAddressType } from '../configurations/cpq';
import { ConfigurationFacade } from '../facades/configuration.facade';
import { CpqFacade } from '../facades/cpq.facade';
import { SoldToAccountsFacade } from '../facades/sold-to.facade';
import { LocalStorageUtils } from '../utils/localStorage.utils';

@Injectable()
export class CustomerEffects {
  constructor(
    private actions$: Actions,
    private customerFacade: CustomerFacade,
    private configurationFacade: ConfigurationFacade,
    private cpqFacade: CpqFacade,
    private soldToAccountsFacade: SoldToAccountsFacade,
  ) {
  }

  initCustomerAddressesAfterLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.CompanyUsersActionSuccess),
      mergeMap(() => {
        this.customerFacade.beginGetCustomerAddressesAction();
        if (this.configurationFacade.isFeatureAvailable(EStoreFeatures.MY_PROFILE)) {
          return this.customerFacade.isBusinessPartner()
            .pipe(
              take(1),
              concatMap(isBp => {
                if (isBp) {
                  return [
                    CustomerActions.loadCustomerSoldToAddresses(),
                  ];
                } else {

                  const lang: string = LocalStorageUtils.getKeyValue('lang');
                  const langData = LocalStorageUtils.getKeyValue(`lang-data-${lang}`);

                  if (langData !== '') {
                    return [
                      CustomerActions.loadCustomerShipToAddresses(),
                    ];
                  } else return [];
                }
              }),
            );
        } else {
          return [];
        }
      }),
    );
  });

  loadCustomerSoldToAddresses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.loadCustomerSoldToAddresses),
      mergeMap(() => this.soldToAccountsFacade.getSoldToAccounts([EGlueResource.SHIP_TO_ADDRESSES])
        .pipe(
          take(1),
          map(response => CustomerActions.loadCustomerSoldToAddressesSuccess({payload: response})),
          catchError(error => of(CustomerActions.loadCustomerSoldToAddressesError(error))),
        ),
      ),
    );
  });

  loadCustomerShipToAddresses$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.loadCustomerShipToAddresses),
      mergeMap(() => this.cpqFacade.getCpqWrongAddress(EAddressType.ShipTo)
        .pipe(
          take(1),
          map(response => CustomerActions.loadCustomerShipToAddressesSuccess({payload: response})),
          catchError(error => of(CustomerActions.loadCustomerShipToAddressesError(error))),
        )),
    );
  });

  getBusinessUnits$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.startGetBusinessUnitAction),
      mergeMap(
        () => this.customerFacade.getBusinessUnits()
          .pipe(
            map(data => {
              return CustomerActions.successGetBusinessUnitAction({payload: data});
            }),
            catchError(error => of(CustomerActions.errorGetBusinessUnitAction(error)),
            ),
          ),
      ),
    );
  });

  getCustomerAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.beginGetCustomerAddressAction),
      mergeMap(
        (action) => this.customerFacade.getCustomerAddresses(JSON.stringify(action.payload))
          .pipe(
            map(data => {
              return CustomerActions.successGetCustomerAddressAction({payload: data});
            }),
            catchError(error => of(CustomerActions.errorGetCustomerAddressAction(error)),
            ),
          ),
      ),
    );
  });

  addCustomerAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.beginAddCustomerAddressAction),
      mergeMap(
        (action) => this.customerFacade.addCustomerAddress(action.customerId, action.data)
          .pipe(
            map(response => {
              return CustomerActions.successAddCustomerAddressAction({data: response});
            }),
            catchError(error => of(CustomerActions.errorAddCustomerAddressAction(error)),
            ),
          ),
      ),
    );
  });

  updateCustomerAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.beginUpdateCustomerAddressAction),
      mergeMap(
        (action) => this.customerFacade.updateCustomerAddress(action.customerId, action.addressId, action.data)
          .pipe(
            map(response => {
              return CustomerActions.successUpdateCustomerAddressAction({data: response});
            }),
            catchError(error => of(CustomerActions.errorUpdateCustomerAddressAction(error)),
            ),
          ),
      ),
    );
  });

  deleteCustomerAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.beginDeleteCustomerAddressAction),
      mergeMap((action) => {
        return this.customerFacade.deleteCustomerAddress(action.customerId, action.addressId).pipe(
          map(() => {
            if (action.isPreferredShipToAddress) {
              return CustomerActions.beginDeletedPreferredShipToAddressRemovalAction({
                customerId: action.customerId,
                addressId: action.addressId,
              });
            } else {
              return CustomerActions.successDeleteCustomerAddressAction({
                customerId: action.customerId,
                addressId: action.addressId,
              });
            }
          }),
          catchError(error => of(CustomerActions.errorDeleteCustomerAddressAction(error))),
        );
      }),
    );
  });

  removeDeletedPreferredShipToAddress$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CustomerActions.beginDeletedPreferredShipToAddressRemovalAction),
      mergeMap(
        (action) => {
          const data = {
            data: {
              type: 'customer-preferences',
              attributes: {
                preferredShipToId: '',
              },
            },
          };

          return this.customerFacade.saveCustomerSettingPreferences(action.customerId, data).pipe(
            map(response => {
              return CustomerActions.successDeletedPreferredShipToAddressRemovalAction({
                customerId: action.customerId,
                addressId: action.addressId,
                data: response,
              });
            }),
            catchError(error => of(CustomerActions.errorDeletedPreferredShipToAddressRemovalAction(error))),
          );
        },
      ),
    );
  });
}
