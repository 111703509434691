<div class="container order-approve">
  <ng-container *ngIf="checkAllLoaded() || !removingItemInProgress">
    <form [formGroup]="approveOrderForm" (keydown.enter)="$event.preventDefault()">
      <div class="row">
        <div class="col-12 col-lg-8">
          <div class="row">
            <div class="col-sm-12 col-lg-1 hidden-sm"></div>
            <div class="col-12 col-lg-10">
              <h3 class="checkout-label" [attr.data-test]="'title-orderApprove'">
                {{ 'order-approve.title' | translate }}
              </h3>
              <h6 [attr.data-test]="'title-selectAddress'">
                {{ 'order-approve.select-address-title' | translate }}
              </h6>
              <div class="row">
                <div class="col-12 col-md-10" style="margin-top: 1rem">
                  <div class="form-group">
                    <app-select-box
                        class=""
                        [form]="approveOrderForm"
                        [cssStyles]="{wrapper: 'selectbox-delivery-address'}"
                        [options]="addresses"
                        [inputName]="'address'"
                        label="{{ 'order-approve.invoice-address' | translate }}"
                        [isRequired]="true"
                        [modelValue]="approveOrderForm.value.address"
                        (selected)="setAddress($event)">
                    </app-select-box>
                  </div>
                </div>
              </div>
              <h6 [attr.data-test]="'title-invoice'">{{ 'order-approve.digital-invoice-will-sent-to' | translate }}</h6>
              <div class="row">
                <div class="col-12 col-md-10">
                  <p [attr.data-test]="'desc-invoice'">{{ userData.email }}</p>
                  <span [attr.data-test]="'label-invoiceCopy'">
                    {{ 'order-approve.send-copy-invoice-to' | translate }}
                  </span>
                  <div class="form-group">
                    <app-textfield
                        [form]="approveOrderForm"
                        [inputName]="'invoiceEmailCopy'"
                        label="{{ 'order-approve.enter-email-optional' | translate }}"
                        [modelValue]="approveOrderForm.value.invoiceEmailCopy">
                    </app-textfield>
                    <div class="checkout__alerts">
                      <ng-container *ngIf="approveOrderForm.get('invoiceEmailCopy').hasError('email')">
                        <small class="textfield__hint" [attr.data-test]="'hint-emailPattern'">
                          {{ 'request-details.email-pattern' | translate }}
                        </small>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <ng-container *ngIf="!isExcludeTaxActive()">
                <h6 [attr.data-test]="'title-taxNumber'">{{ 'order-approve.billing-tax-number-title-ordered' | translate }}</h6>
                <div class="row">
                  <div class="col-12 col-md-10" style="margin-top: 1rem">
                    <div class="form-group">
                      <app-textfield
                          class=""
                          [form]="approveOrderForm"
                          [cssStyles]=""
                          [inputName]="'taxNumber'"
                          label="{{ 'order-approve.billing-tax-number-type' | translate }}"
                          [isRequired]="false"
                          [modelValue]="approveOrderForm.value.taxNumber"
                          (fieldFilled)="setOrderFormValue($event)">
                      </app-textfield>
                      <div class="checkout__alerts">
                        <ng-container *ngIf="approveOrderForm.get('taxNumber').touched &&
                                   approveOrderForm.get('taxNumber').hasError('maxlength')">
                          <small class="textfield__hint"
                                 [attr.data-test]="'hint-modalMaxCharacters'">
                            {{ 'checkout-delivery-details-modal.max-characters' | translate: {charNumber: 30} }}
                          </small>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <h6 [attr.data-test]="'title-paymentMethod'">{{ 'order-approve.select-payment-title' | translate }}</h6>
              <div class="row">
                <div class="col-10">
                  <br/>
                  <p>
                    <input id="radio-1" type="radio" name="paymentMethod" class="radio form-control"
                           checked [attr.data-test]="'input-paymentMethod'">
                    <label for="radio-1" [attr.data-test]="'label-paymentMethod'">
                      {{ 'order-approve.payment-method-invoice' | translate }}
                    </label>
                  </p>
                </div>
              </div>
              <div class="row" *ngIf="!isJpStore">
                <ng-container *ngIf="!voucherButtonIsClicked">
                  <div class="col-12">
                    <button class="button button button-add-code" type="optional"
                            (click)="clickPromotionalButton()" style="margin-top: 1rem"
                            [attr.data-test]="'button-addCode'">
                      {{ 'order-approve.add-promotional-code' | translate }}
                    </button>
                  </div>
                </ng-container>
                <ng-container *ngIf="voucherButtonIsClicked">
                  <div class="col-12">
                    <label [attr.data-test]="'label-addVoucher'">
                      <strong>{{ 'order-approve.insert-voucher' | translate }}</strong>
                    </label>
                  </div>
                  <div class="checkout__field col-12 col-md-6">
                    <app-textfield
                        class="checkout__textfield"
                        [form]="approveOrderForm"
                        [modelValue]="approveOrderForm.value.promotionCode"
                        [markInvalid]="voucherHasError && !voucherListLoaded"
                        [cssStyles]="{wrapper: 'textfield-with-prefix textfield-promotionCode'}"
                        [inputName]="'promotionCode'"
                        label="{{ 'order-approve.code' | translate }}"
                        [readOnly]="voucherInProgress"
                        (fieldFilled)="resetVoucherField()"
                        (keydown.enter)="!isVoucherBtnDisabled() && addVoucher()">
                    </app-textfield>
                    <div class="checkout__alerts">
                      <ng-container
                          *ngIf="approveOrderForm.get('promotionCode').touched &&
                                  (this.voucherHasError && !this.voucherListLoaded)">
                        <small class="textfield__hint" [attr.data-test]="'hint-invalidCode'">
                          {{ 'order-approve.invalid-code' | translate }}
                        </small>
                      </ng-container>
                    </div>
                  </div>
                  <div class="col-3">
                    <button
                        type="button"
                        class="button button add-voucher__btn"
                        [ngClass]="{'in-progress': voucherInProgress}"
                        (click)="addVoucher()"
                        [disabled]="isVoucherBtnDisabled()"
                        [attr.data-test]="'button-applyVoucher'">
                      <ng-container *ngIf="voucherInProgress; else applyMsg">
                        <span class="loading-animation"></span>
                      </ng-container>
                      <ng-template #applyMsg>
                        <span>{{ 'order-approve.apply-voucher' | translate }}</span>
                      </ng-template>
                    </button>
                  </div>
                </ng-container>
              </div>
              <h6 [attr.data-test]="'title-reviewDetails'">{{ 'order-approve.review-delivery-addresses-title' | translate }}</h6>
              <ng-container *ngIf="selectedDeliveryAddressLoaded">
                <div class="address col-12">
                  <div class="row">
                    <div class="address-wrapper col-12">
                      <div class="address-details">
                        <div *ngIf="selectedDeliveryAddress.firstName !== 'empty'">
                          <label [attr.data-test]="'label-name'">
                            {{ selectedDeliveryAddress.firstName }}
                          </label>
                        </div>
                        <div>
                          <label [attr.data-test]="'label-address1'">
                            {{ selectedDeliveryAddress.address1 }}
                          </label>
                        </div>
                        <div>
                          <label [attr.data-test]="'label-address2'">
                            {{ (selectedDeliveryAddress.zipCode === 'empty' ? '' : selectedDeliveryAddress.zipCode) + ' '
                          + (selectedDeliveryAddress.city === 'empty' ? '' : selectedDeliveryAddress.city)
                          + (selectedDeliveryAddress.address2 === 'empty' ? '' : ', ' + selectedDeliveryAddress.address2) }}
                          </label>
                        </div>
                        <div>
                          <label [attr.data-test]="'label-country'">
                            {{ selectedDeliveryAddress.country === 'empty' ? '' : selectedDeliveryAddress.country }}
                          </label>
                        </div>
                      </div>
                      <div class="address-wrapper--modal-buttons">
                        <div (click)="showModalToContactPoint()" data-target="#modal1"
                             [attr.data-test]="'link-reviewDelivery'">
                          <label class="change-poc-label">
                            {{ 'order-approve.review-delivery-instructions' | translate }}
                            <span class="icon-arrow-right"></span>
                          </label>
                        </div>
                        <div (click)="showModalToChangeAddress()" data-target="#modal2"
                             [attr.data-test]="'link-changeAddress'">
                          <label class="change-address-label">
                            {{ 'order-approve.change-address' | translate }}
                            <span class="icon-arrow-right"></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" style="background: white;">
                    <div class="col-12">
                      <ng-container *ngIf="cartLoaded">
                        <ng-container *ngIf="!isExcludeTaxActive(); else excludedTax">
                          <div class="row" *ngFor="let item of itemsInCart; let i = index"
                               style="border: 1px solid #E6E6E6;"
                               [attr.data-test]="'desc-item-' + getItemName(item.relationships)">
                            <div class="col-12 col-md-3"
                                 style="padding-top: 0.9rem;  padding-bottom: 2rem;">
                              <div class="aspect-ratio-1x1 aspect-ratio--cover">
                                <img [src]="getItemPicture(item.relationships)"
                                     [alt]="getItemPicture(item.relationships)">
                              </div>
                            </div>
                            <div class="col-12 col-md-3" style="padding-top: 0.9rem;">
                              <div class="row">
                                <label class="cart-title" [attr.data-test]="'label-itemName'">
                                  <strong>{{ getItemName(item.relationships) }}</strong>
                                </label>
                              </div>
                              <div class="row">
                                <label class="price-1" [attr.data-test]="'label-itemPrice'">
                                  {{ getItemPrice(item.id) | currency: cartCurrency}}
                                </label>
                              </div>
                              <div class="row">
                                <div>
                                  <label class="items-quantity" [attr.data-test]="'label-itemQuantity'">
                                    <ng-container *ngIf="item.attributes.quantity > 1">
                                      {{ 'orders-page.item-plural' | translate:{number: item.attributes.quantity} }}
                                    </ng-container>
                                    <ng-container *ngIf="item.attributes.quantity === 1">
                                      {{ 'orders-page.item-singular' | translate:{number: item.attributes.quantity} }}
                                    </ng-container>
                                  </label>
                                </div>
                              </div>
                              <div class="row">
                                <button
                                    (click)="removeItemFromCartModal(getItemName(item.relationships), getItemSku(item.relationships))"
                                    [attr.data-test]="'button-removeItem'">
                                  <span class="remove-item__desc">
                                    <span class="icon-close"></span>
                                    {{ 'order-approve.remove-item' | translate }}
                                  </span>
                                </button>
                              </div>
                            </div>
                            <div class="col-12 col-md-2 justify-content-end" style="padding-top: 0.9rem;"></div>
                            <div class="col-12 col-md-4 justify-content-end" style="padding-top: 0.9rem;">
                              <div class="row">
                                <p class="delivery-type">
                                  <input id="{{ 'method-'+(i) }}" type="radio"
                                         name="{{ 'delivery-method-'+(i) }}"
                                         class="radio form-control" checked
                                         [attr.data-test]="'input-deliveryMethod'">
                                  <label for="{{ 'method-'+(i) }}"
                                         style="text-align: left;font: normal normal normal 16px/30px Siemens Sans, sans-serif;letter-spacing: 0;color: #1A1A1A;"
                                         [attr.data-test]="'label-deliveryMethod'">
                                    {{ 'order-approve.normal-delivery' | translate }}
                                  </label>
                                  <span class="free-of-charge">
                                      <label [attr.data-test]="'label-freeOfCharge'">
                                        {{ 'order-approve.free-of-charge' | translate }}
                                      </label>
                                  </span>
                                  <label *ngIf="item.attributes.deliveryTime"
                                         style="padding-left: 2rem; text-align: left; font: normal normal normal 14px/30px Siemens Sans, sans-serif;letter-spacing: 0;color: #666666;"
                                         [attr.data-test]="'label-deliveryTime'">
                                    {{ 'shop-cart.estimatedArrivalLabel' | translate}}{{ item.attributes.deliveryTime }}
                                  </label>
                                </p>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                        <ng-template #excludedTax>
                          <div class="row" *ngFor="let item of itemsInCart; let i = index"
                               style="border: 1px solid #E6E6E6;"
                               [attr.data-test]="'desc-item-' + getItemName(item.relationships)">
                            <div class="col-12 col-md-3"
                                 style="padding-top: 0.9rem;  padding-bottom: 2rem;">
                              <div class="aspect-ratio-1x1 aspect-ratio--cover">
                                <img [src]="getItemPicture(item.relationships)"
                                     [alt]="getItemPicture(item.relationships)">
                              </div>
                            </div>
                            <div class="col-12 col-md-9" style="padding-top: 0.9rem;">
                              <div class="row">
                                <label class="cart-title" [attr.data-test]="'label-itemName'">
                                  <strong>{{ getItemName(item.relationships) }}</strong>
                                </label>
                              </div>
                              <div class="row item-details-container">
                                <label class="price-1">{{ 'order-approve.item-price' | translate }}:</label>
                                <label class="price-1" [attr.data-test]="'label-itemPrice'">
                                  {{ getItemPrice(item.id) | currency: '' }}
                                </label>
                              </div>
                              <div class="row item-details-container">
                                <label class="items-quantity">{{ 'order-approve.quantity' | translate }}:</label>
                                <label class="items-quantity" [attr.data-test]="'label-itemQuantity'">
                                  {{ item.attributes.quantity }}
                                </label>
                              </div>
                              <div class="row">
                                <button
                                    (click)="removeItemFromCartModal(getItemName(item.relationships), getItemSku(item.relationships))"
                                    [attr.data-test]="'button-removeItem'">
                                  <span class="remove-item__desc">
                                    <span class="icon-close"></span>
                                    {{ 'order-approve.remove-item' | translate }}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
        <div class="container order-summary col-12 col-lg-4">
          <div class="row">
            <div class="col-12">
              <label style="margin-top: 3rem; float: left"
                     [attr.data-test]="'label-orderSummary'">
                {{ 'order-approve.place-order-title' | translate}}
              </label>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button
                  appDebounceClick
                  class="button button--primary button-sent-approval text-align-center"
                  type="submit"
                  [disabled]="!formIsValid() || actionInProgress"
                  [debounceTime]="300"
                  (debounceClick)="placeOrder()"
                  [attr.data-test]="'button-placeOrder'">
                {{ 'order-approve.place-order-button' | translate }}
              </button>
            </div>
          </div>
          <div class="row checkbox-container-row">
            <div class="col-12" [formGroup]="approveOrderForm">
              <input
                  type="checkbox"
                  id="terms-and-conditions-consent"
                  name="terms-and-conditions-consent"
                  class="checkbox"
                  [required]="true"
                  formControlName="termsAndConditionsConsent"
                  [attr.data-test]="'checkbox-termsAndConditions'">
              <label for="terms-and-conditions-consent" [attr.data-test]="'label-termsAndConditions'">
                <a *ngIf="this.isJpStore;then jpStoreTerms; else nonJpStoreTerms">
                </a>
                <ng-template #jpStoreTerms>
                  <a *ngIf="termsAndConditionsUrl.type === 'internal'"
                     [routerLink]="termsAndConditionsUrl.value"
                     target="_blank">
                    {{ "order-approve.terms-and-conditions-link-title" | translate }}
                  </a>
                  <a *ngIf="termsAndConditionsUrl.type === 'external'"
                     [href]="termsAndConditionsUrl.value"
                     target="_blank">
                    {{ "order-approve.terms-and-conditions-link-title" | translate }}
                  </a>
                  {{ "order-approve.terms-and-conditions-legal-consent" | translate }}
                </ng-template>
                <ng-template #nonJpStoreTerms>
                  {{ "order-approve.terms-and-conditions-legal-consent" | translate }}
                  <a *ngIf="termsAndConditionsUrl.type === 'internal'"
                     [routerLink]="termsAndConditionsUrl.value"
                     target="_blank">
                    {{ "order-approve.terms-and-conditions-link-title" | translate }}
                  </a>
                  <a *ngIf="termsAndConditionsUrl.type === 'external'"
                     [href]="termsAndConditionsUrl.value"
                     target="_blank">
                    {{ "order-approve.terms-and-conditions-link-title" | translate }}
                  </a>
                </ng-template>
              </label>
            </div>
          </div>
          <div class="row checkbox-container-row">
            <div class="col-12" [formGroup]="approveOrderForm">
              <input
                  type="checkbox"
                  id="privacy-policy-consent"
                  name="privacy-policy-consent"
                  class="checkbox"
                  [required]="true"
                  formControlName="privacyPolicyConsent"
                  [attr.data-test]="'checkbox-privacyPolicyConsent'">
              <label for="privacy-policy-consent" [attr.data-test]="'label-privacyPolicyConsent'">
                <a *ngIf="this.isJpStore;then jpStorePrivacyPolicy; else nonJpStorePrivacyPolicy">
                </a>
                <ng-template #jpStorePrivacyPolicy>
                  <a *ngIf="privacyPolicyUrl.type === 'internal'"
                     [routerLink]="privacyPolicyUrl.value"
                     target="_blank">
                    {{ "order-approve.privacy-policy-link-title" | translate }}
                  </a>
                  <a *ngIf="privacyPolicyUrl.type === 'external'"
                     [href]="privacyPolicyUrl.value"
                     target="_blank">
                    {{ "order-approve.privacy-policy-link-title" | translate }}
                  </a>
                  {{ "order-approve.privacy-policy-legal-consent" | translate }}
                </ng-template>
                <ng-template #nonJpStorePrivacyPolicy>
                  {{ "order-approve.privacy-policy-legal-consent" | translate }}
                  <a *ngIf="privacyPolicyUrl.type === 'internal'"
                     [routerLink]="privacyPolicyUrl.value"
                     target="_blank">
                    {{ "order-approve.privacy-policy-link-title" | translate }}
                  </a>
                  <a *ngIf="privacyPolicyUrl.type === 'external'"
                     [href]="privacyPolicyUrl.value"
                     target="_blank">
                    {{ "order-approve.privacy-policy-link-title" | translate }}
                  </a>
                </ng-template>
              </label>
            </div>
          </div>
          <hr class="right-pane-hr" style="margin-top: 2rem"/>
          <div class="row">
            <div class="col-12" *ngIf="cartTotals && !isExcludeTaxActive(); else cartTotalsExcludeTax">
              <h6 [attr.data-test]="'title-orderTotal'" style="text-align: left">
                <strong>{{ 'order-approve.order-total' | translate }}</strong>
              </h6>
              <p style="float: left">
                <span [attr.data-test]="'desc-priceInfo'">
                  {{ 'checkout-delivery-details.price-info' | translate }}
                </span>
                <br/>
                <span [attr.data-test]="'desc-shipping'">
                  {{ 'checkout-delivery-details.free-shipping' | translate }}
                </span>
              </p>
              <p class="price" [attr.data-test]="'desc-totalPrice'">
                {{ getTotalPrice(cartTotals) | currency: cartCurrency }}
              </p>
            </div>
            <ng-template #cartTotalsExcludeTax>
              <div class="totals-container">
                <p class="totals-labels">
                  {{ 'order-approve.sub_total' | translate }}<br>
                  {{ 'order-approve.shipping' | translate }}<br>
                  {{ 'order-approve.gst' | translate: {tax: getTaxPercent()} }}
                </p>
                <p class="totals-prices">
                  {{ getSubTotal() | currency:cartCurrency }}<br>
                  {{ getShipmentTotal() | currency:cartCurrency }}<br>
                  {{ getTaxTotal() | currency:cartCurrency }}
                </p>
              </div>
              <hr class="totals-line-separator">
              <div class="totals-container order-total">
                <p class="totals-labels">
                  <span class="totals-total-value">{{ 'order-approve.total' | translate }}</span>
                  <span class="totals-include-gst"> ({{ 'order-approve.include_gst' | translate }})</span>
                </p>
                <p class="totals-prices">
                  <span class="totals-total-value">{{ getGrandTotal() | currency:cartCurrency }}</span>
                </p>
              </div>
              <div class="shipment-method-container">
                <p>
                  {{ selectedShipmentMethod?.name }}
                </p>
              </div>
            </ng-template>
          </div>
          <div class="row" *ngIf="showAdditionalInfoForVoucher">
            <div class="col-12" *ngFor="let voucher of voucherList">
              <label style="float: left">
                <span
                    class="icon-close remove-voucher_btn"
                    title="{{ 'order-approve.remove-voucher' | translate }}"
                    (click)="removeVoucher(voucher.id)"
                    *ngIf="!isJpStore"></span>
                {{ getVoucherTitle(voucher.id)}}{{ 'order-approve.promotional-code' | translate }}
              </label>
              <label class="price-2">-{{ getVoucherAmount(voucher.id) | currency:cartCurrency }}</label>
            </div>
            <div class="col-12">
              <hr class="bottom-line">
              <label class="price"><strong>{{ getPriceAfterVoucher() | currency:cartCurrency }}</strong></label>
            </div>
          </div>
          <div class="row" *ngIf="cartRules?.length > 0 && !showAdditionalInfoForVoucher">
            <div class="col-12">
              <app-cart-rules
                  [cartRules]="cartRules"
                  [priceToPay]="cartTotals.priceToPay"
                  [currency]="cartCurrency">
              </app-cart-rules>
            </div>
          </div>
          <div class="row">
            <div class="col-12" *ngIf="cartTotals && !isExcludeTaxActive()">
              <p>
                <strong>
                  <span [attr.data-test]="'label-paymentCondition'">
                    {{ 'order-approve.payment-conditions-title' | translate }}
                  </span>
                  <br/>
                  <span [attr.data-test]="'desc-paymentCondition'">
                    {{ 'order-approve.payment-conditions' | translate }}
                  </span>
                </strong>
              </p>
            </div>
          </div>
          <div class="row store-disclaimer-container" *ngIf="isAuStore">
            <div class="col-12">
              <p class="disclaimer">
                {{ 'order-approve.disclaimer' | translate }}
              </p>
            </div>
          </div>
          <hr class="right-pane-hr">
          <div class="col-12">
            <span class="back-to-cart" (click)="backToOrders()" [attr.data-test]="'link-backToCart'">
            <span class="icon-arrow-left"></span>
              {{ 'order-approve.back-to-hospital' | translate }}
            </span>
          </div>
        </div>
      </div>
    </form>
  </ng-container>
  <div class="loader__overlay" *ngIf="!checkAllLoaded() || actionInProgress || removingItemInProgress">
    <div class="loading-animation"></div>
  </div>
</div>
<app-remove-item-from-order-modal
    [showModalConfirmRemove]="showModalConfirmRemove"
    [deleteLastItemAndDeclineOrderFlag]="deleteLastItemAndDeclineOrderFlag"
    [clickedItemDataName]="clickedItemDataName"
    (restartShowModal)="restartShowModal()"
    (removeItemFromCart)="removeItemFromCart()"
    (lastItemDeclineOrder)="lastItemDeclineOrder()"
></app-remove-item-from-order-modal>
<app-contact-point-modal
    [showModal]="showModalContactPoint"
    (reloadCheckoutData)="getCartApprovalData()"
    (closeModal)="showModalContactPoint = false">
</app-contact-point-modal>
<app-change-address-modal
    [showModal]="showModalChangeAddress"
    [previousCartId]="setCartIdForCheckoutUpdate()"
    [isOrderApprovalPage]="true"
    (closeModal)="showModalChangeAddress = false"
    (selectShippingAddress)="selectNewAddress()">
</app-change-address-modal>
<app-confirm-remove-modal
    [showModal]="showModalDeclineConfirm"
    [cartId]="cartId"
    (closeModal)="showModalDeclineConfirm = false">
</app-confirm-remove-modal>
