import { createAction, props } from '@ngrx/store';
import { ICurrentParams } from '../models/catalog.models';
import { ISimpleInstallBaseProduct } from '../models/installedbase.models';

export const loadHowManyProductShouldBeCached = createAction(
  '[Catalog] - load how many products should be cached',
);

export const loadHowManyProductShouldBeCachedSuccess = createAction(
  '[Catalog] - load how many products should be cached success',
  props<{count: number}>(),
);

export const loadHowManyProductShouldBeCachedError = createAction(
  '[Catalog] - load how many products should be cached error',
);

export const loadAbstractProductData = createAction(
  '[Catalog] - load abstract product data - start',
  props<{sku: string}>(),
);

export const loadAbstractProductDataSuccess = createAction(
  '[Catalog] - load abstract product data - success',
  props<{payload: any}>(),
);

export const loadAbstractProductDataError = createAction(
  '[Catalog] - load abstract product data - error',
  props<{error: any}>(),
);

export const loadAbstractProductInfoFromSap = createAction(
  '[Catalog] - load abstract product information from SAP - start',
  props<{sku: string, uuid: string}>(),
);

export const loadAbstractProductInfoFromSapSuccess = createAction(
  '[Catalog] - load abstract product information from SAP - success',
  props<{payload: any}>(),
);

export const loadAbstractProductInfoFromSapError = createAction(
  '[Catalog] - load abstract product information from SAP - error',
  props<{error: any}>(),
);

export const setCachedProductsToBeReloaded = createAction(
  '[Catalog] - set cached abstract products to be reloaded'
)

export const clearAbstractProductData = createAction(
  '[Catalog] - clear abstract product data',
);

export const loadCategoriesStart = createAction(
  '[Catalog] Load Categories Start',
);

export const loadCategoriesSuccess = createAction(
  '[Catalog] Load Categories Success',
  props<{payload: any}>(),
);

export const loadCategoriesFail = createAction(
  '[Catalog] Load Categories Fail',
  props<{error: any}>(),
);

export const setSearchQuery = createAction(
  '[Catalog] Search for provided query',
  props<{query: string}>(),
);

export const clearSearchQuery = createAction(
  '[Catalog] Clear search query',
);

export const setInstalledBase = createAction(
  '[Catalog] Set installed base',
  props<{installedBase: ISimpleInstallBaseProduct}>(),
);

export const setQueryParams = createAction(
  '[Catalog] Set params used on catalog',
  props<{params: ICurrentParams}>(),
);
