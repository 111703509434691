import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { formatDate } from '@angular/common';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

import { I18nService } from '../../../services';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { AppUtils } from '../../../utils/app.utils';
import { CustomerFacade } from '../../../facades/customer.facade';
import { DateUtils } from '../../../utils/date.utils';
import { DAYS_FROM_TODAY_TO_START_CONTRACT, EAddressType } from '../../../configurations/cpq';
import { CpqUtils } from '../../../utils/cpq.utils';
import {IAddress} from "../../../models/common.models";

@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss'],
})
export class ContractDetailsComponent implements OnInit, OnDestroy {
  endOfSupportDate = '';
  contractStartDate = '';
  contractDurationYears = 5;
  shipAddress = '';
  shipToAddresses: IAddress[] = [];
  cpqAddressType = EAddressType.ShipTo;
  contractEndDateOptions = [];
  translations = [];
  minimumDaysFromToday = DAYS_FROM_TODAY_TO_START_CONTRACT;
  cart: any;
  customerBusinessUnitName: string;
  ecommerceTeamEmailAddress: string;
  loadingCartDataInProgress = true;
  showWrongAddressModal = false;

  private unsubscribe$ = new Subject<void>();

  @Input() saveInProgress = false;
  @Input() showSaveDone = false;
  @Input() configId: string;
  @Output() changed = new EventEmitter<any>();
  @Output() goBack = new EventEmitter<any>();
  @Output() goToReviewSummary = new EventEmitter<any>();

  constructor(
    private i18nService: I18nService,
    private marketingFacade: MarketingFacade,
    private customerFacade: CustomerFacade
  ) {
  }

  ngOnInit(): void {
    this.marketingFacade.loadCurrentCart();
    this.selectLoadingCartDataInProgress();
    this.selectCart();
    this.getStoreEmailAddress();
    this.getUserData();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  emitStartDate(event: any): void {
    this.changed.emit([
      {parameter: event.key, value: formatDate(event.value, 'yyyy-MM-dd', 'en')},
      {parameter: 'contractDurationYears', value: this.contractDurationYears}
    ]);
  }

  private getTranslations(): void {
    if (this.translations.length > 0) {
      this.configureEndDateOptions();
      return;
    }
    this.i18nService.getTranslationByKey([
      'service-configuration.year',
      'service-configuration.years',
      'service-configuration.until',
      'service-configuration.past',
    ]).pipe(takeUntil(this.unsubscribe$))
      .subscribe(translations => {
        this.translations = translations;
        this.configureEndDateOptions();
      });
  }

  private configureEndDateOptions(): void {
    const maxContractDurationYears = DateUtils.calculateContractTerm('', this.endOfSupportDate);
    this.contractEndDateOptions = Array.from({length: 10}).map((param, index) => {
      const year = index + 1;
      const yearLabel = year === 1 ? this.translations['service-configuration.year']
        : this.translations['service-configuration.years'];
      if (!this.endOfSupportDate) {
        return {
          name: `${year} ${yearLabel}`,
          value: year,
          disabled: false,
        };
      }

      if (year < maxContractDurationYears) {
        return {
          name: `${year} ${yearLabel}`,
          value: year,
          disabled: false,
        };
      } else if (maxContractDurationYears === year) {
        return {
          name: `${year} ${yearLabel} ${this.translations['service-configuration.until']}`,
          value: year,
          disabled: false,
        };
      } else {
        return {
          name: `${year} ${yearLabel} ${this.translations['service-configuration.past']}`,
          value: year,
          disabled: true,
        };
      }
    });
  }

  selectLoadingCartDataInProgress(): void {
    this.marketingFacade.selectLoadingCartDataInProgress().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(loadingCartDataInProgress => {
        this.loadingCartDataInProgress = loadingCartDataInProgress;
      },
    );
  }

  getMultipleFunctionalLocations(cart: any): string[] {
    const functionalLocationsInArray = [];
    functionalLocationsInArray.push(cart?.attributes?.systemDetails?.siemensEquipmentId);

    return functionalLocationsInArray;
  }

  showReportWrongShipToAddressModal(): void {
    this.showWrongAddressModal = true;
  }

  private selectCart(): void {
    this.marketingFacade.selectCart().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cart => {
        this.cart = cart;
        this.selectCartItems();
      },
    );
  }

  private selectCartItems(): void {
    this.marketingFacade.selectCartItemsWithDetails().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(cartItems => {
        const currentItem = cartItems.find(item => item.attributes.cpqConfigId === this.configId);
        if (currentItem) {
          const {endOfSupport, contractDurationYears, contractStartDate} = currentItem.attributes.systemDetails;
          this.endOfSupportDate = endOfSupport ? formatDate(endOfSupport, 'dd-MMM-yyyy', 'en') : '';
          this.contractDurationYears = contractDurationYears ?? 5;
          this.contractStartDate = contractStartDate ?? '';
          this.shipAddress = CpqUtils.cpqAddressToString(currentItem.attributes.customerShipToAddress);
          this.shipToAddresses = [currentItem.attributes.customerShipToAddress] as IAddress[];
          this.getTranslations();
        }
      },
    );
  }

  private getStoreEmailAddress(): void {
    this.ecommerceTeamEmailAddress = AppUtils.getCurrentStore().ecommerceTeamEmailAddress;
  }

  private getUserData(): void {
    this.customerFacade.selectCompanyBusinessUnit()
      .pipe(take(1))
      .subscribe(businessUnit => {
        this.customerBusinessUnitName = businessUnit?.name;
      });
  }

  updateContractDurationYears($event: any): void {
    this.contractDurationYears = $event.value;
    this.changed.emit($event);
  }
}
