import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { take, takeUntil } from 'rxjs/operators';

import * as ValidationPatterns from '../../../configurations/validations';
import { CheckoutFacade } from '../../../facades/checkout.facade';
import { CustomerFacade } from '../../../facades/customer.facade';
import { CustomerCurrencyPipe } from '../../../shared/pipes/customer-currency.pipe';
import { ImageUtils } from '../../../utils/image.utils';
import {
  IAddress,
  IMessage,
  IPointOfContact,
  IPriceDisputingPerItem,
  ISelectEvent,
} from '../../../models/common.models';
import { ICartAttributes, ICartItemWithDetail, ICartRule } from '../../../models/cart.models';
import { IBaseConfig } from '../../../models/enviroment-delivery-details.model';
import { IShipmentMethod } from '../../../models/checkout.models';
import { Observable, Subject } from 'rxjs';
import { AppUtils } from '../../../utils/app.utils';
import { ICustomerInfo } from '../../../models/customer.models';
import { EFeatureToggles, EGlueResource, EMessageType, EStoreTypes } from '../../../configurations/common';
import { environment } from '../../../../environments/environment.defaults';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { TranslateService } from '@ngx-translate/core';
import { ConfigurationFacade } from '../../../facades/configuration.facade';

@Component({
  selector: 'app-spare-part-contact-details-section',
  templateUrl: 'spare-part-contact-details-section.component.html',
  styleUrls: ['spare-part-contact-details-section.component.scss'],
})
export class SparePartContactDetailsSectionComponent implements OnInit, OnDestroy, OnChanges {
  isUsStore: boolean = false;
  contactDetailsForm: UntypedFormGroup;
  idShipmentMethod: number;
  shipmentMethodsLoading: boolean = false;
  shipmentMethods: IShipmentMethod[];
  defaultShipmentMethodOptions: IBaseConfig[];
  editedShipmentMethodOptions: IBaseConfig[];
  recalculatingNewPricesInProgress: boolean = false;
  maxLengthAttentionTo: number = 35;
  maxLengthPhone: number = 20;
  maxLengthName: number = 35;
  maxLengthComment: number = 1000;
  totals: any;
  itemDetailsAdditionalFields = [] as Array<{
    name: string,
    getValFunc: (item: any) => string,
    shouldDisplayFunc: (item: any) => boolean
  }>;
  itemDisputedPricingMessage: IMessage = {
    type: EMessageType.WARNING,
    title: 'cart.disputed-pricing.title',
    description: 'cart.disputed-pricing.description',
  };
  priceDisputingPerItem: IPriceDisputingPerItem[] = [];
  isPriceDisputingSetInCart: boolean = false;
  isShipmentMethodSelectedManually: boolean = false;
  isProductDiscontinuedStatusEnabled$: Observable<boolean> = new Observable<boolean>();

  private unsubscribe$ = new Subject<void>();

  @Input() creatingOrderInProgress: boolean;
  @Input() cartId: string;
  @Input() cartAttributes: ICartAttributes;
  @Input() cartItemsWithDetails: Array<ICartItemWithDetail>;
  @Input() cartRules: Array<ICartRule>;
  @Input() isOnContactDetailsSection: boolean;
  @Input() addressChanged: boolean = false;
  @Input() isPriceDisputingActive: boolean = false;
  @Input() shipToAddress: IAddress;
  @Input() isSaturdayShipment: boolean = false;

  @Output() formSubmitted = new EventEmitter<any>();
  @Output() backToOrderDetails = new EventEmitter<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private checkoutFacade: CheckoutFacade,
    private customerFacade: CustomerFacade,
    private customerCurrencyPipe: CustomerCurrencyPipe,
    private marketingFacade: MarketingFacade,
    private translate: TranslateService,
    private configurationFacade: ConfigurationFacade,
  ) {
  }

  ngOnInit(): void {
    this.isProductDiscontinuedStatusEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_DISCONTINUED_STATUS);
    this.isUsStore = AppUtils.isStoreActive(EStoreTypes.US);
    this.totals = this.cartAttributes.totals;

    this.initializeForm();
    this.prepareItemAdditionalFields();
    this.selectPriceDisputingPerItem();
    this.selectIsPriceDisputingSetInCart();
  }

  ngOnChanges(): void {
    if (this.contactDetailsForm) {
      this.customerFacade.selectMyProfileInformation()
        .pipe(take(1))
        .subscribe(state => {
          this.preFillAttentionToDefaults(state);
        });
    }
  }

  backToOrderDetailsEmit(): void {
    this.storePointOfContact();
    this.backToOrderDetails.emit();
  }

  getProductImageUrl(item: any): string {
    return ImageUtils.getProductImageUrl(item);
  }

  isFormValid(): boolean {
    return this.contactDetailsForm.status === 'VALID';
  }

  setContactFormValue(event: ISelectEvent): void {
    this.contactDetailsForm.patchValue({
      [event.key]: event.value,
    });
  }

  checkContactFormValues(): void {
    if ((!this.isUsStore && this.contactDetailsForm.value.attentionTo != '')
      || this.contactDetailsForm.value.comment != ''
      || this.addressChanged
      || this.isPriceDisputingSetInCart
    ) {
      this.editedShipmentMethodOptions = this.defaultShipmentMethodOptions.filter(option => option.name != environment.sparePartSaturdayDeliveryKey);
    } else {
      this.editedShipmentMethodOptions = this.defaultShipmentMethodOptions;
    }
  }

  setShipmentMethod(event: ISelectEvent): void {
    this.idShipmentMethod = event.id;
    this.isShipmentMethodSelectedManually = true;
    this.isSaturdayShipment = event.name === environment.sparePartSaturdayDeliveryKey;

    if (this.contactDetailsForm.value.shipmentMethod != this.idShipmentMethod) {
      this.recalculateShipmentMethodPrice(this.idShipmentMethod);
    }

    this.contactDetailsForm.patchValue({
      [event.key]: event.value,
    });
  }

  proceedToCreateOrder(): void {
    const contactDetailsData: {pointOfContact: IPointOfContact} = this.createPointOfContactFromFormValues();
    this.formSubmitted.emit(contactDetailsData);
  }

  createPointOfContactFromFormValues(): {pointOfContact: IPointOfContact} {
    return {
      pointOfContact: {
        pointOfContactId: 'pointOfContact',
        attentionTo: this.contactDetailsForm.value.attentionTo,
        firstName: this.contactDetailsForm.value.firstName,
        lastName: this.contactDetailsForm.value.lastName,
        email: this.contactDetailsForm.value.email,
        phone: this.contactDetailsForm.value.phone,
        comment: this.contactDetailsForm.value.comment,
        shipmentMethod: this.contactDetailsForm.value.shipmentMethod,
        shipmentMethodName: this.defaultShipmentMethodOptions?.find(option => option.value === this.contactDetailsForm.value.shipmentMethod)?.name,
      },
    };
  }

  /**
   * Determine if price disputing is set for given item.
   *
   *
   * @param {string} itemId
   * @return {boolean}
   */
  isPriceDisputingSetForItem(itemId: string): boolean {
    return this.priceDisputingPerItem?.find(itemPriceDisputing => {
      return itemPriceDisputing.itemId === itemId;
    })?.priceDisputing?.isSet;
  }

  private initializeForm(): void {
    this.contactDetailsForm = this.formBuilder.group({
      attentionTo: ['', [Validators.maxLength(this.maxLengthAttentionTo)]],
      firstName: ['', [Validators.required, Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      lastName: ['', [Validators.required, Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required, Validators.maxLength(this.maxLengthPhone), ValidationPatterns.phonePattern]],
      shipmentMethod: ['', [Validators.required]],
      comment: ['', [Validators.maxLength(this.maxLengthComment), ValidationPatterns.noEmptySpaceOnTheBeginning]],
    });
    this.selectCustomerInfo();
  }

  private prepareItemAdditionalFields(): void {
    this.itemDetailsAdditionalFields = [
      {
        name: 'multi-cart.quantity',
        getValFunc: (item) => item.attributes.quantity,
        shouldDisplayFunc: (_) => true,
      },
      {
        name: 'multi-cart.price-per-unit',
        getValFunc: (item) => this.customerCurrencyPipe.transform(
          item.attributes.calculations?.unitPrice,
          this.cartAttributes?.currency,
        ),
        shouldDisplayFunc: (_) => true,
      },
      {
        name: 'multi-cart.total-price',
        getValFunc: (item) => this.customerCurrencyPipe.transform(
          item.attributes.calculations?.sumPrice,
          this.cartAttributes?.currency,
        ),
        shouldDisplayFunc: (_) => true,
      },
    ];
  }

  private selectCustomerInfo(): void {
    const data = {
      type: EGlueResource.CHECKOUT_DATA,
      attributes: {
        idCart: this.cartId,
      },
    };

    this.shipmentMethodsLoading = true;
    this.checkoutFacade.getShipmentMethodsOrRecalculatePrice({data: data})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        if (response?.data) {
          this.shipmentMethods = response.data.attributes?.shipmentMethods;
          this.defaultShipmentMethodOptions = this.shipmentMethods.map(shipmentMethod => {
            return {
              id: shipmentMethod.id,
              name: shipmentMethod.name,
              value: `${shipmentMethod.id}`,
            } as IBaseConfig;
          });
          this.editedShipmentMethodOptions = this.defaultShipmentMethodOptions;

          this.customerFacade.selectMyProfileInformation()
            .pipe(take(1))
            .subscribe(state => {
              const selectedShipmentMethod = this.resolveShipmentMethod(state);
              this.preFillFormDefaults(state, selectedShipmentMethod);
              this.recalculateShipmentMethodPrice(selectedShipmentMethod.id);
            });
        }
        this.shipmentMethodsLoading = false;
      });
  }

  private resolveShipmentMethod(customerInfo: ICustomerInfo): IBaseConfig {
    let selectedShipmentMethod: IBaseConfig;

    // first check if another shipment method has already been selected by the user
    if (customerInfo.pointOfContact) {
      selectedShipmentMethod = this.defaultShipmentMethodOptions.find(
        option => option.value === customerInfo.pointOfContact?.shipmentMethod,
      );
      if (selectedShipmentMethod) {
        return selectedShipmentMethod;
      }
    }

    // otherwise check if total weight of ordering items is over weight limit
    // for automatic preselection of "FedEx Heavy Weight Shipment" method
    if (this.isHeavyWeightShipmentApplicable()) {
      selectedShipmentMethod = this.defaultShipmentMethodOptions.find(
        option => option.name === 'FedEx Heavy Weight Shipment',
      );
      if (selectedShipmentMethod) {
        return selectedShipmentMethod;
      }
    }

    // otherwise check store for default shipment method by name in current store config
    const defaultShipmentName = AppUtils.getCurrentStore().defaultShipmentMethod.name;
    selectedShipmentMethod = this.defaultShipmentMethodOptions.find(
      option => option.name === defaultShipmentName,
    );

    if (!selectedShipmentMethod) {
      // NOTE: This should never happen unless e.g.
      // - new shipment method setup is still in progress as part of new store setup
      // - name of the default shipment method has changed on the Angular or Spryker side
      selectedShipmentMethod = {id: 0, name: '', value: ''};
    }

    return selectedShipmentMethod;
  }

  private preFillFormDefaults(
    customerInfo: ICustomerInfo,
    selectedShipmentMethod: IBaseConfig,
  ): void {
    this.contactDetailsForm.patchValue({
      firstName: customerInfo.pointOfContact?.firstName || customerInfo.firstName || '',
      lastName: customerInfo.pointOfContact?.lastName || customerInfo.lastName || '',
      email: customerInfo.pointOfContact?.email || customerInfo.email || '',
      phone: customerInfo.pointOfContact?.phone || customerInfo.phone || '',
      attentionTo: customerInfo.pointOfContact?.attentionTo || this.shipToAddress?.attentionTo || '',
      shipmentMethod: selectedShipmentMethod.value,
      comment: customerInfo.pointOfContact?.comment || '',
    });
  }

  private preFillAttentionToDefaults(
    customerInfo: ICustomerInfo,
  ): void {
    this.contactDetailsForm.patchValue({
      attentionTo: customerInfo.pointOfContact?.attentionTo || this.shipToAddress?.attentionTo || '',
    });
  }

  private recalculateShipmentMethodPrice(idShipmentMethod: number): void {
    if (!idShipmentMethod) {
      return;
    }

    const data = {
      type: EGlueResource.CHECKOUT_DATA,
      attributes: {
        idCart: this.cartId,
        shipment: {
          idShipmentMethod: idShipmentMethod,
        },
      },
    };

    this.recalculatingNewPricesInProgress = true;
    this.checkoutFacade.getShipmentMethodsOrRecalculatePrice({data: data})
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        if (response?.data) {
          this.checkoutFacade.getShipmentMethodPrice(this.cartId)
            .pipe(take(1))
            .subscribe({
              next: cartItems => {
                if (cartItems?.data.attributes.totals) {
                  this.totals = {...cartItems.data.attributes.totals};
                }
                this.recalculatingNewPricesInProgress = false;
              },
              error: () => this.recalculatingNewPricesInProgress = false,
            });
        }
      });
  }

  /**
   * Select price disputing as array of all items
   * which had price disputing set (from ShopCart state).
   *
   * @private
   *
   */
  private selectPriceDisputingPerItem(): void {
    this.marketingFacade.selectPriceDisputing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(priceDisputingPerItem => {
        this.priceDisputingPerItem = priceDisputingPerItem;
      });
  }

  /**
   * Select if price disputing is set in the cart
   *
   * @private
   *
   */
  private selectIsPriceDisputingSetInCart(): void {
    this.marketingFacade.selectIsPriceDisputingSetInCart().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isPriceDisputingSetInCart => {
      this.isPriceDisputingSetInCart = isPriceDisputingSetInCart;
    });
  }

  ngOnDestroy(): void {
    this.storePointOfContact();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private storePointOfContact(): void {
    const pointOfContact = this.createPointOfContactFromFormValues().pointOfContact;

    // do not cache automatically preselected shipment method
    if (!this.isShipmentMethodSelectedManually) {
      pointOfContact.shipmentMethod = null;
      pointOfContact.shipmentMethodName = null;
    }

    this.customerFacade.setPointOfContact(pointOfContact);
  }

  /**
   * Determine if "FedEx Heavy Weight Shipment" method should be applied (based on the weight limit)
   *
   *
   * @return {boolean}
   */
  isHeavyWeightShipmentApplicable(): boolean {
    return this.calculateTotalWeight() > this.translate.instant('config.heavyWeightShipmentLimit');
  }

  /**
   * Calculate total weight (for all items in cart)
   *
   *
   * @return {number}
   */
  calculateTotalWeight(): number {
    return this.cartItemsWithDetails.reduce((sum, item) => {
      return sum + item?.attributes?.quantity * (item?.attributes?.attributes?.sap_p40_gross_weight || 0);
    }, 0);
  }
}
