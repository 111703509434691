import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { IUserAddress } from '../models/customer.models';
import { Observable } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';
import { IAddressData, IAddressesDataIncluded, ICustomAddressData } from '../models/checkout.models';
import { GlueConfigurationService } from './glue-configuration.service';
import { AddressUtils } from '../utils/address.utils';

@Injectable({
  providedIn: 'root',
})
export class DeliveryDetailsService {

  glueUrl = this.glueConfiguration.getEndpointUrl();

  constructor(
    private http: HttpClient,
    private glueConfiguration: GlueConfigurationService,
  ) {
  }

  private httpParamsForGetCompanyUsersRoleItems = {
    params: new HttpParams()
      .set('include', 'company-roles,customers'),
  };

  private httpParamsForGetApprovers = {
    params: new HttpParams()
      .set('include', 'customers'),
  };

  private static handleError(error: any): Promise<IUserAddress> {
    return Promise.reject(error);
  }

  postCheckoutData(data: any): Observable<any> {
    const newBody = JSON.stringify(data);
    const cartsInfoUrl = this.glueUrl + '/checkout-update';

    return this.http.post<any>(cartsInfoUrl, newBody).pipe(
      map(res => res),
      catchError(DeliveryDetailsService.handleError),
    );
  }

  getCheckoutData(data: any): Observable<any> {
    const newBody = JSON.stringify(data);
    const cartsInfoUrl = this.glueUrl + '/checkout-data?include=shipment-methods,shipments';

    return this.http.post<any>(cartsInfoUrl, newBody).pipe(
      map(response => response),
      catchError(DeliveryDetailsService.handleError)
    );
  }

  getShipmentMethodsOrRecalculatePrice(data: any): Observable<any> {
    const newBody = JSON.stringify(data);
    const cartsInfoUrl = this.glueUrl + '/checkout-data';

    return this.http.post<any>(cartsInfoUrl, newBody).pipe(
      map(response => response),
      catchError(DeliveryDetailsService.handleError)
    );
  }

  getShipmentMethodPrice(cartId: string): Observable<any> {
    const cartsUrl = `${this.glueUrl}/carts/${cartId}?include=items`;

    return this.http.get<any>(cartsUrl).pipe(
      tap(),
      catchError(DeliveryDetailsService.handleError),
    );
  }

  postCheckout(data: any): Observable<any> {
    const newBody = JSON.stringify(data);
    const cartsInfoUrl = this.glueUrl + '/checkout';

    return this.http.post<any>(cartsInfoUrl, newBody).pipe(
      take(1),
      map(response => response),
      catchError(DeliveryDetailsService.handleError)
    );
  }

  getCompanyUsers(): Observable<any> {
    const companyUsersUrl = this.glueUrl + '/company-users/mine';

    return this.http.get<any>(companyUsersUrl, this.httpParamsForGetCompanyUsersRoleItems)
      .pipe(
        tap(),
        catchError(DeliveryDetailsService.handleError),
      );
  }

  getCompanyUsersForApprove(): Observable<any> {
    const companyUsersUrl = this.glueUrl + '/company-approvers';

    return this.http.get<any>(companyUsersUrl, this.httpParamsForGetApprovers).pipe(
      tap(),
      catchError(DeliveryDetailsService.handleError),
    );
  }

  getAddressesFromData(addressesToAdd: IAddressesDataIncluded[] | ICustomAddressData[], addressList: IAddressData[]): IAddressData[] {
    addressesToAdd.forEach(data => {
      if (data?.attributes) {
        const newAddress: IAddressData = {id: data.id, name: AddressUtils.createAddressString(data.attributes), value: data.attributes};
        const addressFound = addressList.find(address => address.name === newAddress.name);
        if (!addressFound) {
          addressList.push(newAddress);
        }
      }
    });
    return addressList;
  }
}

