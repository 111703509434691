import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { CheckoutActions } from '../../actions';
import * as ValidationPatterns from '../../configurations/validations';
import { ICheckoutUpdate } from '../../models/checkout.models';
import { IBaseConfig } from '../../models/enviroment-delivery-details.model';
import { State } from '../../reducers';
import { AppUtils } from '../../utils/app.utils';
import { ObjectUtils } from '../../utils/object.utils';
import { EGlueResource, EStoreTypes } from '../../configurations/common';
import { formatDate } from '@angular/common';
import { ConfigurationFacade } from '../../facades/configuration.facade';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { IPointOfContact } from '../../models/common.models';
import * as ShopCartSelectors from '../../reducers/shop-cart.reducer';

@Component({
  selector: 'app-contact-point-modal',
  templateUrl: './contact-point-modal.component.html',
  styleUrls: ['./contact-point-modal.component.scss'],
})
export class ContactPointModalComponent implements OnInit, OnDestroy {
  @Input() showModal: boolean;
  @Output() closeModal = new EventEmitter<any>();
  @Output() reloadCheckoutData = new EventEmitter<any>();

  pocData: IPointOfContact;
  pointOfContactForm: UntypedFormGroup;
  data: ICheckoutUpdate;
  departments: IBaseConfig[];
  deliveryTimes: IBaseConfig[];
  cartId: string;
  isJpStore: boolean;

  maxLengthPhone = 20;
  maxLengthName = 35;
  maxLengthComment = 500;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private store: Store<State>,
    private checkoutFacade: CheckoutFacade,
    private configurationFacade: ConfigurationFacade
  ) {
  }

  ngOnInit(): void {
    this.getDeliveryTimes();
    this.getDepartments();
    this.getCartId();
    this.isJpStore = AppUtils.isStoreActive(EStoreTypes.JP);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getDeliveryTimes(): void {
    this.configurationFacade.getTranslationByKey(['delivery-times'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.deliveryTimes = Object.values(data['delivery-times']).map((deliveryTime: string) => {
          return {
            name: deliveryTime,
            value: deliveryTime
          };
        });
      });
  }

  getDepartments(): void {
    this.configurationFacade.getTranslationByKey(['departments'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.departments = Object.values(data['departments']).map((department: string) => {
          return {
            name: department,
            value: department
          };
        });
      });
  }

  getCartId(): void {
    this.store.select(ShopCartSelectors.selectCurrentCartId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        cartId => {
          this.cartId = cartId;
        },
      );
  }

  initializeForm(): void {
    this.pointOfContactForm = this.formBuilder.group({
      department: [this.departments.find(d => d.name === this.pocData.department), [Validators.required]],
      firstName: [this.pocData.firstName, [Validators.required, Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      lastName: [this.pocData.lastName, [Validators.required, Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      phone: [this.pocData.phone, [Validators.required, Validators.maxLength(this.maxLengthPhone), ValidationPatterns.phonePattern]],
      deliveryTime: this.pocData.deliveryTime ? this.deliveryTimes.find(t => t.name === this.pocData.deliveryTime) : '',
      deliveryDate: this.pocData.deliveryDate ? this.pocData.deliveryDate : '',
      comment: [this.pocData.comment ?? '', [Validators.maxLength(this.maxLengthComment), ValidationPatterns.noEmptySpaceOnTheBeginning]],
    });
  }

  formIsValid(): boolean {
    return this.pointOfContactForm.status === 'VALID';
  }

  compareFormToPocData(): boolean {
    let keysToCompare = ['firstName', 'lastName', 'department', 'phone', 'comment'];
    keysToCompare.push(this.isJpStore ? 'deliveryDate' : 'deliveryTime');

    const filteredPocData = this.filteredShallowCopy(this.pocData, keysToCompare);
    const filteredFormObj = this.filteredShallowCopy(this.pointOfContactForm.value, keysToCompare);

    filteredFormObj.department = filteredFormObj.department.value;
    if (!this.isJpStore) {
      filteredFormObj.deliveryTime = filteredFormObj.deliveryTime.value;
    }

    return ObjectUtils.isEqual(filteredFormObj, filteredPocData);
  }

  filteredShallowCopy(objectToCopy: any, keysToInclude: string[]): any {
    return Object.keys(objectToCopy)
      .filter(key => keysToInclude.includes(key))
      .reduce((obj, key) => {
        obj[key] = objectToCopy[key];
        return obj;
      }, {});
  }

  saveAddressPOC(): void {
    if (this.formIsValid()) {
      this.store.dispatch(CheckoutActions.UpdatePOCData({
        payload: {
          department: this.pointOfContactForm.value.department.value,
          firstName: this.pointOfContactForm.value.firstName,
          lastName: this.pointOfContactForm.value.lastName,
          phone: this.pointOfContactForm.value.phone,
          deliveryTime: this.pointOfContactForm.value.deliveryTime.value,
          deliveryDate: this.pointOfContactForm.value.deliveryTime.value,
          comment: this.pointOfContactForm.value.comment,
        },
      }));

      this.data = {
        type: EGlueResource.CHECKOUT_UPDATE,
        attributes: {
          idCart: this.cartId,
          isAddressSavingSkipped: true,
          pointOfContact: {
            pointOfContactId: 'pointOfContact',
            department: this.pointOfContactForm.value.department.value,
            firstName: this.pointOfContactForm.value.firstName,
            lastName: this.pointOfContactForm.value.lastName,
            phone: this.pointOfContactForm.value.phone,
            deliveryTime: this.pointOfContactForm.value.deliveryTime.value,
            deliveryDate: this.pointOfContactForm.value.deliveryDate,
            comment: this.pointOfContactForm.value.comment,
          },
        },
      };

      const dataToSend = {
        data: this.data,
      };

      this.checkoutFacade.postCheckoutData(dataToSend).pipe(
        take(1)
      ).subscribe({
        next:() => {
          this.reloadCheckoutData.emit();
          this.closeModal.emit();
        }
      });
    }
  }

  setOrderFormCommentValue(event: any): void {
    this.pointOfContactForm.patchValue({
      [event.key]: event.value,
    });
  }

  setOrderFormValue(event: any): void {
    this.pointOfContactForm.patchValue({
      [event.key]: event,
    });
  }

  setDateFormValue(event: any): void {
    this.pointOfContactForm.patchValue({
      [event.key]: event.value ? formatDate(event.value, 'yyyy-MM-dd', 'en') : '',
    });
  }

  resetForm(): void {
    this.closeModal.emit();
  }
}
