import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import * as ValidationPatterns from '../../../configurations/validations';
import { CustomerFacade } from '../../../facades/customer.facade';
import { IBaseModel, ISelectEvent } from '../../../models/common.models';
import { IApproverData, ICustomerInfo } from '../../../models/customer.models';
import { IBaseConfig } from '../../../models/enviroment-delivery-details.model';
import { AppUtils } from '../../../utils/app.utils';
import { ImageUtils } from '../../../utils/image.utils';
import { ObjectUtils } from '../../../utils/object.utils';
import { formatDate } from '@angular/common';
import { CheckoutFacade } from '../../../facades/checkout.facade';
import { TaxUtils } from '../../../utils/tax.utils';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { IShipmentMethod } from '../../../models/checkout.models';
import { CartUtils } from '../../../utils/cart.utils';
import { EGlueResource, EStoreTypes } from '../../../configurations/common';
import { GlueUtils } from '../../../utils/glue.utils';

@Component({
  selector: 'app-order-contact-details-section',
  templateUrl: 'order-contact-details-section.component.html',
  styleUrls: ['order-contact-details-section.component.scss'],
})
export class OrderContactDetailsSectionComponent implements OnInit, OnDestroy {
  @Input() isJpStore;
  @Input() isAuStore;
  @Input() creatingOrderInProgress;
  @Input() creatingPreviewInProgress;
  @Input() cartItems;
  @Input() cartItemsWithDetails;
  @Input() cartRules;
  @Input() isCartEmpty;
  @Input() hasApproverAccess;
  @Input() cartId: string;

  @Output() previewSubmitted = new EventEmitter();
  @Output() formSubmitted = new EventEmitter<any>();
  @Output() backToDetailsClicked = new EventEmitter();

  contactForm: UntypedFormGroup;
  approverForm: UntypedFormGroup;
  departments: IBaseConfig[] = [];
  deliveryTimes: IBaseConfig[] = [];
  areFormsValid: boolean = false;
  maxLengthPhone: number = 20;
  maxLengthName: number = 35;
  maxLengthComment: number = 500;
  shipmentMethods: IShipmentMethod[];
  shipmentMethodsLoading: boolean = false;
  idShipmentMethod: number;
  minDaysFromToday: number = 0;

  selectedApprover: any;
  approversNameList: IApproverData[];
  approversNameListLoading: boolean = true;
  customerBusinessUnitName: any;
  companyUserUuid: string;

  pricesLoading: boolean = false;

  voucherButtonIsClicked: boolean;
  voucherHasError: boolean = false;
  voucherList = [];
  voucherListLoaded: boolean = false;
  showAdditionalInfoForVoucher: boolean;
  addVoucherInProgress: boolean = false;
  removeVoucherInProgress: boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private formBuilder: UntypedFormBuilder,
              private router: Router,
              private customerFacade: CustomerFacade,
              private checkoutFacade: CheckoutFacade,
              private marketingFacade: MarketingFacade,
              private configurationFacade: ConfigurationFacade
  ) {
  }

  ngOnInit(): void {
    this.getDeliveryTimes();
    this.getDepartments();
    this.getDatepickerMinDaysFromToday();
    this.getCustomerBusinessUnit();
    this.initializeForm();
    this.selectCustomerInfo();

    if (this.isAuStore) {
      this.getShipmentMethods();
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getProductImageUrl(product: any): string {
    return ImageUtils.getProductImageUrl(product);
  }

  getCustomerBusinessUnit(): void {
    this.customerFacade.selectCustomerBusinessUnit().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(
      data => {
        if (data && !this.customerBusinessUnitName) {
          this.customerBusinessUnitName = data.customerBusinessUnit;
          this.getApprovers();
        }
      },
    );
  }

  setContactFormValue(event: ISelectEvent): void {
    this.contactForm.patchValue({
      [event.key]: event.value,
    });

    this.revalidateForms();
  }

  setApproverFormValue(event: ISelectEvent): void {
    this.approverForm.patchValue({
      [event.key]: event.value,
    });

    this.revalidateForms();
  }

  selectApprover(event: any): void {
    this.approverForm.patchValue({
      approver: event,
    });

    this.selectedApprover = event;

    this.revalidateForms();
  }

  revalidateForms(): void {
    if (this.hasApproverAccess) {
      this.areFormsValid = this.contactForm.status === 'VALID';
    } else {
      this.areFormsValid = this.contactForm.status === 'VALID' && this.approverForm.status === 'VALID';
    }
  }

  proceedToCreateOrder(): void {
    let approverData = this.approverForm.value.approver;

    if (!approverData && this.hasApproverAccess) {
      approverData = {
        value: this.companyUserUuid
      }
    }

    const formData = {
      pointOfContact: {
        comment: this.contactForm.value.comment,
        email: this.contactForm.value.email,
        department: this.contactForm.value.department,
        firstName: this.contactForm.value.firstName,
        lastName: this.contactForm.value.lastName,
        phone: this.contactForm.value.phone,
        pointOfContactId: 'pointOfContact',
        deliveryTime: this.contactForm.value.deliveryTime,
        deliveryDate: this.contactForm.value.deliveryDate
          ? formatDate(this.contactForm.value.deliveryDate, 'yyyy-MM-dd', 'en')
          : '',
        shipmentMethod: this.contactForm.value.shipmentMethod,
      },
      approverData: {
        approver: approverData,
        commentForApprover: this.approverForm.value.commentForApprover,
      },
    };
    this.formSubmitted.emit(formData);
  }

  backToCart(): void {
    this.router.navigate(['/shop-cart']).then();
  }

  backToRequestDetails(): void {
    this.backToDetailsClicked.emit();
  }

  setShipmentMethod(event: ISelectEvent): void {
    this.idShipmentMethod = event.value;

    if (this.contactForm.value.shipmentMethod != this.idShipmentMethod) {
      this.recalculateShipmentMethodPrice(this.idShipmentMethod);
    }

    this.contactForm.patchValue({
      [event.key]: event.value,
    });

    this.revalidateForms();
  }

  getShipmentMethods(): void {
    this.shipmentMethodsLoading = true;
    const data = {
      type: 'checkout-data',
      attributes: {
        idCart: this.cartId
      },
    };

    this.checkoutFacade.getShipmentMethodsOrRecalculatePrice({data: data}).subscribe(response => {
      if (response?.data) {
        this.shipmentMethods = response.data.attributes?.shipmentMethods;
        this.shipmentMethods.forEach(shipmentMethod => {
          shipmentMethod.value = shipmentMethod.id;
        });

        const selectedShippingMethod = this.shipmentMethods.find(option => option.name === 'Standard Shipping');

        this.contactForm.patchValue({
          shipmentMethod: selectedShippingMethod.value,
        });

        this.recalculateShipmentMethodPrice(selectedShippingMethod.value);
      }

      this.shipmentMethodsLoading = false;
    });
  }

  getTaxPercent(): number {
    return TaxUtils.getTaxPercentage(this.cartItemsWithDetails);
  }

  showVoucher(): void {
    this.voucherButtonIsClicked = true;
  }

  addVoucher(): void {
    this.addVoucherInProgress = true;
    this.marketingFacade.postVoucher(this.cartId, this.contactForm.value.promotionCode).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: response => {
        if (response) {
          this._updateVoucherList(response.included);
          this.setContactFormValue({key: 'promotionCode', value: ''});
        }
        this.addVoucherInProgress = false;
      },
      error: () => {
        this.voucherListLoaded = false;
        this.voucherHasError = true;
        this.addVoucherInProgress = false;
      }
    });
  }

  removeVoucher(voucherId: string): void {
    this.removeVoucherInProgress = true;
    this.marketingFacade.removeVoucher(this.cartId, voucherId).pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe({
      next: response => {
        this.removeVoucherInProgress = false;
        this.setContactFormValue({key: 'promotionCode', value: ''});
        this._updateVoucherList(response.included);
      },
      error: _ => {
        this.removeVoucherInProgress = false;
        this.voucherHasError = true;
      }
    });
  }

  isVoucherBtnDisabled(): boolean {
    return this.addVoucherInProgress || this.contactForm.value.promotionCode === '';
  }

  resetVoucherField(): void {
    this.voucherListLoaded = false;
    this.voucherHasError = false;
  }

  getVoucherTitle(id: string): number {
    const voucherDiscount = this.getVoucherAmount(id);
    return Math.round((voucherDiscount * 100) / this.cartItems.totals.subtotal);
  }

  getVoucherAmount(id: string): number {
    return this.voucherList.filter(tmp => tmp.type === EGlueResource.VOUCHERS && tmp.id === id)[0].attributes.amount;
  }

  getTotalPrice(): number {
    return this.cartItems.totals.taxTotal + this.cartItems.totals.subtotal;
  }

  getPriceAfterVoucher(): number {
    let totalVoucherAmount = 0;
    if (this.voucherList.length > 0) {
      this.voucherList.forEach(
        tmp => {
          totalVoucherAmount += tmp.attributes.amount;
        });
    }
    let totalPrice;
    if (this.isJpStore) {
      totalPrice = this.cartItems.totals.subtotal;
    } else {
      totalPrice = this.getTotalPrice();
    }
    return totalPrice - totalVoucherAmount;
  }

  isProceedToCreateOrderBtnDisabled(): boolean {
    return !this.areFormsValid
      || this.creatingOrderInProgress
      || this.pricesLoading
      || this.addVoucherInProgress
      || this.removeVoucherInProgress
      || this.creatingPreviewInProgress;
  }

  getDatepickerMinDaysFromToday(): void {
    this.configurationFacade.getTranslationByKey('checkout-delivery-details.min-days-from-today').pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(value => {
      this.minDaysFromToday = +value && +value >= 0 ? +value : 0;
    });
  }

  getDeliveryTimes(): void {
    this.configurationFacade.getTranslationByKey(['delivery-times'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.deliveryTimes = Object.values(data['delivery-times']).map((deliveryTime: string) => {
          return {
            name: deliveryTime,
            value: deliveryTime
          };
        });
      });
  }

  getDepartments(): void {
    this.configurationFacade.getTranslationByKey(['departments'])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(data => {
        this.departments = Object.values(data['departments']).map((department: string) => {
          return {
            name: department,
            value: department
          };
        });
      });
  }

  private initializeForm(): void {
    this.contactForm = this.formBuilder.group({
      department: ['', [Validators.required]],
      firstName: ['', [Validators.required, Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      lastName: ['', [Validators.required, Validators.maxLength(this.maxLengthName), ValidationPatterns.onlyLetters]],
      phone: ['', [Validators.required, Validators.maxLength(this.maxLengthPhone), ValidationPatterns.phonePattern]],
      email: ['', [Validators.required, Validators.email]],
      comment: ['', [Validators.maxLength(this.maxLengthComment), ValidationPatterns.noEmptySpaceOnTheBeginning]],
      deliveryTime: '',
      deliveryDate: '',
      shipmentMethod: '',
      promotionCode: ''
    });

    this.approverForm = this.formBuilder.group({
      approver: ['', [Validators.required]],
      commentForApprover: ['', [Validators.maxLength(this.maxLengthComment), ValidationPatterns.noEmptySpaceOnTheBeginning]],
    });
  }

  private selectCustomerInfo(): void {
    this.customerFacade.selectMyProfileInformation().pipe(take(1)).subscribe(state => {
      this.contactForm.patchValue({
        ['firstName']: state.firstName || '',
        ['lastName']: state.lastName || '',
        ['email']: state.email || '',
      });

      this.companyUserUuid = state.companyUserUuid;

      this.validatePrefilledCustomerNames(state);
      this.revalidateForms();
    });
  }

  private getApprovers(): void {
    this.checkoutFacade.getCompanyUsersForApprove().pipe(
      take(1),
    ).subscribe(response => {
      const companyUsers = response.data.filter(user => user.attributes.isActive === true);
      const userIdMap = companyUsers.map(user => (
        {
          id: user.id,
          customerIds: user.relationships.customers.data.map(rel => rel.id),
          customerBusinessUnit: user.attributes.companyBusinessUnit,
        }
      ));
      const approverData = [];
      const approverFromHigherBusinessUnit = [];
      userIdMap.forEach(user => {
        if (this.customerBusinessUnitName) {
          const includedUser = response.included.find(i => i.id === user.customerIds[0]).attributes;
          if (ObjectUtils.isEqual(this.customerBusinessUnitName, user.customerBusinessUnit)) {
            approverData.push({
              value: user.id,
              name: `${includedUser.firstName} ${includedUser.lastName}`,
              firstName: includedUser.firstName,
              lastName: includedUser.lastName,
            });
          } else {
            approverFromHigherBusinessUnit.push({
              value: user.id,
              name: `${includedUser.firstName} ${includedUser.lastName}`,
              firstName: includedUser.firstName,
              lastName: includedUser.lastName,
            });
          }
        }
      });
      Array.prototype.push.apply(approverData, approverFromHigherBusinessUnit);
      this.approversNameList = approverData;
      this.approversNameListLoading = false;
    });
  }

  private recalculateShipmentMethodPrice(idShipmentMethod: number): void {
    this.pricesLoading = true;
    const data = {
      type: 'checkout-data',
      attributes: {
        idCart: this.cartId,
        shipment: {
          idShipmentMethod: idShipmentMethod
        }
      },
    };

    this.checkoutFacade.getShipmentMethodsOrRecalculatePrice({ data: data })
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(response => {
        if (response?.data) {
          this.checkoutFacade.getShipmentMethodPrice(this.cartId)
            .pipe(take(1))
            .subscribe({
              next: cart => {
                if (cart?.data.attributes.totals) {
                  this.cartItems.totals = { ...cart.data.attributes.totals };
                }
                this.pricesLoading = false;
              },
            });
        }
    });
  }

  private validatePrefilledCustomerNames(state: ICustomerInfo): void {
    // validate "firstName"/"lastName" field when it is already prefilled
    // immediate validation is needed as those fields can include special characters when being set-up in XIA

    if (state?.firstName) {
      this.contactForm.get('firstName').markAsTouched();
    }

    if (state?.lastName) {
      this.contactForm.get('lastName').markAsTouched();
    }
  }

  previewQuote(): void {
    this.previewSubmitted.emit();
  }

  getCurrentStore(): string {
    return AppUtils.getCurrentStore().storeId;
  }

  isJapanStore(): boolean {
    return this.getCurrentStore()==='JP';
  }

  /**
   * Check if cart contains any item without price. If yes, then it is quote request, not order, and you cannot
   * select shipping method.
   *
   * @returns {boolean}
   */
  isSetPricesForAllItems(): boolean {
    return CartUtils.isSetPricesForAllItems(this.cartItemsWithDetails);
  }

  /**
   * Get title for second page of delivery details:
   * If there is any product without price or is GB store active, then set title including point of contact.
   * If there are all products with price, then set title including shipping method.
   *
   * @returns {string}
   */
  getSecondPageTitle(): string {
    return this.isSetPricesForAllItems() && AppUtils.isStoreActive(EStoreTypes.AU) ?
      'checkout-delivery-details.select-department-and-shipping-method-title' : 'checkout-delivery-details.select-point-of-contact-title';
  }

  private _updateVoucherList(included: IBaseModel[]): void {
    this.voucherListLoaded = true;
    this.voucherHasError = false;
    this.voucherList = GlueUtils.filterResourcesByType(included, EGlueResource.VOUCHERS);
    this.showAdditionalInfoForVoucher = this.voucherList && this.voucherList.length > 0;
  }
}
