import { Injectable, Injector } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { CustomerActions } from '../actions';
import { authFeatureKey } from '../auth/reducers';
import { authStatusFeatureKey } from '../auth/reducers/auth.reducers';
import {
  ICompanyBusinessUnitAttributes,
  ICompanyBusinessUnits,
  ICustomerAccessControlData,
  ICustomerAddressData,
  ICustomerAddressesData,
  ICustomerAddressPayloadData,
  ICustomerCheckoutData,
  ICustomerInfo,
  ICustomerPreferences,
} from '../models/customer.models';
import { State } from '../reducers';
import * as CustomerSelectors from '../reducers/customer.reducer';
import { CustomerService } from '../services';
import { AppUtils } from '../utils/app.utils';
import { EUserRoles } from '../configurations/common';
import { IAddress, IPointOfContact } from '../models/common.models';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class CustomerFacade {
  private pCustomerService: CustomerService;

  private getCustomerService(): CustomerService {
    if (!this.pCustomerService) {
      this.pCustomerService = this.injector.get(CustomerService);
    }
    return this.pCustomerService;
  }

  constructor(
    private injector: Injector,
    private store: Store<State>,
    private translate: TranslateService,
  ) {
  }

  getCompanyUsers(): Observable<any> {
    return this.getCustomerService().getCompanyUsers();
  }

  selectCustomerData(): Observable<ICustomerCheckoutData> {
    return this.store.select(CustomerSelectors.selectCustomerData);
  }

  selectCustomerDataLoading(): Observable<boolean> {
    return this.store.select(CustomerSelectors.selectCustomerDataLoading);
  }

  selectCustomerPreferences(): Observable<any> {
    return this.store.select(CustomerSelectors.selectCustomerPreferences);
  }

  getCustomerCompanyRoles(): Observable<EUserRoles[]> {
    return this.store.select(CustomerSelectors.getCompanyRoles);
  }

  isBusinessPartner(): Observable<boolean> {
    return this.store.select(CustomerSelectors.isBusinessPartner);
  }

  getBusinessUnitById(id: string): Observable<ICompanyBusinessUnits> {
    return this.getCustomerService().getBusinessUnitById(id);
  }

  getCustomerConsentedTerms(): Observable<any> {
    return this.store.select(CustomerSelectors.getConsentedTerms);
  }

  getMyOrderPageData(): Observable<any> {
    return this.store.select(CustomerSelectors.getMyOrderPageData);
  }

  selectUserId(): Observable<any> {
    return this.store.select(CustomerSelectors.selectUserId);
  }

  selectIsGuest(): Observable<any> {
    return this.store.select(CustomerSelectors.isGuest);
  }

  selectIsCustomerLoaded(): Observable<any> {
    return this.store.select(CustomerSelectors.isCustomerLoaded);
  }

  selectAccessControlData(): Observable<ICustomerAccessControlData> {
    return this.store.select(CustomerSelectors.accessControlData);
  }

  selectCustomerCompanyRoles(): Observable<EUserRoles[]> {
    return this.store.select(CustomerSelectors.getCompanyRoles);
  }

  selectCompanyBusinessUnit(): Observable<any> {
    return this.store.select(CustomerSelectors.getMyBusinessUnitName);
  }

  selectCompanyRolesLoading(): Observable<boolean> {
    return this.store.select(CustomerSelectors.selectCompanyRolesLoading);
  }

  selectCustomerBusinessUnit(): Observable<any> {
    return this.store.select(CustomerSelectors.getMyBusinessUnitAndUserRoles);
  }

  selectMyProfileInformation(): Observable<ICustomerInfo> {
    return this.store.select(CustomerSelectors.getMyProfileInformation);
  }

  selectCompanyUserId(): Observable<string> {
    return this.store.select(CustomerSelectors.selectCompanyUserId);
  }

  isSaturdayShipmentSelected(): Observable<boolean> {
    return this.store.select(CustomerSelectors.isSaturdayShipmentSelected);
  }

  setPointOfContact(data: IPointOfContact): void {
    this.store.dispatch(CustomerActions.setPointOfContact({data: data}));
  }

  clearPointOfContact(): void {
    this.store.dispatch(CustomerActions.clearPointOfContact());
  }

  clearAttentionTo(): void {
    this.store.dispatch(CustomerActions.clearAttentionTo());
  }

  getCustomerCompanyRolesFromLocalStorage(): string {
    const localStorage = window.localStorage;

    if (!localStorage.hasOwnProperty('customer')) {
      return '';
    }
    const customer = JSON.parse(localStorage.customer);
    return customer.companyRoles || [];
  }

  updateCustomerPreferences(data: ICustomerPreferences): void {
    this.store.dispatch(CustomerActions.updateCustomerPreferences({data: data}));
  }

  updateConsentedTerms(currentConsentedTerms: string[]): void {
    this.store.dispatch(CustomerActions.setConsentedTerms({newConsentedTerms: currentConsentedTerms}));
  }

  setStore(storeId: string): void {
    const [_language, market] = storeId.split('-');
    const currentStore = environment.stores.find(store => store.marketCode === market);
    this.store.dispatch(CustomerActions.setStore({store: currentStore}));
  }

  selectStore(): Observable<any> {
    return this.store.select(CustomerSelectors.getStore);
  }

  beginGetBusinessUnitsAction(): void {
    this.store.dispatch(CustomerActions.startGetBusinessUnitAction());
  }

  selectBusinessUnitLoading(): Observable<any> {
    return this.store.select(CustomerSelectors.selectBusinessUnitLoading);
  }

  selectBusinessAddress(): Observable<any> {
    return this.store.select(CustomerSelectors.selectBusinessAddress);
  }

  selectBusinessUnits(): Observable<ICompanyBusinessUnitAttributes[]> {
    return this.store.select(CustomerSelectors.selectBusinessUnits);
  }

  selectCustomerLoadedGuardData(): Observable<any> {
    return this.store.select(CustomerSelectors.customerLoadedGuardData);
  }

  getBusinessUnits(): Observable<ICompanyBusinessUnits> {
    return this.getCustomerService().getBusinessUnits();
  }

  beginGetCustomerAddressesAction(): void {
    this.store.select(s => s[authFeatureKey][authStatusFeatureKey].user).pipe(take(1)).subscribe(userData => {
      if (userData) {
        let userID = userData[AppUtils.jwtGidElementName];
        userID = userID.replace(/["]+/g, '');
        this.store.dispatch(CustomerActions.beginGetCustomerAddressAction({payload: `${userID}`}));
      }
    });
  }

  beginAddCustomerAddressAction(customerId: string, data: ICustomerAddressPayloadData): void {
    this.store.dispatch(CustomerActions.beginAddCustomerAddressAction({customerId, data}));
  }

  beginUpdateCustomerAddressAction(customerId: string, addressId: string, data: ICustomerAddressPayloadData): void {
    this.store.dispatch(CustomerActions.beginUpdateCustomerAddressAction({customerId, addressId, data}));
  }

  beginDeleteCustomerAddressAction(customerId: string, addressId: string, isPreferredShipToAddress: boolean): void {
    this.store.dispatch(CustomerActions.beginDeleteCustomerAddressAction({
      customerId, addressId, isPreferredShipToAddress,
    }));
  }

  selectCustomerAddresses(): Observable<any> {
    return this.store.select(CustomerSelectors.selectCustomerAddresses);
  }

  selectUpdateCustomerAddressesInProgress(): Observable<boolean> {
    return this.store.select(CustomerSelectors.selectUpdateCustomerAddressesInProgress);
  }

  selectCustomerShipToAddresses(): Observable<any> {
    return this.store.select(CustomerSelectors.selectCustomerShipToAddresses);
  }

  setCustomCustomerShipToAddress(customerCustomerShipToAddress: IAddress): void {
    this.store.dispatch(CustomerActions.setCustomShipToAddress({data: customerCustomerShipToAddress}));
  }

  clearCustomCustomerShipToAddress(): void {
    this.store.dispatch(CustomerActions.clearCustomShipToAddress());
  }

  selectCustomCustomerShipToAddress(): Observable<IAddress> {
    return this.store.select(CustomerSelectors.selectCustomCustomerShipToAddress);
  }

  getCustomerAddresses(customerId: string): Observable<ICustomerAddressesData> {
    return this.getCustomerService().getCustomerAddresses(customerId.replace(/"+/g, ''));
  }

  addCustomerAddress(customerId: string, payload: ICustomerAddressPayloadData): Observable<ICustomerAddressData> {
    return this.getCustomerService().addCustomerAddress(customerId, payload);
  }

  updateCustomerAddress(customerId: string, addressId: string, payload: ICustomerAddressPayloadData): Observable<ICustomerAddressData> {
    return this.getCustomerService().updateCustomerAddress(customerId, addressId, payload);
  }

  deleteCustomerAddress(customerId: string, addressId: string): Observable<any> {
    return this.getCustomerService().deleteCustomerAddress(customerId, addressId);
  }

  getAuth0CustomerFromStore(): Observable<any> {
    return this.store.select(s => s[authFeatureKey][authStatusFeatureKey].user);
  }

  saveCustomerSettingPreferences(customerId: string, data: any): Observable<any> {
    return this.getCustomerService().saveCustomerSettingPreferences(customerId, data);
  }

  deleteCustomerAccount(customerId: string): Observable<any> {
    return this.getCustomerService().deleteCustomerAccount(customerId);
  }

  isPriceDisputingActive(): Observable<boolean> {
    return this.selectCustomerCompanyRoles().pipe(
      map(companyRoles => {
        if (companyRoles.includes(EUserRoles.BusinessPartnerApprover)) {
          return this.translate.instant('business-partner-approver.price-disputing') === 'true';
        }

        if (companyRoles.includes(EUserRoles.BusinessPartnerBuyer)) {
          return this.translate.instant('business-partner-buyer.price-disputing') === 'true';
        }

        return false;
      }));
  }
}
