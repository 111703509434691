import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { combineLatest, Observable, skipWhile, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { takeUntil } from 'rxjs/operators';

import { CustomerCurrencyPipe } from '../../../shared/pipes/customer-currency.pipe';
import { CustomerFacade } from '../../../facades/customer.facade';
import { ImageUtils } from '../../../utils/image.utils';
import { SparePartsUtils } from '../../../utils/spare-parts.utils';
import { addressValidation } from '../../../configurations/validations';
import {
  IAddress,
  IMessage,
  IPriceDisputingPerItem,
  ISelectEvent,
  ISystemDetails,
} from '../../../models/common.models';
import {
  IAddressSelectBox,
  ICompanyBusinessUnitAddresses,
  ICompanyBusinessUnits,
  ICustomerPreferences,
} from '../../../models/customer.models';
import { ICartAttributes, ICartItemWithDetail, ICartRule } from '../../../models/cart.models';
import { ConfigurationFacade } from '../../../facades/configuration.facade';
import { AddressUtils } from '../../../utils/address.utils';
import { EAddressType, EFeatureToggles, EGlueResource, EMessageType, EUserRoles } from '../../../configurations/common';
import { SoldToAccountsFacade } from '../../../facades/sold-to.facade';
import { ISoldToAddressesResponse, ISoldToIncludedResource } from '../../../models/soldTo-selection.models';
import { State } from '../../../reducers';
import { ShopCartActions } from '../../../actions';
import { MarketingFacade } from '../../../facades/marketing.facade';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-spare-part-order-details-section',
  templateUrl: 'spare-part-order-details-section.component.html',
  styleUrls: ['spare-part-order-details-section.component.scss'],
})
export class SparePartOrderDetailsSectionComponent implements OnInit, OnDestroy {
  orderDetailsForm: UntypedFormGroup;

  preferredShipToAddress: IAddress;
  shipToAddresses: IAddress[] = [];
  availableShipToAddresses: IAddressSelectBox[] = [];
  availableBPBillToAddresses: IAddressSelectBox[] = [];
  priceDisputingPerItem: IPriceDisputingPerItem[] = [];
  currentShipToAddress: IAddress;

  prefilledBillToAddressId: string | null;
  prefilledShipToAddressId: string | null;
  shipToAddressId: string = '';

  customerPreferences: ICustomerPreferences;

  //operations
  isBusinessPartner: boolean = false;
  addressChanged: boolean = false;
  showModalReportWrongAddress: boolean = false;
  showModalAddNewAddress: boolean = false;
  addressesLoading: boolean = false;
  availableBpBillToAddressesLoading: boolean = true;
  availableBpShipToAddressesLoading: boolean = false;
  isProductDiscontinuedStatusEnabled$: Observable<boolean> = new Observable<boolean>();

  itemDetailsAdditionalFields = [] as Array<{
    name: string,
    getValFunc: (item: any) => string,
    shouldDisplayFunc: (item: any) => boolean
  }>;
  itemMessage: IMessage = {
    type: EMessageType.WARNING,
    title: 'cart.disputed-pricing.title',
    description: 'cart.disputed-pricing.description',
  };

  //static types
  reportWrongAddressType: EAddressType = EAddressType.SHIP_TO;
  addressType: EAddressType = EAddressType.SHIP_TO;

  private unsubscribe$: Subject<void> = new Subject<void>();
  protected readonly EAddressType = EAddressType;

  @Input() cartId: string;
  @Input() cartAttributes: ICartAttributes;
  @Input() cartItemsWithDetails: Array<ICartItemWithDetail>;
  @Input() cartRules: Array<ICartRule>;
  @Input() systemDetails: ISystemDetails;
  @Input() loggedUserRoles: EUserRoles[];
  @Input() userLoggedData: any;
  @Input() isSaturdayShipment: boolean = false;
  @Input() isPriceDisputingActive: boolean = false;

  @Output() formSubmitted: EventEmitter<any> = new EventEmitter<any>();
  @Output() addressChangedEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private customerFacade: CustomerFacade,
    private marketingFacade: MarketingFacade,
    private customerCurrencyPipe: CustomerCurrencyPipe,
    private configurationFacade: ConfigurationFacade,
    private soldToFacade: SoldToAccountsFacade,
    private store: Store<State>,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.initializeForm();
    this.isProductDiscontinuedStatusEnabled$ = this.configurationFacade.isFeatureEnabled(EFeatureToggles.PRODUCT_DISCONTINUED_STATUS);
    this.selectIsCustomerBusinessPartner();
    this.selectCustomerPreferences();
    this.selectShipToAndBillToAddressesAndFillFormData();
    this.prepareItemAdditionalFields();
    this.selectPriceDisputingPerItem();
    this.route.queryParams
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(params => {
        this.shipToAddressId = params.addressId;
      });
  }

  /**
   * Select whether customer is business partner or not
   */
  selectIsCustomerBusinessPartner(): void {
    this.customerFacade.isBusinessPartner().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(isBusinessPartner => {
      this.isBusinessPartner = isBusinessPartner;
    });
  }

  /**
   * Select customer preferences.
   * Set this.customerPreferences variable. This variable is used for deciding which address is preferred.
   * @private
   */
  private selectCustomerPreferences(): void {
    this.customerFacade.selectCustomerPreferences().pipe(
      skipWhile(preferences => preferences === null),
      takeUntil(this.unsubscribe$),
    ).subscribe(customerPreferences => {
      if (customerPreferences) {
        this.customerPreferences = customerPreferences;
      }
    });
  }

  /**
   * If BP - get ship to and bill to address
   * If non-BP - select ship to address
   */
  selectShipToAndBillToAddressesAndFillFormData(): void {
    this.addressesLoading = true;
    //subscribe and combine to two customer state properties
    combineLatest([
      this.isBusinessPartner
        ? this.soldToFacade.getSoldToAccountsById(this.cartAttributes?.soldToAddress?.sapId, [EGlueResource.SHIP_TO_ADDRESSES, EGlueResource.BILL_TO_ADDRESSES])
        : this.customerFacade.selectCustomerShipToAddresses(),
      this.customerFacade.selectCustomCustomerShipToAddress(),
      this.customerFacade.selectCustomerAddresses(),
    ]).pipe(
      skipWhile(([addresses, _customShipToAddress, customerAddresses]) => !addresses || !customerAddresses),
      takeUntil(this.unsubscribe$),
    ).subscribe(([addresses, customShipToAddress, customerAddresses]) => {
      this.processShipToAndBillToAddressesResponseAndFillFormData(addresses, customShipToAddress, customerAddresses);
    });
  }

  proceedToContactDetails(): void {
    const orderDetailsData = {
      shipToAddress: this.orderDetailsForm?.value?.shipToAddress?.value,
      billToAddress: this.orderDetailsForm?.value?.businessPartnerBillToAddress?.value,
    };
    this.formSubmitted.emit(orderDetailsData);
  }

  /**
   * Customer can set custom shipping address
   *
   * @param {ISelectEvent} event
   */
  setCustomShipToAddress(event: ISelectEvent): void {
    const customShipToAddress: IAddress = {
      ...event.value,
      state: event.value.address2,
      sapId: this.currentShipToAddress?.sapId,
      isCustom: true,
    };
    this.currentShipToAddress = customShipToAddress;

    const customAddress: IAddressSelectBox = {
      name: `${SparePartsUtils.formatAddressToString(this.currentShipToAddress)}`,
      value: this.currentShipToAddress,
    };

    this.orderDetailsForm.patchValue({
      shipToAddress: customAddress,
    });
    this.availableShipToAddresses.push(customAddress);

    this.addressChanged = true;
    this.addressChangedEmitter.emit(this.addressChanged);
    this.customerFacade.setCustomCustomerShipToAddress(customShipToAddress);
    this.customerFacade.clearAttentionTo();
  }

  /**
   * Split BP addresses into shipTo and billTo and patch billTo to the form
   * @param addresses
   */
  splitBPAddressesIntoShipToAndBillTo(addresses: ISoldToAddressesResponse): ISoldToIncludedResource[] {
    const bpShipToAddresses: ISoldToIncludedResource[] = [];
    const bpBillToAddresses: ISoldToIncludedResource[] = [];
    addresses?.included?.forEach(customerAddress => {
      if (customerAddress.type === EGlueResource.SHIP_TO_ADDRESSES) {
        bpShipToAddresses.push(customerAddress);
      }
      if (customerAddress.type === EGlueResource.BILL_TO_ADDRESSES) {
        bpBillToAddresses.push(customerAddress);
      }
    });

    //according to addresses patch billTo addresses to the current form
    this.patchDefaultBillToAddressToForm(bpBillToAddresses);
    return bpShipToAddresses;
  }

  /**
   * Process ship to address and bill to address response
   * @param {ISoldToAddressesResponse | IAddress[]} addresses
   * @param {IAddress} customShipToAddress
   * @param {IAddress} customerAddresses
   * @private
   */
  processShipToAndBillToAddressesResponseAndFillFormData(
    addresses: ISoldToAddressesResponse | IAddress[],
    customShipToAddress: IAddress,
    customerAddresses: IAddress[],
  ): void {
    // In case the user set the current address (add address modal) do not process this functionality
    if (this.addressChanged) {
      this.addressesLoading = false;
      return;
    }

    let customerShipToAddress: IAddress;
    const selectedEquipment: string = this.systemDetails
      ? (`${this.systemDetails.nameEnUs} (${this.systemDetails.siemensEquipmentId})`)
      : '';

    const quoteShipToAddress: IAddress = this.cartAttributes?.shippingAddress;
    if (this.cartAttributes.pointOfContact?.attentionTo) {
      quoteShipToAddress.attentionTo = this.cartAttributes.pointOfContact.attentionTo;
    }
    let bpShipToAddresses: ISoldToIncludedResource[] = [];

    if (this.isBusinessPartner) {
      bpShipToAddresses = this.splitBPAddressesIntoShipToAndBillTo(addresses as ISoldToAddressesResponse);
    }

    const preferredShipToAddress: IAddress = this.getPreferredShipToAddress(
      this.isBusinessPartner
        ? bpShipToAddresses
        : addresses,
      customerAddresses,
    );

    this.preferredShipToAddress = {...preferredShipToAddress, company: preferredShipToAddress?.name};

    if (!this.isBusinessPartner) {
      if (AddressUtils.isAddressValid(this.preferredShipToAddress)) {
        customerShipToAddress = this.preferredShipToAddress;
      } else {
        if (AddressUtils.isAddressValid(quoteShipToAddress)) {
          customerShipToAddress = quoteShipToAddress;
        } else {
          customerShipToAddress = customShipToAddress;
        }
      }
      const mappedAddresses: IAddress[] =
              (addresses as IAddress[]).map(address => AddressUtils.unifyAddress(address, this.userLoggedData));

      if (this.shipToAddressId) {
        let shipToAddressById = mappedAddresses.find(address => address.id === this.shipToAddressId);
        if (!!shipToAddressById) {
          shipToAddressById = mappedAddresses.find(address => address.sapId === this.shipToAddressId);
        }

        customerShipToAddress = shipToAddressById;
      }

      if (customShipToAddress
        && this.checkAddressDuplicity([...customerAddresses, ...mappedAddresses as IAddress[]], customShipToAddress)) {
        this.availableShipToAddresses =
          [...this.mapAddressesToAddressSelectBox([...customerAddresses, ...mappedAddresses as IAddress[], customShipToAddress])];
      } else {
        this.availableShipToAddresses =
          [...this.mapAddressesToAddressSelectBox([...customerAddresses, ...mappedAddresses as IAddress[]])];
      }
    } else {
      customerShipToAddress = this.getBpShipToAddress(bpShipToAddresses, customerAddresses);
    }

    //check whether customer has ship to address, in case not, create address from Business Unit Address
    if (customerShipToAddress) {
      this.setCurrentCustomerShipToAddress(customerShipToAddress, customShipToAddress, selectedEquipment);

      if (!this.isBusinessPartner) {
        this.shipToAddresses = [this.currentShipToAddress];
      }

      this.addressesLoading = false;
    } else {
      this.createShipToAddressFromBusinessUnitAddress(selectedEquipment);
    }
  }

  /**
   * Check whether addresses includes customer custom address
   * @param {IAddress[]} addresses
   * @param {IAddress} customAddress
   * @returns {boolean}
   * @private
   */
  private checkAddressDuplicity(addresses: IAddress[], customAddress: IAddress): boolean {
    addresses.forEach(address => {
      if (AddressUtils.createAddressString(address) === AddressUtils.createAddressString(customAddress)) {
        return true;
      }
    });
    return false;
  }

  /**
   * In case the user have customer ShipToAddress, check if customer created custom shipTo address and based on this preselect
   * the correct one.
   * Set current customer shipTo address based on customer role.
   * Patch newly create shipToAddress to the form.
   * @param {IAddress} customerShipToAddress
   * @param {IAddress} customShipToAddress
   * @param {string} selectedEquipment
   */
  setCurrentCustomerShipToAddress(customerShipToAddress: IAddress, customShipToAddress: IAddress, selectedEquipment: string): void {
    const businessUnitName = this.userLoggedData?.businessUnitName;
    const soldToAccount: string = `${this.isBusinessPartner
      ? this.cartAttributes?.soldToAddress?.sapId + ' - '
      : ''}${this.cartAttributes?.soldToAddress?.company ?? businessUnitName}`;

    // set shipToAddress to customer preferred ship to address
    this.currentShipToAddress = customerShipToAddress;

    // check if customer set new current ship to address
    if (customShipToAddress) {
      // set shipToAddress to customer custom address
      if (this.isBusinessPartner) {
        this.setCustomBpShipToAddress(customShipToAddress);
      }
      this.currentShipToAddress = customShipToAddress;

    }
    let addressName: string;
    if (this.currentShipToAddress.isCustom) {
      addressName = `${SparePartsUtils.formatAddressToString(this.currentShipToAddress)}`;
    } else {
      addressName = `${this.currentShipToAddress?.sapId ?? this.cartAttributes?.systemDetails?.shipToAddress?.sapId} - ${SparePartsUtils.formatAddressToString(this.currentShipToAddress)}`;
    }

      this.orderDetailsForm.patchValue({
        selectedEquipment: selectedEquipment,
        soldToAccount: soldToAccount,
        customer: this.systemDetails?.shipToAddress?.name ?? businessUnitName,
        shipToAddress: {
          name: addressName,
          value: this.currentShipToAddress,
        },
      });
  }

  /**
   * In case the user does not have customer ShipToAddress, create a new one from BusinessUnitAddress.
   * Patch newly create shipToAddress to the form.
   *
   * @param {string} selectedEquipment
   */
  createShipToAddressFromBusinessUnitAddress(selectedEquipment: string): void {
    this.customerFacade.getBusinessUnitById('mine')
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(businessUnit => {
        const businessUnitAddress: ICompanyBusinessUnitAddresses = businessUnit.included.find(item =>
          item.type === 'company-business-unit-addresses') as ICompanyBusinessUnitAddresses;
        this.currentShipToAddress = this.mapShipToAddressFromBusinessUnitAddress(businessUnit, businessUnitAddress);
        this.shipToAddresses = [this.currentShipToAddress];

        this.orderDetailsForm.patchValue({
          selectedEquipment: selectedEquipment,
          soldToAccount: businessUnit?.data[0]?.attributes.name ?? '',
          shipToAddress: {
            name: `${this.currentShipToAddress.sapId} - ${SparePartsUtils.formatAddressToString(this.currentShipToAddress)}`,
            value: this.currentShipToAddress,
          },
          customer: this.systemDetails?.shipToAddress?.name,
        });

        if (this.orderDetailsForm.get('soldToAccount').status === 'INVALID') {
          this.showNotificationSoldToProblem();
        }

        this.addressesLoading = false;
      });
  }

  /**
   *
   * @param {ICompanyBusinessUnits} businessUnit
   * @param {ICompanyBusinessUnitAddresses} businessUnitAddress
   * @returns {IAddress}
   * @private
   */
  private mapShipToAddressFromBusinessUnitAddress(businessUnit: ICompanyBusinessUnits, businessUnitAddress: ICompanyBusinessUnitAddresses): IAddress {
    return {
      company: businessUnit?.data?.[0]?.attributes?.name,
      isDefaultBilling: false,
      isDefaultShipping: false,
      phone: this.userLoggedData?.customerData?.phone,
      salutation: this.userLoggedData?.customerData?.salutation,
      firstName: this.userLoggedData?.customerData?.firstName,
      lastName: this.userLoggedData?.customerData?.lastName,
      address1: businessUnitAddress?.attributes?.address1,
      address2: businessUnitAddress?.attributes?.address2,
      address3: businessUnitAddress?.attributes?.address3,
      city: businessUnitAddress?.attributes?.city,
      country: businessUnitAddress?.attributes?.country,
      iso2Code: businessUnitAddress?.attributes?.iso2Code,
      state: businessUnitAddress?.attributes?.state,
      zipCode: businessUnitAddress?.attributes?.zipCode,
      sapId: businessUnit?.data?.[0]?.attributes?.businessUnitNumber,
    };
  }

  mapAddressesToAddressSelectBox(addresses: IAddress[]): IAddressSelectBox[] {
    const selectBoxAddresses: IAddressSelectBox[] = [];
    addresses.forEach(address => {
      const selectBoxAddress = AddressUtils.mapAddressToAddressSelectBox(
        address,
        EAddressType.SHIP_TO,
        this.cartAttributes?.systemDetails?.shipToAddress?.sapId,
      );

      selectBoxAddresses.push({
        name: selectBoxAddress.name,
        value: selectBoxAddress.value,
      });
    });
    return selectBoxAddresses;
  }

  mapAddressResourceToAddressSelectBox(address: ISoldToIncludedResource): IAddressSelectBox {
    const addressTitle: string = `${address?.attributes?.sapId} - ${AddressUtils.getFormattedAddress(
      AddressUtils.unifyAddress(address?.attributes, this.userLoggedData),
      EAddressType.BILL_TO,
    )}`;
    const addressBody: IAddress = AddressUtils.unifyAddress(address?.attributes, this.userLoggedData);
    return {name: addressTitle, value: addressBody};
  }

  initializeForm(): void {
    this.orderDetailsForm = this.formBuilder.group({
      selectedEquipment: ['', [Validators.required]],
      soldToAccount: ['', [Validators.required]],
      customer: this.isBusinessPartner ? ['', [Validators.required]] : '',
      shipToAddress: ['', [Validators.required, addressValidation]],
      businessPartnerBillToAddress: this.isBusinessPartner ? ['', [Validators.required]] : '',
    });
  }

  private showNotificationSoldToProblem(): void {
    this.configurationFacade.appendNotification({
      type: 'warning',
      title: 'checkout-delivery-details-notification.soldToProblem',
      messages: [{
        key: 'checkout-delivery-details-notification.contactSupport',
      }],
      actions: [
        {
          type: '',
          label: 'checkout-delivery-details-notification.close',
          css: ['button', 'button--secondary'],
        },
      ],
    });
  }

  private prepareItemAdditionalFields(): void {
    this.itemDetailsAdditionalFields = [
      {
        name: 'multi-cart.quantity',
        getValFunc: (item) => item.attributes.quantity,
        shouldDisplayFunc: (_) => true,
      },
      {
        name: 'multi-cart.price-per-unit',
        getValFunc: (item) => this.customerCurrencyPipe.transform(
          item.attributes.calculations.unitPrice,
          this.cartAttributes.currency,
        ),
        shouldDisplayFunc: (_) => true,
      },
      {
        name: 'multi-cart.total-price',
        getValFunc: (item) => this.customerCurrencyPipe.transform(
          item.attributes.calculations.sumPrice,
          this.cartAttributes.currency,
        ),
        shouldDisplayFunc: (_) => true,
      },
    ];
  }

  /**
   * Get preferred shipTo address.
   *
   * @param {any} shipToAddresses
   * @param {IAddress} customerAddresses
   * @return {IAddress}
   */
  getPreferredShipToAddress(shipToAddresses: any, customerAddresses: IAddress[]): IAddress {
    let preferredCustomerAddress = customerAddresses?.find(
      address => {
        return address?.id === this.customerPreferences?.preferredShipToId;
      },
    );

    if (preferredCustomerAddress) {
      return {
        ...preferredCustomerAddress,
        sapId: this.cartAttributes?.systemDetails?.shipToAddress?.sapId,
      };
    }

    if (this.isBusinessPartner) {
      return AddressUtils.unifyAddress(
        shipToAddresses?.find(
          address => {
            return address?.attributes?.sapId === this.customerPreferences?.preferredShipToId
              || address?.id === this.customerPreferences?.preferredShipToId;
          },
        )?.attributes,
        this.userLoggedData,
      );
    } else {
      return shipToAddresses?.find(
        address => {
          return address?.sapId === this.customerPreferences?.preferredShipToId
            || address?.id === this.customerPreferences?.preferredShipToId;
        },
      );
    }
  }

  /**
   * Select shipTo address in orderDetailsForm.
   *
   * @param {ISelectEvent} event
   */
  selectShipToAddressInForm(event: ISelectEvent): void {
    this.orderDetailsForm.patchValue({
      shipToAddress: {
        name: `${event?.value?.isCustom ? '' : event?.value?.sapId + ' -'} ${AddressUtils.getFormattedAddress(event?.value)}`,
        value: event?.value,
      },
    });

    this.store.dispatch(ShopCartActions.setShipToAddressIdAction({shipToAddressSapId: event?.value?.sapId}));
    this.currentShipToAddress = event?.value;
    this.shipToAddresses = [this.currentShipToAddress];
    this.customerFacade.clearAttentionTo();
  }

  /**
   * Select business partner's billTo address in orderDetailsForm.
   *
   * @param {ISelectEvent} event
   */
  selectBpBillToAddress(event: ISelectEvent): void {
    this.orderDetailsForm.patchValue({
      businessPartnerBillToAddress: {
        name: `${event?.value?.sapId} - ${AddressUtils.getFormattedAddress(event?.value, EAddressType.BILL_TO)}`,
        value: event?.value,
      },
    });
    this.store.dispatch(ShopCartActions.setBillToAddressIdAction({billToAddressSapId: event?.value?.sapId}));
  }


  /**
   * Get all business partner's shipTo addresses based on the preselected soldTo address and set one of those as a default address.
   *
   * @private
   *
   * @param {ISoldToIncludedResource[]} addresses
   * @param {IAddress[]} customerAddresses
   * @return {IAddress}
   */
  private getBpShipToAddress(addresses: ISoldToIncludedResource[], customerAddresses: IAddress[]): IAddress {
    this.availableShipToAddresses = [];

    if (addresses?.length > 0) {
      addresses.forEach(address => {
        const addressTitle: string = `${address?.attributes?.sapId} - ${AddressUtils.getFormattedAddress(
          AddressUtils.unifyAddress(address?.attributes, this.userLoggedData),
        )}`;
        const addressBody: IAddress = AddressUtils.unifyAddress(address?.attributes, this.userLoggedData);
        this.availableShipToAddresses.push({name: addressTitle, value: addressBody});
      });
    }

    this.availableShipToAddresses = this.availableShipToAddresses.concat(
      this.mapAddressesToAddressSelectBox(customerAddresses),
    );

    let preferredBpShipToAddress: IAddressSelectBox;
    if (this.preferredShipToAddress?.isCustom) {
      preferredBpShipToAddress = this.availableShipToAddresses.find(address => {
        return address?.value?.id === this.preferredShipToAddress?.id;
      });
    } else {
      preferredBpShipToAddress = this.availableShipToAddresses.find(address => {
        return address?.value?.sapId === this.preferredShipToAddress?.sapId;
      });
    }

    this.selectPrefilledShipToAddressId();
    let prefilledShip = null;
    if (this.prefilledShipToAddressId) {
      prefilledShip = addresses.find(address => address.id === this.prefilledShipToAddressId);
    }

    const defaultBpShipToAddress = {
      name: `${this.cartAttributes?.systemDetails?.shipToAddress?.sapId} - ${AddressUtils.getFormattedAddress(
        AddressUtils.unifyAddress(this.cartAttributes?.systemDetails?.shipToAddress, this.userLoggedData),
      )}`,
      value: AddressUtils.unifyAddress(this.cartAttributes?.systemDetails?.shipToAddress, this.userLoggedData),
    };

    const prefilledBpShipToAddress = {
      name: `${prefilledShip?.attributes?.sapId} - ${AddressUtils.getFormattedAddress(
        AddressUtils.unifyAddress(prefilledShip?.attributes, this.userLoggedData),
      )}`,
      value: AddressUtils.unifyAddress(prefilledShip?.attributes, this.userLoggedData),
    };

    this.availableShipToAddresses.push(defaultBpShipToAddress);

    let shipToAddress;
    if ((this.preferredShipToAddress?.sapId || this.preferredShipToAddress?.id) && preferredBpShipToAddress) {
      this.orderDetailsForm.patchValue({
        shipToAddress: preferredBpShipToAddress,
      });

      shipToAddress = preferredBpShipToAddress.value;
    } else if (prefilledShip) {
      this.orderDetailsForm.patchValue({
        shipToAddress: prefilledBpShipToAddress,
      });

      shipToAddress = prefilledBpShipToAddress.value;
    } else {
      this.orderDetailsForm.patchValue({
        shipToAddress: defaultBpShipToAddress,
      });

      shipToAddress = defaultBpShipToAddress.value;
    }

    this.availableBpShipToAddressesLoading = false;
    return shipToAddress;
  }

  /**
   * Find default bill to address based on the preselected soldTo address and patch this address to the form.
   *
   * @param {ISoldToIncludedResource} addresses
   *
   */
  patchDefaultBillToAddressToForm(addresses: ISoldToIncludedResource[]): void {
    this.availableBPBillToAddresses = [];
    if (addresses) {
      if (addresses.length > 0) {
        addresses.forEach((address) => {
          address.type === EGlueResource.BILL_TO_ADDRESSES
          && this.availableBPBillToAddresses.push(this.mapAddressResourceToAddressSelectBox(address));
        });
        this.selectPrefilledBillToAddressId();
        const prefilledBill: ISoldToIncludedResource = addresses.find(address => address.id === this.prefilledBillToAddressId);
        const defaultBpBillToAddress: IAddressSelectBox = this.mapAddressResourceToAddressSelectBox(prefilledBill ?? addresses[0]);
        this.orderDetailsForm.patchValue({
          businessPartnerBillToAddress: defaultBpBillToAddress,
        });
      }
    }
    this.availableBpBillToAddressesLoading = false;
  }

  /**
   * Show change address modal if saturday delivery shipment method is not selected.
   */
  showAddNewAddressModal(): void {
    if (!this.isSaturdayShipment) {
      this.showModalAddNewAddress = true;
    }
  }

  /**
   * Determine if price disputing is set for given item.
   *
   *
   * @param {string} itemId
   * @return {boolean}
   */
  isPriceDisputingSetForItem(itemId: string): boolean {
    return this.priceDisputingPerItem?.find(itemPriceDisputing => {
      return itemPriceDisputing.itemId === itemId;
    })?.priceDisputing?.isSet;
  }

  /**
   * Returns current form values for triggered report wrong address (BP flow only)
   * @returns {string}
   */
  addressValueForReport(): string {
    switch (this.reportWrongAddressType) {
      case EAddressType.BILL_TO:
        return this.orderDetailsForm.value.businessPartnerBillToAddress?.name ?? ' ';
      case EAddressType.SHIP_TO:
        return this.orderDetailsForm.value.shipToAddress?.name;
      default:
        return this.orderDetailsForm.value.soldToAccount;
    }
  }

  /**
   * Returns the correct one title for report wrong address modal based on address type (BillTo, ShipTo, SoldTo)
   * @returns {string}
   */
  titleForReport(): string {
    switch (this.reportWrongAddressType) {
      case EAddressType.BILL_TO:
        return 'checkout-delivery-details.report-wrong-bill-to-address';
      case EAddressType.SHIP_TO:
        return 'checkout-delivery-details.report-wrong-ship-to-address';
      case EAddressType.SOLD_TO:
        return 'checkout-delivery-details.report-wrong-sold-to-address';
      default:
        return 'checkout-delivery-details.report-wrong-ship-to-address';
    }
  }

  /**
   * Select price disputing as array of all items
   * which had price disputing set (from ShopCart state).
   *
   * @private
   */
  private selectPriceDisputingPerItem(): void {
    this.marketingFacade.selectPriceDisputing()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(priceDisputingPerItem => {
        this.priceDisputingPerItem = priceDisputingPerItem;
      });
  }

  /**
   * Set custom business partner's shipTo address.
   *
   * @private
   *
   * @param {IAddress} address
   *
   */
  private setCustomBpShipToAddress(address: IAddress): void {
    const addressTitle: string = `${SparePartsUtils.formatAddressToString(address)}`;
    const customBpShipToAddress = {
      name: addressTitle,
      value: {
        ...address,
      },
    };

    this.availableShipToAddresses.push(customBpShipToAddress);
    this.orderDetailsForm.patchValue({
      shipToAddress: customBpShipToAddress,
    });
  }

  private selectPrefilledShipToAddressId(): void {
    this.marketingFacade.selectPrefilledShipToAddressId().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(shipToAddressId => {
      this.prefilledShipToAddressId = shipToAddressId;
    });
  }

  private selectPrefilledBillToAddressId(): void {
    this.marketingFacade.selectPrefilledBillToAddressId().pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(billToAddressId => {
      this.prefilledBillToAddressId = billToAddressId;
    });
  }

  /**
   *
   * @param item
   * @returns {string}
   */
  getProductImageUrl(item: any): string {
    return ImageUtils.getProductImageUrl(item);
  }

  /**
   * Check whether all form fields are valid
   * @returns {boolean}
   */
  isFormValid(): boolean {
    return this.orderDetailsForm.status === 'VALID';
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
