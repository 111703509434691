import { createFeatureSelector, createReducer, createSelector, on } from '@ngrx/store';

import { CatalogActions } from '../actions';
import { ICurrentParams } from '../models/catalog.models';
import { AppUtils } from '../utils/app.utils';
import { CustomerState, customerState } from './customer.reducer';
import { ArrayUtils } from '../utils/array.utils';
import { IStore } from '../models/settings.model';
import { environment } from '../../environments/environment.defaults';
import {
  IAbstractProductAvailabilityResource,
  IAbstractProductPrices,
  IAbstractProductPricesAndAvailability,
  ICachedProductSkuAndRequiredReload,
} from '../models/abstract-product.models';
import { EGlueResource } from '../configurations/common';

export const catalogFeatureKey: string = 'catalog';

export interface CatalogState {
  recentlyOpenedProducts: any[];
  recentlyOpenedProductsPrices: IAbstractProductPrices[];
  recentlyOpenedProductsAvailabilities: IAbstractProductAvailabilityResource[];
  recentlyOpenedProductSkus: ICachedProductSkuAndRequiredReload[];
  isAbstractProductLoading: boolean;
  arePricesAndAvailabilitiesLoading: boolean;
  abstractProductsSapDataLoading: boolean;
  abstractProductError: any;
  abstractProductSapInfoError: any;
  categoriesLoading: boolean;
  categoriesLoaded: boolean;
  categories: any;
  categoriesError: any;
  query: string;
  installedBase: any;
  queryParams: ICurrentParams;
  howManyProductsShouldBeCached: number;
}

export const initialState: CatalogState = {
  recentlyOpenedProducts: [],
  recentlyOpenedProductsPrices: [],
  recentlyOpenedProductsAvailabilities: [],
  recentlyOpenedProductSkus: [],
  isAbstractProductLoading: false,
  arePricesAndAvailabilitiesLoading: false,
  abstractProductsSapDataLoading: false,
  abstractProductError: null,
  abstractProductSapInfoError: null,
  categoriesLoading: false,
  categoriesLoaded: false,
  categories: null,
  categoriesError: null,
  query: '',
  installedBase: null,
  queryParams: null,
  howManyProductsShouldBeCached: null,
};

export const selectCatalogState = createFeatureSelector<CatalogState>(
  catalogFeatureKey,
);

export const selectAbstractProductError = createSelector(
  selectCatalogState,
  state => state.abstractProductError,
);

export const selectCategories = createSelector(
  selectCatalogState,
  state => state.categories,
);

export const selectMainCategoryName = createSelector(
  selectCatalogState,
  state => state.categories[0]?.name ? state.categories[0].name : '',
);

export const selectIsCategoriesLoading = createSelector(
  selectCatalogState,
  state => state.categoriesLoading,
);

export const selectIsCategoriesLoaded = createSelector(
  selectCatalogState,
  state => state.categoriesLoaded,
);

export const selectSearchQuery = createSelector(
  selectCatalogState,
  state => state.query,
);

export const selectInstalledBase = createSelector(
  selectCatalogState,
  state => state.installedBase,
);

export const selectQueryParams = createSelector(
  selectCatalogState,
  state => state.queryParams,
);

export const selectBlockedSortByOptions = createSelector(
  selectCatalogState,
  customerState,
  (catalog: CatalogState, customer: CustomerState): string[] => {
    const currentStore: IStore = AppUtils.getCurrentStore();
    const blockedOptionsPerRole: string[] = environment.blockedSortOptionsPerRole.find(sortBy => customer.companyRoles.includes(sortBy.role))?.options;
    const blockedOptionsPerStore: string[] = environment.blockedSortOptionsPerStore.find(sortBy => sortBy.store === currentStore.storeId)?.excluded;
    const blockedOptions: string[] = ArrayUtils.removeDuplicates(ArrayUtils.concatArrays(blockedOptionsPerRole, blockedOptionsPerStore));
    return blockedOptions ?? [];
  },
);

export const selectRecentlyOpenedProductsSkus = createSelector(
  selectCatalogState,
  state => state.recentlyOpenedProductSkus
)

export const selectRecentlyOpenedProductBySku = (sku: string) => createSelector(
  selectCatalogState,
  state => state.recentlyOpenedProducts.find(
    product => product.sku === sku
  )
)

export const selectAbstractProductPricesAndAvailabilitiesBySku = (sku: string) => createSelector(
  selectCatalogState,
  state => [
    {...state.recentlyOpenedProductsPrices.find(product => product.id === sku)},
    {...state.recentlyOpenedProductsAvailabilities.find(product => product.id === sku)},
  ] as IAbstractProductPricesAndAvailability
)

export const selectSapInfoLoading = createSelector(
  selectCatalogState,
  state => state.arePricesAndAvailabilitiesLoading
)

export const selectIsAbstractProductLoading = createSelector(
  selectCatalogState,
  state => state.isAbstractProductLoading
)

export const CatalogReducer = createReducer(
  initialState,

  on(CatalogActions.loadHowManyProductShouldBeCachedSuccess, (state: CatalogState, {count}) => {
    return {
      ...state,
      howManyProductsShouldBeCached: count
    }
  }),
  on(CatalogActions.loadHowManyProductShouldBeCachedError, (state: CatalogState) => {
    return {
      ...state,
      howManyProductsShouldBeCached: environment.maxCachedProducts,
    }
  }),
  on(CatalogActions.loadAbstractProductData, (state: CatalogState) => {
    return {
      ...state,
      isAbstractProductLoading: true,
    };
  }),

  on(CatalogActions.loadAbstractProductDataSuccess, (state: CatalogState, {payload}) => {
    return {
      ...state,
      recentlyOpenedProducts: [
        ...pushItemAndShift(
          state.recentlyOpenedProducts,
          {
            ...payload.data.attributes,
            included: payload.included,
            relationships: payload.data?.relationships || undefined,
          },
          state.howManyProductsShouldBeCached
        )],
      recentlyOpenedProductSkus: [
        ...pushItemAndShift(
          state.recentlyOpenedProductSkus,
          {sku: payload.data.attributes.sku, isReloadRequired: true},
          state.howManyProductsShouldBeCached
        )],
      isAbstractProductLoading: false,
    };
  }),
  on(CatalogActions.loadAbstractProductInfoFromSap, (state: CatalogState) => {
    return {
      ...state,
      arePricesAndAvailabilitiesLoading: true,
    }
  }),
  on(CatalogActions.loadAbstractProductInfoFromSapSuccess, (state: CatalogState, {payload}) => {
    const abstractProductPrices: IAbstractProductPrices
            = payload.included?.find(value => value.type === EGlueResource.ABSTRACT_PRODUCT_PRICES);
    const abstractProductAvailabilities: IAbstractProductAvailabilityResource
            = payload.included?.find(value => value.type === EGlueResource.ABSTRACT_PRODUCT_AVAILABILITIES);
    const recentlyOpenedProductSkus =
            state.recentlyOpenedProductSkus.map(product =>
              product.sku !== abstractProductPrices.id ? product : {...product, isReloadRequired: false})
    return {
      ...state,
      recentlyOpenedProductsPrices:
        [...addOrUpdateItem(state.recentlyOpenedProductsPrices, abstractProductPrices, state.howManyProductsShouldBeCached)],
      recentlyOpenedProductsAvailabilities:
        [...addOrUpdateItem(state.recentlyOpenedProductsAvailabilities, abstractProductAvailabilities, state.howManyProductsShouldBeCached)],
      abstractProductSapInfoError: null,
      arePricesAndAvailabilitiesLoading: false,
      recentlyOpenedProductSkus,
    };
  }),
  on(CatalogActions.loadAbstractProductInfoFromSapError, (state: CatalogState, {error}) => {
    return {
      ...state,
      abstractProductSapInfoError: error,
      arePricesAndAvailabilitiesLoading: false,
    };
  }),
  on(CatalogActions.setCachedProductsToBeReloaded, (state: CatalogState) => {
    const recentlyOpenedProductSkusUpdated = state.recentlyOpenedProductSkus.map((product) => ({
        ...product,
        isReloadRequired: true,
    }));
    return {
      ...state,
      recentlyOpenedProductSkus: recentlyOpenedProductSkusUpdated
    }
  }),

  on(CatalogActions.loadAbstractProductDataError, (state: CatalogState, {error}) => {
    return {
      ...state,
      abstractProductError: error,
      isAbstractProductLoading: false,
    };
  }),

  on(CatalogActions.clearAbstractProductData, (state: CatalogState) => {
    return {...state, abstractProductError: null};
  }),

  on(CatalogActions.loadCategoriesStart, (state: CatalogState) => {
    return {
      ...state,
      categoriesLoading: true,
    };
  }),

  on(CatalogActions.loadCategoriesSuccess, (state: CatalogState, {payload}) => {
    const {categoryNodesStorage} = payload.data[0].attributes;
    const categories = categoryNodesStorage.map(parentCategory => {
      return {...parentCategory, hasProducts: parentCategory?.productsQuantity > 0};
    });
    return {
      ...state,
      categoriesLoading: false,
      categoriesLoaded: true,
      categories,
    };
  }),

  on(CatalogActions.loadCategoriesFail, (state: CatalogState, {error}) => {
    return {
      ...state,
      categoriesLoading: false,
      categoriesLoaded: false,
      categoriesError: error,
    };
  }),
  on(CatalogActions.setSearchQuery, (state: CatalogState, action) => {
    return {
      ...state,
      query: action.query,
    };
  }),
  on(CatalogActions.clearSearchQuery, (state: CatalogState) => {
    return {
      ...state,
      query: '',
    };
  }),
  on(CatalogActions.setInstalledBase, (state: CatalogState, action) => {
    return {
      ...state,
      installedBase: action.installedBase,
    };
  }),
  on(CatalogActions.setQueryParams, (state: CatalogState, action) => {
    return {
      ...state,
      queryParams: action.params,
    };
  }),
);

/**
 * Push new item to the array. In case the array exceeds the max length remove the last item
 * @param {any[]} array
 * @param item
 * @param {number} max
 * @returns {any[]}
 */
function pushItemAndShift(array: any[], item: any, max: number): any[] {
  // Push the new item to the beginning of the array
  array.unshift(item);

  // If array length exceeds max, remove the last item
  if (array.length > max) {
    array.pop();
  }
  return array;
}


/**
 * Checks whether the item is in the array.
 * When the item is in the array update it with new data.
 * When the item is not in the array add it to the top of the array
 *
 * @param {any[]} array
 * @param item
 * @param {number} max
 * @returns {any[]}
 */
function addOrUpdateItem(array: any[], item: any, max: number): any[] {
  if(item) {
    const isInArray: boolean = array.some(existing => existing.id === item.id);

    if(!isInArray) {
      return pushItemAndShift(array, item, max)
    }
    const indexOfItem: number = array.findIndex(existing => existing.id === item.id);
    array.splice(indexOfItem, 1,item);
  }

  return [...array];
}
