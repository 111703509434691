import { Component, OnDestroy, OnInit } from '@angular/core';
import { DeliveryDetailsService } from './services/delivery-details.service';
import { combineLatest, skipWhile, takeUntil, Subject } from 'rxjs';
import { EFeatureToggles, EGlueResource, EStoreTypes, EUserRoles } from '../../configurations/common';
import { CustomerFacade } from '../../facades/customer.facade';
import { Router } from '@angular/router';
import { I18nService } from '../../services';
import { MarketingFacade } from '../../facades/marketing.facade';
import { take } from 'rxjs/operators';
import { PageTypes } from '../../analytics/enums/pageTypes';
import { AnalyticsService } from '../../analytics/analytics.service';
import { ICartAttributes, ICartItemWithDetail, ICartRule } from '../../models/cart.models';
import {
  IAddress,
  IMaterialMasterNumbersPerItem,
  IPriceDisputingPerItem,
  ISystemDetails,
} from '../../models/common.models';
import { CartUtils } from '../../utils/cart.utils';
import { AddressUtils } from '../../utils/address.utils';
import { CheckoutFacade } from '../../facades/checkout.facade';
import { ICheckoutUpdate } from '../../models/checkout.models';
import { ICustomerCheckoutData } from '../../models/customer.models';
import { AppUtils } from '../../utils/app.utils';
import { FileType, FileUtils } from '../../utils/file.utils';
import { ConfigurationFacade } from '../../facades/configuration.facade';

@Component({
  selector: 'app-harmonized-page-delivery-details',
  templateUrl: './harmonized-page-delivery-details.component.html',
  styleUrl: './harmonized-page-delivery-details.component.scss',
})
export class HarmonizedPageDeliveryDetailsComponent implements OnInit, OnDestroy {

  cartId: string;
  inProcessCartId: string;
  cartAttributes: ICartAttributes;
  cartItemsWithDetails: ICartItemWithDetail[];
  cartRules: ICartRule[];
  systemDetails: ISystemDetails;
  priceDisputingPerItem: IPriceDisputingPerItem[];
  materialMasterNumbersPerItem: IMaterialMasterNumbersPerItem[] = [];
  userData: ICustomerCheckoutData;
  orderDetailsData: {shipToAddress: IAddress, billToAddress: IAddress};

  isBusinessPartner: boolean = false;
  isCartDataLoading: boolean = true;
  isCreatingOrderInProgress: boolean = false;
  isOnDeliveryDetails2Section: boolean = false;
  isPreselectedShipToAddressCustom: boolean = false;
  isPriceDisputingActive: boolean = false;
  isSaturdayShipment: boolean = false;
  isUserDataLoading: boolean = true;
  isPriceDisputingSetInCart: boolean = false;
  isPdfGenerateQuoteEnabled: boolean = false;

  isApproverBuyerWorkflowEnabled: boolean = false;
  hasApproverAccess: boolean = false;
  companyUserId: string;
  showApprovalModal: boolean = false;
  approvalMsg: string;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private customerFacade: CustomerFacade,
    private marketingFacade: MarketingFacade,
    private checkoutFacade: CheckoutFacade,
    private configurationFacade: ConfigurationFacade,
    private router: Router,
    private i18nService: I18nService,
    private analyticsService: AnalyticsService,
    private deliveryDetailsService: DeliveryDetailsService,
  ) {
  }

  /**
   * Initializes the component by loading necessary data.
   */
  ngOnInit(): void {
    this.deliveryDetailsService.updateDeliveryDetailsData();
    this._selectCustomerData();
    this._selectCurrentCartId();
    this._selectIsPdfGenerateQuoteEnabled();
    this._selectIsApproverBuyerFlowEnabled();
    this._selectIsPreselectedShipToAddressCustom();
  }

  /**
   * Cleans up resources when the component is destroyed.
   */
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.customerFacade.clearAttentionTo();
  }

  /**
   * Proceeds to the delivery details 1 section.
   */
  proceedToDeliveryDetails1Section(): void {
    this.isOnDeliveryDetails2Section = false;
  }

  /**
   * Creates an order with necessary data.
   */
  createOrder(): void {
    this.isCreatingOrderInProgress = true;

    combineLatest([
      this.checkoutFacade.selectPreselectedShipToAddress(),
      this.checkoutFacade.selectPreselectedBillToAddress(),
      this.checkoutFacade.selectDeliveryDetailsFormData(),
      this.checkoutFacade.selectDeliveryDetailsApproverFormData(),
    ]).pipe(take(1)).subscribe(([preselectedShipTo, preselectedBillTo, checkoutFormData, checkoutApproverFormData]) => {
      const pointOfContact = {
        pointOfContactId: 'pointOfContact',
        attentionTo: checkoutFormData?.attentionTo ?? '',
        department: checkoutFormData?.department ?? '',
        firstName: checkoutFormData?.firstName ?? '',
        lastName: checkoutFormData?.lastName ?? '',
        email: checkoutFormData?.email ?? '',
        phone: checkoutFormData?.phone ?? '',
        comment: checkoutFormData?.comment ?? '',
        deliveryTime: checkoutFormData?.deliveryTime ?? '',
        deliveryDate: checkoutFormData?.deliveryDate ?? '',
        shipmentMethod: checkoutFormData?.shipmentMethod ?? '',
        floorOrRoom: checkoutFormData?.floorOrRoom ?? '',
      };

      let checkoutUpdateData: ICheckoutUpdate = {
        type: EGlueResource.CHECKOUT_UPDATE,
        attributes: {
          idCart: this.cartId,
          isAddressSavingSkipped: true,
          pointOfContact: pointOfContact,
          priceDisputingPerItem: this.priceDisputingPerItem,
          materialMasterNumbers: this.materialMasterNumbersPerItem,
        },
      };

      if (preselectedShipTo) {
        checkoutUpdateData = {
          ...checkoutUpdateData,
          attributes: {
            ...checkoutUpdateData.attributes,
            shippingAddress: AddressUtils.updateEmptyAddressFields(preselectedShipTo),
          },
        };
      }

      if (preselectedBillTo) {
        checkoutUpdateData = {
          ...checkoutUpdateData,
          attributes: {
            ...checkoutUpdateData.attributes,
            billingAddress: AddressUtils.updateEmptyAddressFields(preselectedBillTo),
          },
        };
      }

      if (this.isApproverBuyerWorkflowEnabled) {
        checkoutUpdateData = {
          ...checkoutUpdateData,
          attributes: {
            ...checkoutUpdateData.attributes,
            approverDetails: !this.hasApproverAccess
              ? checkoutApproverFormData
              : {approverId: this.companyUserId, comment: '', dueDate: null},
          },
        };
      }

      this.checkoutFacade.postCheckoutData({data: checkoutUpdateData}).pipe(
        take(1),
      ).subscribe({
        next: () => {
          this.isCreatingOrderInProgress = false;
          this.inProcessCartId = this.cartId;

          if (this.isPdfGenerateQuoteEnabled) {
            this._generateQuotePdf();
          }

          if (this.isApproverBuyerWorkflowEnabled) {
            this.showApprovalModal = true;
            this.generateApprovalMsg();

            if (AppUtils.isStoreActive(EStoreTypes.JP) && !this.userData.companyRoles.includes(EUserRoles.Buyer)) {
              this.analyticsService.trackCart('order.placed');
              return;
            }

            if (this.userData.companyRoles.includes(EUserRoles.Buyer)) {
              this.analyticsService.trackCart('order.placed');
              this.marketingFacade.createEmptyCart();
              return;
            }
          }

          this.checkoutFacade.actionPutCartIdOrderApprove(this.inProcessCartId);
          this.router.navigate(['/order-approval/', this.inProcessCartId]).then();
        },
        error: () => {
          this.isCreatingOrderInProgress = false;
        },
      });
    });
  }

  /**
   * Selects customer related data.
   *
   * @private
   */
  private _selectCustomerData(): void {
    combineLatest([
      this.customerFacade.selectCustomerData(),
      this.customerFacade.selectCustomerDataLoading(),
      this.customerFacade.isSaturdayShipmentSelected(),
      this.customerFacade.isBusinessPartner(),
      this.customerFacade.isPriceDisputingActive(),
    ]).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe(([data, isDataLoading, isSaturdayShipment, isBusinessPartner, isPriceDisputingActive]) => {
      this.userData = data;
      this.isUserDataLoading = isDataLoading;
      this.isSaturdayShipment = isSaturdayShipment;
      this.isBusinessPartner = isBusinessPartner;
      this.isPriceDisputingActive = isPriceDisputingActive;

      if (this.userData.companyRoles.includes(EUserRoles.Viewer)) {
        this.router.navigate([this.i18nService.getCurrentLocale()]).then();
      }

      this.hasApproverAccess = [
        EUserRoles.Approver,
        EUserRoles.Admin,
      ].some(role => this.userData.companyRoles.includes(role));
    });
  }

  /**
   * Selects the current cart ID.
   *
   * @private
   */
  private _selectCurrentCartId(): void {
    this.marketingFacade.selectCartId()
      .pipe(
        skipWhile(cartId => !cartId),
        take(1),
      ).subscribe(cartId => {
      this.cartId = cartId;
      this._selectCartItemAdditionalData(cartId);
    });
  }

  /**
   * Selects additional data for the current cart.
   *
   * @private
   *
   * @param {string} cartId
   */
  private _selectCartItemAdditionalData(cartId: string): void {
    combineLatest([
      this.marketingFacade.getCurrentCartItems(),
      this.marketingFacade.selectCartItemsWithDetails(),
      this.marketingFacade.selectPriceDisputing(),
      this.marketingFacade.selectIsPriceDisputingSetInCart(),
      this.marketingFacade.selectAllMaterialMasterNumbersInCart(cartId),
    ]).pipe(
      skipWhile(([cartData]) => !cartData),
      takeUntil(this.unsubscribe$),
    ).subscribe(
      ([cartData, cartItemsWithDetails, priceDisputingPerItem, isPriceDisputingSetInCart, materialMasterNumbers]) => {
        this.cartAttributes = cartData.data.attributes;
        this.cartRules = cartData.included.filter(include => include.type === 'cart-rules');
        this.systemDetails = cartData.data.attributes.systemDetails;

        if (cartItemsWithDetails?.length > 0) {
          this.cartItemsWithDetails = CartUtils.mapAvailabilitiesToCartItems(
            cartItemsWithDetails, cartData.included,
          );
        }

        this.priceDisputingPerItem = priceDisputingPerItem;
        this.isPriceDisputingSetInCart = isPriceDisputingSetInCart;
        this.materialMasterNumbersPerItem = materialMasterNumbers;
        this.isCartDataLoading = false;

        this._setDataForAnalyticsTracking(cartData);
      });
  }

  /**
   * Sets data for analytics tracking.
   *
   * @private
   *
   * @param {ICustomerCheckoutData} cartData
   */
  private _setDataForAnalyticsTracking(cartData: ICustomerCheckoutData): void {
    this.analyticsService.setCartId(this.cartId);
    this.analyticsService.setProducts(cartData);
    this.analyticsService.trackPageReady('spare part - delivery details', PageTypes.DELIVERY_DETAILS_PAGE, 'concrete-products');
  }

  /**
   * Selects whether PDF generate quote functionality is enabled (based on Arakh feature toggle).
   *
   * @private
   */
  private _selectIsPdfGenerateQuoteEnabled(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.PDF_GENERATE_QUOTE).pipe(take(1)).subscribe(value => {
      this.isPdfGenerateQuoteEnabled = value;
    });
  }

  /**
   * Selects whether the approver-buyer flow is enabled (based on Arakh feature toggle).
   *
   * If approver-buyer flow is enabled, selects additional needed data (company user id).
   *
   * @private
   */
  private _selectIsApproverBuyerFlowEnabled(): void {
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.APPROVER_BUYER_FLOW).pipe(take(1)).subscribe(value => {
      this.isApproverBuyerWorkflowEnabled = value;

      if (this.isApproverBuyerWorkflowEnabled) {
        this._selectCompanyUserId();
      }
    });
  }

  /**
   * Selects the company user ID.
   *
   * @private
   */
  private _selectCompanyUserId(): void {
    this.customerFacade.selectCompanyUserId().pipe(take(1)).subscribe(companyUserId => this.companyUserId = companyUserId);
  }

  /**
   * Selects whether the preselected ship-to address is custom (manually created by customer).
   *
   * @private
   */
  private _selectIsPreselectedShipToAddressCustom(): void {
    this.checkoutFacade.selectIsPreselectedShipToAddressCustom$()
      .pipe(
        takeUntil(this.unsubscribe$),
      ).subscribe(isPreselectedShipToAddressCustom => {
      this.isPreselectedShipToAddressCustom = isPreselectedShipToAddressCustom;
    });
  }

  /**
   * Generates a quote PDF for the current cart.
   *
   * @private
   */
  private _generateQuotePdf(): void {
    if (this.cartId) {
      this.checkoutFacade.getCartGenerateQuotePdfFile(this.cartId, false).pipe(take(1)).subscribe(file => {
        FileUtils.saveAndOpenFile(file, FileType.PDF, `Cart_details_${this.cartId}`);
      });
    }
  }

  /**
   * Generates an approval message based on feature toggle and user role.
   */
  generateApprovalMsg(): void {
    const messageUrl: string = this.i18nService.getCurrentLocale() + '/order-approval/' + this.cartId;
    let messageKey: string = 'checkout-delivery-details.approval-success-message';
    let messageArgs: object = {};
    this.configurationFacade.isFeatureEnabled(EFeatureToggles.APPROVER_HYPERLINK).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((value: boolean) => {
      if (value && this.userData.companyRoles.includes(EUserRoles.Approver)) {
        messageKey = 'checkout-delivery-details.approval-success-message-approver';
        messageArgs = {navigationToHospitalOrder: messageUrl};
      }
    });

    this.i18nService.getTranslationByKey(messageKey, messageArgs).pipe(
      takeUntil(this.unsubscribe$),
    ).subscribe((translation: string) => this.approvalMsg = translation);
  }

  /**
   * Navigates back to the homepage and then resets cached checkout data.
   */
  backToHomepage(): void {
    this.router.navigate(
      [this.i18nService.getCurrentLocale()],
      {queryParams: {lastCartId: this.inProcessCartId}},
    ).then(() => this.marketingFacade.resetCachedCheckoutData());
  }
}
