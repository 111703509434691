<section class="totals-and-discounts-container" *ngIf="totalsAndDiscounts$ | async as data">
  <div class="cart-pricelist">
    <div class="cart--subtotal-price">
      <span [attr.data-test]="'label-subtotalPrice'">
        {{ 'checkout-delivery-details.subtotal' | translate }}
      </span>
      <span [attr.data-test]="'desc-subtotalPrice'">
        {{ data.totals.subtotal | currency: currency }}
      </span>
    </div>
    <div class="cart--shipping-price">
      <span [attr.data-test]="'label-shippingAndHandlingPrice'">
        {{ 'checkout-delivery-details.shipping-and-handling' | translate }}
      </span>
      <span [attr.data-test]="'desc-shippingAndHandlingPrice'">
        {{ data.totals.expenseTotal | currency: currency }}
      </span>
    </div>
    @if (TaxUtils.isExcludeTaxActive()) {
      <div class="cart--shipping-price">
        <span [attr.data-test]="'label-gstPrice'">
          {{ 'checkout-delivery-details.gst-price' | translate: {tax: taxPercentage} }}
        </span>
        <span [attr.data-test]="'desc-shippingAndHandlingPrice'">
          {{ data.totals.taxTotal | currency: currency }}
        </span>
      </div>
    }
  </div>
  @if (data.discounts && data.discounts.length > 0) {
    <div class="discounts-section">
      @for (discount of data.discounts; track discount.displayName) {
        <div class="discount-container">
          <label>
            @if (!AppUtils.isStoreActive(EStoreTypes.JP)) {
              <span
                  class="icon-close remove-discount_btn"
                  title="{{ 'order-review.remove-discount' | translate }}"
                  (click)="removeDiscount(discount)"></span>
            }
            {{ orderReviewService.getDiscountTitle(discount.amount, data.totals.subtotal) }}{{ 'order-review.discount' | translate }}
          </label>
          <label class="price-2">-{{ discount.amount | currency: currency }}</label>
        </div>
      }
    </div>
  }
  <hr class="bottom-line">
  <div class="final-price-section">
    <strong [attr.data-test]="'label-orderTotalPrice'">
      {{ 'checkout-delivery-details.order-total' | translate }}
    </strong>
    <label class="price">
      <strong>
        {{ data.totals.priceToPay | currency: currency }}
      </strong>
    </label>
  </div>
  <div class="additional-details-section">
        <span [attr.data-test]="'desc-priceInfo'">
          {{ 'checkout-delivery-details.price-info' | translate }}
        </span>
    <br>
    <span *ngIf="data.totals.expenseTotal === 0 && data.totals.shipmentTotal === 0"
          [attr.data-test]="'desc-shipping'">
          {{ 'checkout-delivery-details.free-shipping' | translate }}
        </span>
  </div>
</section>
